import React, { useState, useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useParams, useNavigate } from 'react-router-dom'
import ImageUploader from '../../../Components/ImageUploader'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectDetail, editProjectWebsiteSetting } from '../../../Actions/AdminAction'

const ProjectWebsiteSetting = () => {

    const { projectId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, error, detail } = useSelector(
        (state) => state?.developer?.project?.detail
    );

    useEffect(() => {
        dispatch(getProjectDetail(projectId));
    }, []);


    const submitHandler=(e)=>{
        e.preventDefault();
        const formData = new FormData(e.target);
        dispatch(editProjectWebsiteSetting(formData, navigate, projectId));
    }

    if (detail?.domain_setting?.domain !== undefined) {
        return (
            <Layout pageTitle={"Project Website Setting"} >
                <form  onSubmit={submitHandler}  >
                    <div className='d-grid gap-4 p-3 ' >
                        <div>
                            <label htmlFor="domain">Domain</label>
                            <input type="text" name="domain" id='domain' placeholder='Enter Domain' className='form-control' defaultValue={detail.domain_setting?.domain} />
                        </div>
                        <div>
                            <label htmlFor="primaryColor">Primary Color </label>
                            <input type="text" name="primary" id='primaryColor' placeholder='Enter Primary Color' className="form-control" defaultValue={detail.domain_setting?.primary} />
                        </div>
                        <div>
                            <label htmlFor="primaryBGTextColor">Primary Background Text Color</label>
                            <input type="text" name="primary_bg_text_color" id='primaryBGTextColor' placeholder='Enter Primary Background Text Color' className="form-control" defaultValue={detail.domain_setting?.text_color_for_primary_bg} />
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <p>Main Hero</p>
                                <ImageUploader dbImage={"https://api.eazyapp.in" + detail.domain_setting?.main_hero} name={"main_hero"} />
                            </div>
                            <div className="col-md-4">
                                <p>Mobile Main Hero</p>
                                <ImageUploader dbImage={"https://api.eazyapp.in" + detail.domain_setting?.mobile_main_hero} name={"mobile_main_hero"}   />
                            </div>
                            <div className="col-md-4">
                                <p>Logo</p>
                                <ImageUploader dbImage={"https://api.eazyapp.in" + detail.domain_setting?.project_logo} name={"project_logo"} />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="meta_description">Meta Description</label>
                            <textarea type="text" name="meta_description" id='meta_description' placeholder='Enter Meta Description' className='form-control' defaultValue={detail.domain_setting?.meta_description} />
                        </div>
                        <div>
                            <label htmlFor="meta_keywords">Meta Keywords</label>
                            <textarea type="text" name="meta_keywords" id='meta_keywords' placeholder='Enter Meta Keywords' className='form-control' defaultValue={detail.domain_setting?.meta_keywords} />
                        </div>
                        <div>
                            <label htmlFor="google_analytics_id">Google Analytics ID</label>
                            <input type="text" name="google_analytics_id" id='google_analytics_id' placeholder='Enter Google Analytics ID' className='form-control' defaultValue={detail.domain_setting?.google_analytics_id} />
                        </div>
                        <div>
                            <label htmlFor="google_tag_manager_id">Google Tag Manager ID</label>
                            <input type="text" name="google_tag_manager_id" id='google_tag_manager_id' placeholder='Enter Google Tag Manager ID' className='form-control' defaultValue={detail.domain_setting?.google_tag_manager_id} />
                        </div>
                        <div>
                            <label htmlFor="google_verification_content">Google Verification Content</label>
                            <input type="text" name="google_verification_content" id='google_verification_content' placeholder='Enter Google Verification Content' className='form-control' defaultValue={detail.domain_setting?.google_verfication_content} />
                        </div>
                        <div className="text-end">
                            <button className="btn btn-success" type="submit">
                                Save
                            </button>
                            <button type='button' className="btn btn-dark" onClick={() => navigate(-1)} >Cancel</button>

                        </div>
                    </div>
                </form>
            </Layout>
        )
    }
}

export default ProjectWebsiteSetting
