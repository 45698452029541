import React, { useState } from 'react'
import EnterTemplateName from './WhatsappFunctions/EnterTemplateName';
import CreateHeader from './WhatsappFunctions/CreateHeader';
import CreateBody from './WhatsappFunctions/CreateBody';
import CreateFooter from './WhatsappFunctions/CreateFooter';
import AddButton from './WhatsappFunctions/AddButton';
import Card from './WhatsappFunctions/Card';
import "./whatsapp.css"
import Slider from "react-slick";
import moment from 'moment/moment';
import { useDispatch } from 'react-redux';
import { saveWhatsappTemplate } from '../Actions/CommonAction';

const HeaderHandler = ({ header }) => {

  if (header === null) return <></>;
  let content = null;

  switch (header.type) {
    case "text":
      content = <p className='text-start' >{header.value}</p>;
      break;

    case "image":
      const imageUrl = URL.createObjectURL(header.value);
      content = (
        <>
          <div
            style={{
              width: "280px",
              height: "320px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${imageUrl})`,
            }}
          ></div>
          <br />
        </>
      );
      break;

    case "video":
      const videoUrl = URL.createObjectURL(header.value);
      content = <video src={videoUrl} controls />;
      break;

    case "document":
      const documentUrl = URL.createObjectURL(header.value);
      content = (
        <div>
          Document:{" "}
          <a href={documentUrl} target="_blank" rel="noopener noreferrer">
            Open Document
          </a>
        </div>
      );
      break;

    case "location":
      content = <div>Location: {header.value}</div>;
      break;

    default:
      content = <></>;
  }

  return content;
};



const Whatsapp = ({ developerId }) => {

  const [whatsappData, setWhatsappData] = useState(null)
  const [header, setHeader] = useState(null)
  const [body, setBody] = useState(null)
  const [footer, setFooter] = useState(null)
  const [buttonData, setButtonData] = useState([])
  const [cardsData, setCardsData] = useState([])
  const [cards, setCards] = useState(0)

  const settings = {
    dots: false,
    infinite: false,
    centerMode: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  console.log(developerId)
  const dispatch = useDispatch()

  const submitHandler = () => {
    dispatch(saveWhatsappTemplate(whatsappData))
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-6 p-3">
          <div className="d-grid gap-3">
            <EnterTemplateName whatsappDataHandler={(e) => { setWhatsappData(e) }} />
            <div>
              <select className='form-select' onChange={(e) => setCards(parseInt(e.target.value))} >
                {[0, 2, 3, 4, 5, 6].map((i) => {
                  return <option value={i}>{i !== 0 ? i + " Cards" : "No Cards"}</option>
                })}
              </select>
            </div>
            {
              cards == 0 ? <>
                <CreateHeader  developerId={developerId}   whatsappDataHandler={(e) => {
                  const data = { ...whatsappData }
                  if (!data.hasOwnProperty('components')) {
                    data["components"] = []
                  }
                  data["components"] = [...data["components"].filter(i => i.type != "HEADER"), e]
                  setWhatsappData(data)
                }} dataToShow={(e) => { setHeader(e) }} />
                <CreateBody whatsappDataHandler={(e) => {
                  const data = { ...whatsappData }
                  if (!data.hasOwnProperty('components')) {
                    data["components"] = []
                  }
                  data["components"] = [...data["components"].filter(i => i.type != "BODY"), e]
                  setWhatsappData(data)
                }} dataToShow={(e) => { setBody(e) }} />
                <CreateFooter whatsappDataHandler={(e) => {
                  const data = { ...whatsappData }
                  if (!data.hasOwnProperty('components')) {
                    data["components"] = []
                  }
                  data["components"] = [...data["components"].filter(i => i.type != "FOOTER"), e]
                  setWhatsappData(data)
                }} dataToShow={(e) => { setFooter(e) }} />
                <AddButton whatsappDataHandler={(e) => {
                  const data = { ...whatsappData }
                  if (!data.hasOwnProperty('components')) {
                    data["components"] = []
                  }
                  data["components"] = [...data["components"].filter(i => i.type != "BUTTONS"), e]
                  setWhatsappData(data)
                }} dataToShow={(e) => { setButtonData(e) }} />

              </> : <>  <Card developerId={developerId}  cards={cards} whatsappDataHandler={(e) => {
                const data = { ...whatsappData }
                if (!data.hasOwnProperty('components')) {
                  data["components"] = []
                }
                data["components"] = [...data["components"].filter(i => i.type != "CAROUSEL"), e]
                setWhatsappData(data)
              }} dataToShow={(e) => { setCardsData(e) }} /> </>
            }

            <div className="text-end">
              <button className='btn btn-primary' onClick={submitHandler} >Submit</button>
            </div>
          </div>
        </div>
        <div className="col-md-6 py-3 px-2 vh-100 d-flex justify-content-center align-items-center ">
          <div className='vh-80 w-60 d-flex  ' style={{ height: "80vh" }} >

            <div className="right-container">
              {/* <!--header --> */}
              <div className="header py-5 px-3">
                <div className="img-text ">
                  <div className="user-img">
                    <img className="dp" src="https://images.pexels.com/photos/2474307/pexels-photo-2474307.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                  </div>
                  <h4>Leo<br /><span>Online</span></h4>
                </div>
                <div className="nav-icons">
                  <li><i className="fa-solid fa-magnifying-glass"></i></li>
                  <li><i className="fa-solid fa-ellipsis-vertical"></i></li>
                </div>
              </div>

              {/* <!--chat-container --> */}
              <div className="chat-container">
                <div className="message-box my-message">
                  <p>Hey!<br /><span>07:43</span></p>
                </div>
                {cards > 0 ?
                  <Slider {...settings.gallery}>
                    {cardsData.map((card) => {
                      return <div className="px-3">
                        <div className="message-box friend-message" style={{ width: "fit-content !important" }}>
                          <div className="p-2  bg-white" style={{ width: "fit-content !important", minWidth: "300px", borderRadius: "0.8rem" }} >
                            <HeaderHandler header={card?.header} />

                            {card.body?.value ? <pre className='text-wrap' >{card.body?.value.split('\n').map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}</pre> : <></>}

                            <div className='w-100 d-flex text-secondary text-sm py-2 ' > {card.footer?.value} <span className='ms-auto' >{moment().format("hh:mm A")}</span></div>
                            {card.buttonData != null && buttonData?.value?.map((btn) => {
                              return <div className=' w-100 border-top p-3 border-primary text-center '>{btn.text}</div>
                            })}

                          </div>
                        </div>
                      </div>
                    })}
                  </Slider>
                  :

                  <div className="message-box friend-message">
                    <div className="p-2  bg-white" style={{ width: "300px", borderRadius: "0.8rem" }} >
                      <HeaderHandler header={header} />

                      {body?.value ? <pre className='text-wrap' >{body?.value.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}</pre> : <></>}

                      <div className='w-100 d-flex text-secondary text-sm py-2 ' > {footer?.value} <span className='ms-auto' >{moment().format("hh:mm A")}</span></div>
                      {buttonData != null && buttonData?.value?.map((btn) => {
                        return <div className=' w-100 border-top p-3 border-primary text-center '>{btn.text}</div>
                      })}

                    </div>
                  </div>
                }

              </div>
              {/* <!--input-bottom --> */}
              <div className="chatbox-input">
                <i className="fa-regular fa-face-grin"></i>
                <i className="fa-sharp fa-solid fa-paperclip"></i>
                <input type="text" placeholder="Type a message" disabled />
                <i className="fa-solid fa-microphone"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Whatsapp
