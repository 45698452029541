import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import {
	getTeamsDataAction,
	checkInTeamMemeber,
	checkOutTeamMemeber,
} from "../../Actions/CREAction";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { json } from "react-router-dom";
const Teams = () => {
	const [teams, setTeams] = useState([]);
	const [teamId, setTeamId] = useState(null);
	const [confirmCheckIn, setConfirmCheckIn] = useState(false);
	const [confirmCheckOut, setConfirmCheckOut] = useState(false);
	const dispatch = useDispatch();
	const { loading, error, creTeamsData } = useSelector(
		(state) => state?.cre?.teamsData
	);

	useEffect(() => {
		if (creTeamsData !== undefined) {
			setTeams(
				creTeamsData.map((d) => {
					return {
						id: d.id,
						img: "https://api.eazyapp.in" + d.img,
						name: d.emp_name,
						designation: d.emp_designation,
						checkInTime: d.checkin,
						checkOutTime: d.checkout,
					};
				})
			);
		}
	}, [creTeamsData]);
	useEffect(() => {
		dispatch(getTeamsDataAction());
	}, []);
	return (
		<Layout pageTitle={"Teams"}>
			<div className="main_container">
				<div className="row w-100 gap-3">
					{teams.map((d) => {
						return (
							<div key={d.id} className="col-md-5  border rounded p-2 shadow">
								<div className="d-flex">
									<img
										src={d.img}
										alt=""
										className="me-3 img-thumbnail"
										style={{ height: "100px", width: "100px" }}
									/>
									<div className=" col-8">
										<h4 className="p-0 m-0 card_heading">{d.name}</h4>
										<p className="p-0 m-0 text-muted ">{d.designation}</p>
										<div className=" d-flex justify-content-between px-1 w-100 border-top mt-2 ">
											<a
												href={`tel:${d?.contact?.phone_number}`}
												className="fs-4 fw-bold text-primary "
											>
												<Icon.Telephone />
											</a>
											<a
												href={`sms:+${d?.contact?.phone_number}`}
												className="fs-4 fw-bold text-secondary "
											>
												<Icon.Chat />
											</a>
											<a
												href={`mailto:${d?.contact?.email}`}
												className="fs-4 fw-bold text-danger "
											>
												<Icon.EnvelopeAt />
											</a>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
				<Modal
					show={confirmCheckIn}
					onHide={() => {
						setConfirmCheckIn(false);
						setTeamId(null);
					}}
				>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<p>
							Are you Sure You Want to Check-In{" "}
							{teams?.filter((d) => d.id === teamId)[0]?.name}
						</p>
						<div className="d-flex gap-3 justify-content-end">
							<button
								className="btn btn-success"
								onClick={() => {
									dispatch(checkInTeamMemeber(teamId));
									setTeamId(null);
									setConfirmCheckIn(false);
								}}
							>
								Yes
							</button>
							<button
								className="btn btn-danger"
								onClick={() => {
									setConfirmCheckIn(false);
									setTeamId(null);
								}}
							>
								No
							</button>
						</div>
					</Modal.Body>
				</Modal>
				<Modal
					show={confirmCheckOut}
					onHide={() => {
						setConfirmCheckOut(false);
						setTeamId(null);
					}}
				>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<p>
							Are you Sure You Want to Check-Out{" "}
							{teams?.filter((d) => d.id === teamId)[0]?.name}
						</p>
						<div className="d-flex gap-3 justify-content-end">
							<button
								className="btn btn-success"
								onClick={() => {
									dispatch(checkOutTeamMemeber(teamId));
									setTeamId(null);
									setConfirmCheckOut(false);
								}}
							>
								Yes
							</button>
							<button
								className="btn btn-danger"
								onClick={() => {
									setConfirmCheckOut(false);
									setTeamId(null);
								}}
							>
								No
							</button>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		</Layout>
	);
};

export default Teams;
