import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import FileUploader from "../../../Components/FileUploader";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { uploadNewDocument } from "../../../Actions/AdminAction";
import toast from "react-hot-toast";
const AddDocument = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { projectId } = useParams();

	const submitHandler=(event)=>{
		event.preventDefault()
		const formData=new FormData(event.target)
		if (formData.get('file') == null || formData.get('file') == "" || formData.get('file') == undefined || formData.get('file').size == 0) {
			toast.error("Please Upload File")	
			return 
		}
		dispatch(uploadNewDocument(formData,projectId,navigate))
	}

	return (
		<Layout pageTitle={"Add Document"}>
			<form onSubmit={submitHandler}>
			<div className="d-grid p-3 gap-3">
				<div className="d-grid place-items-center">
					<div className="w-20">
						<FileUploader dbFile={null} />
					</div>
				</div>

				<div>
					<label className="text-muted">Select File Type</label>
					<select className="form-select" name="file_type">
						{["Broucher", "Cost Sheet", "Master Plan", "Floor Plan"].map(
							(fileType) => {
								return (
									<option
										value={fileType}
									>
										{fileType}
									</option>
								);
							}
						)}
					</select>
				</div>
				<div>
					<label className="text-muted">Select File For</label>
					<select className="form-select" name="available_to" multiple >
						{["Customers", "Visitors", "Channel Partner", "Employees"].map(
							(availbleTo) => {
								return (
									<option
										value={availbleTo}
									>
										{availbleTo}
									</option>
								);
							}
						)}
					</select>
				</div>

				<div className="justify-content-end d-flex gap-3">
					<button className="btn btn-success" type="submit" >Submit</button>
					<button type="button" className="btn btn-dark" onClick={()=>{
                        navigate(-1)
                    }} > 
						Cancel
					</button>
				</div>
			</div>
			</form>
		</Layout>
	);
};

export default AddDocument;
