import React ,{ useState}  from 'react'
import {Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
const ConfirmDelete = ({ id, idToCall, callback, label = "Delete" }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
	const [show, setShow] = useState(false);
    return (
        <>
            <div onClick={() => setShow(true)} >
                {label}
            </div>
            <Modal show={show} onHide={() => setShow(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            setShow(false);
                            dispatch(callback(id, idToCall, navigate))
                        }}
                    >
                        Delete
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setShow(false)}
                    >
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default ConfirmDelete
