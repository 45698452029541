import axios from "./axios";
import {
	ADD_NOTE_REQUEST,
	ADD_NOTE_SUCCESS,
	ADD_NOTE_FAIL,
	//
	ADD_TASK_REQUEST,
	ADD_TASK_SUCCESS,
	ADD_TASK_FAIL,
	//
	ADD_LOG_REQUEST,
	ADD_LOG_SUCCESS,
	ADD_LOG_FAIL,
	//
	DELETE_ACTIVITY_OR_TASK_REQUEST,
	DELETE_ACTIVITY_OR_TASK_SUCCESS,
	DELETE_ACTIVITY_OR_TASK_FAIL,
	//
	EDIT_ACTIVITY_LOG_REQUEST,
	EDIT_ACTIVITY_LOG_SUCCESS,
	EDIT_ACTIVITY_LOG_FAIL,
	//
	EDIT_TASK_REQUEST,
	EDIT_TASK_SUCCESS,
	EDIT_TASK_FAIL,
	//
	GET_TASK_REQUEST,
	GET_TASK_SUCCESS,
	GET_TASK_FAIL,
	//
	GET_ALL_PROJECTS_REQUEST,
	GET_ALL_PROJECTS_SUCCESS,
	GET_ALL_PROJECTS_FAIL,
	//
	GET_REPORT_REQUEST,
	GET_REPORT_SUCCESS,
	GET_REPORT_FAIL,
	//
	GET_PROJECT_REPORT_REQUEST,
	GET_PROJECT_REPORT_SUCCESS,
	GET_PROJECT_REPORT_FAIL,
	//
	GET_PROJECTS_WING_REQUEST,
	GET_PROJECTS_WING_SUCCESS,
	GET_PROJECTS_WING_FAIL,
	//
	GET_PROJECT_COLLATERALS_REQUEST,
	GET_PROJECT_COLLATERALS_SUCCESS,
	GET_PROJECT_COLLATERALS_FAIL,
	//
	CHANGE_PASSWORD_REQUEST,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAIL,
	//
	EDIT_PROFILE_REQUEST,
	EDIT_PROFILE_SUCCESS,
	EDIT_PROFILE_FAIL,
	// 
	GENRATE_REPORT_REQUEST,
	GENRATE_REPORT_SUCCESS,
	GENRATE_REPORT_FAIL,
	// 
	GET_PROJECT_OFFERS_REQUEST,
	GET_PROJECT_OFFERS_SUCCESS,
	GET_PROJECT_OFFERS_FAIL,
	// 
	GET_PROJECT_PAYMENT_SCHEDULE_REQUEST,
	GET_PROJECT_PAYMENT_SCHEDULE_SUCCESS,
	GET_PROJECT_PAYMENT_SCHEDULE_FAIL,
	// 
	GET_PROJECT_PRICING_LIST_REQUEST,
	GET_PROJECT_PRICING_LIST_SUCCESS,
	GET_PROJECT_PRICING_LIST_FAIL,
	// 
	SAVE_WHATSAPP_TEMPLATE_REQUEST,
	SAVE_WHATSAPP_TEMPLATE_SUCCESS,
	SAVE_WHATSAPP_TEMPLATE_FAIL,
	// 
} from "../Constants/CommonConstants";
import { getVisitorsDetail } from "./SEAction";
import toast from "react-hot-toast";


export const addNoteAction =
	(noteData, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ADD_NOTE_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.post("add/note/", noteData, config);

			toast.success("Note added successfully");

			navigate(-1);
			dispatch({
				type: ADD_NOTE_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: ADD_NOTE_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const addTaskAction =
	(taskData, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ADD_TASK_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};
			const { data } = await axios.post("add/task/", taskData, config);

			toast.success("Task added successfully");

			navigate(-1);
			dispatch({
				type: ADD_TASK_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: ADD_TASK_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const addLogAction =
	(logData, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: ADD_LOG_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};
			const { data } = await axios.post("add/log/", logData, config);

			toast.success("Log added successfully");

			dispatch({
				type: ADD_LOG_SUCCESS,
				payload: data,
			});
			navigate(-1);
		} catch (error) {
			dispatch({
				type: ADD_LOG_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const delelteTaskOrActivity =
	(deleteData, visitorId) => async (dispatch, getState) => {
		try {
			dispatch({
				type: DELETE_ACTIVITY_OR_TASK_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.post(
				"delete/activity-or-task/",
				deleteData,
				config
			);

			toast.success("Deleted successfully");

			dispatch(getVisitorsDetail(visitorId));
			dispatch({
				type: DELETE_ACTIVITY_OR_TASK_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: DELETE_ACTIVITY_OR_TASK_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const editLogAction =
	(logData, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: EDIT_ACTIVITY_LOG_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};
			const { data } = await axios.post("edit/log/", logData, config);

			toast.success("Log edited successfully");

			dispatch({
				type: EDIT_ACTIVITY_LOG_SUCCESS,
				payload: data,
			});
			navigate(-1);
		} catch (error) {
			dispatch({
				type: EDIT_ACTIVITY_LOG_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const editTaskACtion =
	(taskData, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: EDIT_TASK_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};

			const { data } = await axios.post("edit/task/", taskData, config);

			toast.success("Task edited successfully");

			dispatch({
				type: EDIT_TASK_SUCCESS,
				payload: data,
			});
			navigate(-1);
		} catch (error) {
			dispatch({
				type: EDIT_TASK_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const complteTask =
	(taskData, refreshDataFor, page) => async (dispatch, getState) => {
		try {
			const {
				userState: { userInfo },
			} = getState();
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};
			const { data } = await axios.post("edit/task/", taskData, config);
			dispatch(refreshDataFor(page));
		} catch (error) {
			dispatch({
				type: EDIT_TASK_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			})
		}
	};

export const getTaskAction = (taskStatus) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_TASK_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get(`/get/${taskStatus}/tasks/`, config);
		dispatch({
			type: GET_TASK_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_TASK_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const get_projects = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_ALL_PROJECTS_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.get(`/get/projects/`, config);
		dispatch({
			type: GET_ALL_PROJECTS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_ALL_PROJECTS_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const get_sale_reports = (postData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_REPORT_REQUEST });
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.post("get/sale-reports/", postData, config);

		dispatch({
			type: GET_REPORT_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_REPORT_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const get_project_report = (postData) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_PROJECT_REPORT_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.post("get/project-report/", postData, config);

		dispatch({
			type: GET_PROJECT_REPORT_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_PROJECT_REPORT_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getProjectWings = (projectId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_PROJECTS_WING_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get(`get/project-wings/${projectId}/`, config);

		dispatch({
			type: GET_PROJECTS_WING_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_PROJECTS_WING_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getProjectCollaterals =
	(projectId, params = {}) =>
		async (dispatch, getState) => {
			try {
				dispatch({
					type: GET_PROJECT_COLLATERALS_REQUEST,
				});

				const config = {
					headers: {
						"Content-type": "application/json",
					},
					params: params,
				};
				const { data } = await axios.get(
					`get-project-collaterals/${projectId}/`,
					config
				);
				dispatch({
					type: GET_PROJECT_COLLATERALS_SUCCESS,
					payload: data,
				});
			} catch (error) {
				dispatch({
					type: GET_PROJECT_COLLATERALS_FAIL,
					payload:
						error.response && error.response.data.error
							? error.response.data.error
							: error.message,
				});
			}
		};

export const changePassword = (key, postData, navigate) => async (dispatch, getState) => {
	try {
		dispatch({
			type: CHANGE_PASSWORD_REQUEST,
		});

		const config = {
			headers: {
				"Content-type": "application/json",
			},
		};

		const { data } = await axios.post(`reset-password/${key}`, postData, config);

		toast.success(data.message);

		dispatch({
			type: CHANGE_PASSWORD_SUCCESS,
			payload: data,
		});
		navigate("/login", { replace: true });
	} catch (error) {
		dispatch({
			type: CHANGE_PASSWORD_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const editEmployee = (postData, navigate) => async (dispatch, getState) => {
	try {
		dispatch({
			type: EDIT_PROFILE_REQUEST
		})

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "multipart/form-data",
				Authorization: `Bearer ${userInfo?.token}`
			},
		};

		const { data } = await axios.post("edit/employee/", postData, config)

		toast.success("Profile Updated Successfully");

		dispatch({
			type: EDIT_PROFILE_SUCCESS,
			payload: data
		})
		navigate(-1);

	} catch (error) {
		dispatch({
			type: EDIT_PROFILE_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
}



export const genrateReport = (postData) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GENRATE_REPORT_REQUEST
		})


		const { data } = await axios.post("genrate-report/", postData)
		dispatch({
			type: GENRATE_REPORT_SUCCESS,
			payload: data
		})

	} catch (error) {
		dispatch({
			type: GENRATE_REPORT_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
}

export const getProjectOffers = (projectId) => async (dispatch, getState) => {

	try {

		dispatch({
			type: GET_PROJECT_OFFERS_REQUEST
		})

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				"Authorization": `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.get(`get-project-offers/${projectId}/`, config);
		dispatch({
			type: GET_PROJECT_OFFERS_SUCCESS,
			payload: data
		})

	} catch (error) {
		dispatch({
			type: GET_PROJECT_OFFERS_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		})
	}

}


export const getProjectPaymentSchedule = (projectId) => async (dispatch, getState) => {
	try {

		dispatch({
			type: GET_PROJECT_PAYMENT_SCHEDULE_REQUEST
		})

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				"Authorization": `Bearer ${userInfo?.token}`
			}
		}
		const { data } = await axios.get(`get-project-payment-schedule/${projectId}/`, config);

		dispatch({
			type: GET_PROJECT_PAYMENT_SCHEDULE_SUCCESS,
			payload: data
		})

	} catch (error) {
		dispatch({
			type: GET_PROJECT_PAYMENT_SCHEDULE_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		})
	}
}


export const getProjectPricingList = (projectId) => async (dispatch, getState) => {
	try {

		dispatch({ type: GET_PROJECT_PRICING_LIST_REQUEST })

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}

		}
		const { data } = await axios.get(`get/project-pricing-list/${projectId}/`, config);

		dispatch({ type: GET_PROJECT_PRICING_LIST_SUCCESS, payload: data })


	} catch (error) {
		dispatch({
			type: GET_PROJECT_PRICING_LIST_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		})
	}
}





export const saveWhatsappTemplate = (postData) => async (dispatch, getState) => {
	try{

		dispatch({
			type: SAVE_WHATSAPP_TEMPLATE_REQUEST
		})

		const { userState: { userInfo } } = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				// Authorization: `Bearer ${userInfo?.token}`
			}
		}
		const { data } = await axios.post("save-whatsapp-template", postData, config);

		dispatch({
			type: SAVE_WHATSAPP_TEMPLATE_SUCCESS,
			payload: data
		})

		toast.success("Whatsapp Template Saved Successfully");

	}catch(error){
		dispatch({
			type: SAVE_WHATSAPP_TEMPLATE_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		})
	}
}