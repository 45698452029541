import CreHomePage from "./CREDashboard";
import Visits from "./CREVisits";
import Teams from "./CRETeams";
import CheckInVisitors from "./CheckInVisitors";
import AddImage from "./CheckInProcess/AddImage";
import AssignSalesExecutive from "./CheckInProcess/AssignSalesExecutive";
import Source from "./CheckInProcess/Source";
import BasicInfo from "./CheckInProcess/BasicInfo";
import ResidentialInfo from "./CheckInProcess/ResidentialInfo";
import VerifyNumber from "./CheckInProcess/VerifyNumber";
import VerifyVisitor from "./CheckInProcess/VerifyVisitor";
import WorkInfo from "./CheckInProcess/workInfo";
import Requirements from "./CheckInProcess/Requirements";
import Layout from "./Layout/Layout";
import React from "react";
import Profile from "../../Profile";
import Scanner from "./CheckInProcess/Scanner";
import EditProfile from "../EditProfile";
const CREProfile = () => {
	return (
		<Layout pageTitle={"Profile"}>
			<Profile />
		</Layout>
	);
};
const CREEditProfile = () => {
	return (
		<Layout pageTitle={"Edit Profile"}>
			<EditProfile />
		</Layout>
	);
};
export {
	CREProfile,
	CreHomePage,
	Visits,
	Teams,
	CheckInVisitors,
	AddImage,
	AssignSalesExecutive,
	Source,
	BasicInfo,
	ResidentialInfo,
	VerifyNumber,
	VerifyVisitor,
	WorkInfo,
	Requirements,
	Scanner,
	CREEditProfile,
};