import {
	//
	GET_DASHBOARD_DATA_REQUEST,
	GET_DASHBOARD_DATA_SUCCESS,
	GET_DASHBOARD_DATA_FAIL,
	//
	GET_LEADS_REQUEST,
	GET_LEADS_SUCCESS,
	GET_LEADS_FAIL,
	//
	GET_LEAD_DETAIL_REQUEST,
	GET_LEAD_DETAIL_SUCCESS,
	GET_LEAD_DETAIL_FAIL,
	//
	GET_PROJECTS_REQUEST,
	GET_PROJECTS_SUCCESS,
	GET_PROJECTS_FAIL,
	//
	GET_MY_PROJECT_BUCKET_REQUEST,
	GET_MY_PROJECT_BUCKET_SUCCESS,
	GET_MY_PROJECT_BUCKET_FAIL,
	//
	TAG_PROJECT_REQUEST,
	TAG_PROJECT_SUCCESS,
	TAG_PROJECT_FAIL,
	//
	GET_PROJECT_DETAIL_REQUEST,
	GET_PROJECT_DETAIL_SUCCESS,
	GET_PROJECT_DETAIL_FAIL,
	//
	GET_PROJECT_DOCUMENTS_REQUEST,
	GET_PROJECT_DOCUMENTS_SUCCESS,
	GET_PROJECT_DOCUMENTS_FAIL,
	//
	GET_EMPLOYEES_REQUEST,
	GET_EMPLOYEES_SUCCESS,
	GET_EMPLOYEES_FAIL,
	//
	DETAIL_EMPLOYEES_REQUEST,
	DETAIL_EMPLOYEES_SUCCESS,
	DETAIL_EMPLOYEES_FAIL,
	//
} from "../Constants/CPConstants";

export const dashboardDataReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_DASHBOARD_DATA_REQUEST:
			return { loading: true };
		case GET_DASHBOARD_DATA_SUCCESS:
			return { loading: false, dashData: action.payload };
		case GET_DASHBOARD_DATA_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const cpLeadsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_LEADS_REQUEST:
			return { loading: true };
		case GET_LEADS_SUCCESS:
			return { loading: false, leads: action.payload };
		case GET_LEADS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const detailLeadReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_LEAD_DETAIL_REQUEST:
			return { loading: true };
		case GET_LEAD_DETAIL_SUCCESS:
			return { loading: false, detail: action.payload };
		case GET_LEAD_DETAIL_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const projectDataReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROJECTS_REQUEST:
			return { loading: true };
		case GET_PROJECTS_SUCCESS:
			return { loading: false, projectsData: action.payload };
		case GET_PROJECTS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const bucketProjectReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_MY_PROJECT_BUCKET_REQUEST:
			return { loading: true };
		case GET_MY_PROJECT_BUCKET_SUCCESS:
			return { loading: false, projects: action.payload };
		case GET_MY_PROJECT_BUCKET_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const tagProjectReducer = (state = {}, action) => {
	switch (action.type) {
		case TAG_PROJECT_REQUEST:
			return { loading: true };
		case TAG_PROJECT_SUCCESS:
			return { loading: false, projects: action.payload };
		case TAG_PROJECT_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const projectDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROJECT_DETAIL_REQUEST:
			return { loading: true };
		case GET_PROJECT_DETAIL_SUCCESS:
			return { loading: false, detail: action.payload };
		case GET_PROJECT_DETAIL_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const projectDocumentReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROJECT_DOCUMENTS_REQUEST:
			return { loading: true };
		case GET_PROJECT_DOCUMENTS_SUCCESS:
			return { loading: false, files: action.payload };
		case GET_PROJECT_DOCUMENTS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const CPEmployeesReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_EMPLOYEES_REQUEST:
			return { loading: true };
		case GET_EMPLOYEES_SUCCESS:
			return { loading: false, employeePagination: action.payload };
		case GET_EMPLOYEES_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const CPEmployeeDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case DETAIL_EMPLOYEES_REQUEST:
			return { loading: true };
		case DETAIL_EMPLOYEES_SUCCESS:
			return { loading: false, detail: action.payload };
		case DETAIL_EMPLOYEES_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};
