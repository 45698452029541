import React, { useState, useRef } from "react";
import pdfIcon from "../assets/file-icons/pdf-icon.png";
import excelIcon from "../assets/file-icons/excel-icon.png";
import wordIcon from "../assets/file-icons/word-icon.png";
import jpgICON from "../assets/file-icons/jpg-icon.png";
import pngIcon from "../assets/file-icons/png-icon.png";
import mp4Icon from "../assets/file-icons/mp4-icon.png";
import movIcon from "../assets/file-icons/mov-icon.png";
import mp3Icon from "../assets/file-icons/mp3-icon.png";
import unknownIcon from "../assets/file-icons/unknown-icon.png";
const FileUploader = ({ dbFile }) => {
	const inputFile = useRef(null);
	const [file, setFile] = useState();
	const [inputFileName, setInputFileName] = useState();
	// const fileType = dbFile
	// 	? dbFile.split(".")[dbFile.split(".").length - 1]
	// 	: file
	// 	? inputFileName.split(".")[inputFileName.split(".").length - 1]
	// 	: null;
	const fileType = file
		? inputFileName.split(".")[inputFileName.split(".").length - 1]
		: dbFile
		? dbFile.split(".")[dbFile.split(".").length - 1]
		: null;
	const fileName = file
		? inputFileName
		: dbFile
		? dbFile.split("/")[dbFile.split("/").length - 1]
		: null;

	// const imageObject={
	//     pdf:pdfIcon,
	//     excel:excelIcon,
	//     word:wordIcon,
	// }

	const image = (fileType) => {
		switch (fileType) {
			case "pdf":
				return pdfIcon;
			case "xlsx":
				return excelIcon;
			case "csv":
				return excelIcon;
			case "docx":
				return wordIcon;
			case "doc":
				return wordIcon;
			case "txt":
				return wordIcon;
            case "jpg":
                return jpgICON
            case "jpeg":
                return jpgICON
            case "png":
                return pngIcon
            case "mp4":
                return mp4Icon
            case "mov":
                return movIcon
            case "mp3":
                return mp3Icon
			default:
				return unknownIcon;
		}
	};

	return (
		<div
			className="w-100 h-100 p-2  hover_to_show position-relative"
			onDragOver={(e) => {
				e.preventDefault();
			}}
			onDrop={(event) => {
				event.preventDefault();
				inputFile.current.value = "";
				inputFile.current.files = event.dataTransfer.files;
				// const dT = new DataTransfer();
				const file = event.dataTransfer.files[0];
				setInputFileName(file.name);
				const fileReader = new FileReader();
				fileReader.readAsDataURL(file);
				fileReader.onload = () => {
					setFile(fileReader.result);
				};
			}}
		>
			<input
				ref={inputFile}
				type="file"
				name="file"
				onChange={(e) => {
					const file = e.target.files[0];
					setInputFileName(file.name);
					const fileReader = new FileReader();
					fileReader.readAsDataURL(file);
					fileReader.onload = () => {
						setFile(fileReader.result);
					};
				}}
				hidden
			/>
			{fileType !== null ? (
				<div>
					<div className="d-flex justify-content-center align-items-center">
						<img className="img-thumbnail w-80  " src={image(fileType)} alt="" style={{height:"300px",width:"300px"}} />
					</div>
					<div className="text-center">
						<p className="m-0">{fileName}</p>
					</div>
					<div className="div_to_show position-absolute top-0  w-100 h-100 ">
						<div className="d-grid place-items-center p-3 border h-100  rounded ">
							<p className="m-0 text-danger fs-4 fw-bold text-shadow">
								Drag & Drop
							</p>
							<p className="my-3 text-danger fs-4 fw-bold text-shadow"> or </p>
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									inputFile.current.click();
								}}
							>
								Choose a file to Upload
							</button>
						</div>
					</div>
				</div>
			) : (
				<>
					<div className="d-grid place-items-center p-3 border rounded ">
						<p className="m-0">Drag & Drop</p>
						<p className="my-3"> or </p>
						<button
							type="button"
							className="btn btn-outline-primary"
							onClick={() => {
								inputFile.current.click();
							}}
						>
							Choose a file to Upload
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default FileUploader;
