import React, { useState, useRef, useEffect } from 'react'

const CreateBody = ({ whatsappDataHandler, dataToShow }) => {
    const [body, setBody] = useState({
        type: "BODY",
        text: null,
    })
    const [variables, setVariables] = useState({})
    const textRef = useRef()

    useEffect(()=>{
        const body_handle = Object.keys(variables).map(i => { return variables[i] })
        const data={...body}
        if(body_handle.length > 0){
            data.example = { body_text : [body_handle]}
        }
        setBody(data)
    },[variables])

    useEffect(()=>{
        if (body.text == null) return
        whatsappDataHandler(body);

        let body_text = body.text;
        Object.keys(variables).forEach(i => {
            body_text = body_text.replaceAll(i, variables[i]);
        });

        dataToShow({ type: "body", value: body_text });
    },[body,variables])

    return (
        <div>
            <label htmlFor="body">Template Body</label>
            <textarea ref={textRef} name="body" placeholder='Enter Body' className='form-control' id="body" rows={4} onKeyUp={(e) => {
                const data = {
                    ...variables
                }
                if (e.key === "{") {
                    var new_var = `{{${Object.keys(variables).length + 1}}}`
                    textRef.current.value = textRef.current.value.slice(0, -2) + new_var
                    data[new_var] = null
                }
                setVariables(data)
            }} defaultValue={body.value} onChange={e => {
                const data = {
                    ...variables
                }
                Object.keys(data).map(i => {
                    if (!e.target.value.includes(i)) {
                        delete data[i]
                    }
                })
                setVariables(data)
                const body_data={...body}
                body_data.text=e.target.value
                setBody(body_data)
            }}  ></textarea>
            <div className="text-end">
                <span onClick={() => {
                    var new_var = `{{${Object.keys(variables).length + 1}}}`
                    textRef.current.value = textRef.current.value + new_var
                    const data = {
                        ...variables
                    }
                    data[new_var] = null
                    setVariables(data)
                }} >Add Variable</span>
            </div>
            {Object.keys(variables).filter(i => "length" !== i).map(i => <p>{i} : <input type="text" className='form-control' onChange={e=>{const data = {...variables};data[i]=e.target.value;setVariables(data)}}  /></p>)}
        </div>
    )
}

export default CreateBody