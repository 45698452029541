import React, { useState, useEffect } from "react";
import Layout from "./Layout/Layout";
import InfiniteScroll from "react-infinite-scroll-component";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCPEmployees, deleteCPEmployee } from "../../Actions/CPAction";
import Loading from "../../Components/Loading";
const ManageEmployees = () => {
	const [data, setData] = useState([
		{
			name: "John Doe",
			designation: "Software Engineer",
			image: "https://example.com/johndoe.jpg",
			mobile_number: "1234567890",
			email: "johndoe@example.com",
			id: "1",
		},
		{
			name: "Jane Smith",
			designation: "Product Manager",
			image: "https://example.com/janesmith.jpg",
			mobile_number: "0987654321",
			email: "janesmith@example.com",
			id: "2",
		},
		{
			name: "Alex Johnson",
			designation: "Data Analyst",
			image: "https://example.com/alexjohnson.jpg",
			mobile_number: "5555555555",
			email: "alexjohnson@example.com",
			id: "3",
		},
		{
			name: "Emily Davis",
			designation: "UX Designer",
			image: "https://example.com/emilydavis.jpg",
			mobile_number: "9876543210",
			email: "emilydavis@example.com",
			id: "4",
		},
		{
			name: "Michael Wilson",
			designation: "Sales Representative",
			image: "https://example.com/michaelwilson.jpg",
			mobile_number: "1111111111",
			email: "michaelwilson@example.com",
			id: "5",
		},
		{
			name: "Sophia Lee",
			designation: "HR Manager",
			image: "https://example.com/sophialee.jpg",
			mobile_number: "2222222222",
			email: "sophialee@example.com",
			id: "6",
		},
		{
			name: "David Brown",
			designation: "Finance Analyst",
			image: "https://example.com/davidbrown.jpg",
			mobile_number: "3333333333",
			email: "davidbrown@example.com",
			id: "7",
		},
		{
			name: "Olivia Taylor",
			designation: "Marketing Coordinator",
			image: "https://example.com/oliviataylor.jpg",
			mobile_number: "4444444444",
			email: "oliviataylor@example.com",
			id: "8",
		},
		{
			name: "Daniel Clark",
			designation: "Operations Manager",
			image: "https://example.com/danielclark.jpg",
			mobile_number: "6666666666",
			email: "danielclark@example.com",
			id: "9",
		},
		{
			name: "Ava Martinez",
			designation: "Customer Service Representative",
			image: "https://example.com/avamartinez.jpg",
			mobile_number: "7777777777",
			email: "avamartinez@example.com",
			id: "10",
		},
		{
			name: "Matthew White",
			designation: "Quality Assurance Engineer",
			image: "https://example.com/matthewwhite.jpg",
			mobile_number: "8888888888",
			email: "matthewwhite@example.com",
			id: "11",
		},
		{
			name: "Samantha Harris",
			designation: "Business Analyst",
			image: "https://example.com/samanthaharris.jpg",
			mobile_number: "9999999999",
			email: "samanthaharris@example.com",
			id: "12",
		},
		{
			name: "Christopher Thomas",
			designation: "Project Manager",
			image: "https://example.com/christopherthomas.jpg",
			mobile_number: "1212121212",
			email: "christopherthomas@example.com",
			id: "13",
		},
		{
			name: "Isabella Garcia",
			designation: "Graphic Designer",
			image: "https://example.com/isabellagarcia.jpg",
			mobile_number: "1313131313",
			email: "isabellagarcia@example.com",
			id: "14",
		},
		{
			name: "Andrew Martinez",
			designation: "Software Engineer",
			image: "https://example.com/andrewmartinez.jpg",
			mobile_number: "1414141414",
			email: "andrewmartinez@example.com",
			id: "15",
		},
		{
			name: "Mia Robinson",
			designation: "Product Manager",
			image: "https://example.com/miarobinson.jpg",
			mobile_number: "1515151515",
			email: "miarobinson@example.com",
			id: "16",
		},
		{
			name: "Ethan Clark",
			designation: "Data Analyst",
			image: "https://example.com/ethanclark.jpg",
			mobile_number: "1616161616",
			email: "ethanclark@example.com",
			id: "17",
		},
		{
			name: "Abigail Lewis",
			designation: "UX Designer",
			image: "https://example.com/abigaillewis.jpg",
			mobile_number: "1717171717",
			email: "abigaillewis@example.com",
			id: "18",
		},
		{
			name: "James Walker",
			designation: "Sales Representative",
			image: "https://example.com/jameswalker.jpg",
			mobile_number: "1818181818",
			email: "jameswalker@example.com",
			id: "19",
		},
		{
			name: "Charlotte Hall",
			designation: "HR Manager",
			image: "https://example.com/charlottehall.jpg",
			mobile_number: "1919191919",
			email: "charlottehall@example.com",
			id: "20",
		},
	]);
	const [page, setPage] = useState(1);
	const [dataCount, setDataCount] = useState(20);
	const [hasMore, setHasMore] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { loading, error, employeePagination } = useSelector(
		(state) => state?.cp?.employees
	);
	useEffect(() => {
		if (employeePagination !== undefined) {
			setData(employeePagination.employees);
			setPage(employeePagination.page);
			setDataCount(employeePagination.total_employees);
			setHasMore(employeePagination.next_page);
		}
	}, [employeePagination]);
	useEffect(() => {
		dispatch(getCPEmployees({ page: page }));
	}, [page]);

	return (
		<Layout pageTitle={"Manage Employees"}>
			{
				loading ?
				<div className="h-100 justify-content-center align-items-center">
					<Loading />
				</div>
				: 
			<div className="h-100">
				<div
					className="d-flex align-items-center justify-content-between"
					style={{ height: "7%" }}
				>
					<h1 className="m-2">Employees List</h1>
					<button
						className="btn btn-primary"
						onClick={() => navigate("/add-employee")}
						style={{ height: "max-content" }}
					>
						Add 
					</button>
				</div>
				<div style={{ overflowY: "scroll", maxHeight: "87%" }}>
					<InfiniteScroll
						dataLength={dataCount}
						next={() => setPage(page + 1)}
						className="d-grid gap-2 p-2"
						hasMore={hasMore}
						loader={<h4>Loading...</h4>}
						scrollableTarget="scrollableDiv"
					>
						{data.map((employee) => {
							return (
								<>
									<div className="d-flex border gap-3 rounded position-relative shadow p-2">
										<div>
											<Dropdown
												className="position-absolute end-0  "
												style={{ zIndex: "1" }}
											>
												<Dropdown.Toggle
													className="hide-arrow"
													variant=""
													id="dropdown-basic"
												>
													<FontAwesomeIcon icon={icon.faEllipsis} />
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item
														onClick={() => {
															navigate(`/edit-employee/${employee.id}`);
														}}
													>
														Edit
													</Dropdown.Item>
													<Dropdown.Item
														onClick={() => {
															dispatch(deleteCPEmployee(employee.id));
														}}
													>
														Delete
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</div>
										<img
											className="img-thumbnail"
											src={"https://api.eazyapp.in" + employee.photo}
											alt=""
											style={{ height: "100px", width: "100px" }}
										/>
										<div className="flex-fill">
											<h3 className="card_heading mb-0">{employee.name}</h3>
											<p className="mt-0 mb-1 text-muted ">
												{employee.designation}
											</p>
											<div className=" d-flex justify-content-between px-1 border-top mt-2 ">
												<a
													href={`tel:${employee.phone_number}`}
													className="fs-4 fw-bold text-primary "
												>
													<FontAwesomeIcon icon={icon.faPhone} />
												</a>
												<a
													href={`sms:+${employee.phone_number}`}
													className="fs-4 fw-bold text-secondary "
												>
													<FontAwesomeIcon icon={icon.faMessage} />
												</a>
												<a
													href={`mailto:${employee.email}`}
													className="fs-4 fw-bold text-danger "
												>
													<FontAwesomeIcon icon={icon.faEnvelope} />
												</a>
											</div>
										</div>
									</div>
								</>
							);
						})}
					</InfiniteScroll>
				</div>
			</div>
			}
		</Layout>
	);
};

export default ManageEmployees;
