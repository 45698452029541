import React,{useState,useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	getPaymentSlab,
	updatePaymentSlab,
} from "../../../../Actions/AdminAction";
import Layout from "../../Layout/Layout";
import moment from "moment";
const SlabEdit = () => {
    const [key,setKey]=useState("")
    const [value,setValue]=useState("")
    const [date,setDate]=useState("")
	const { slabId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { loading, error, slabData } = useSelector(
			(state) => state?.developer?.paymentSlab
		);

    useEffect(()=>{
        if(slabData){
            setKey(slabData.key)
            setValue(slabData.value)
            setDate(slabData.date_to_ask)
        }
    },[slabData])
    useEffect(()=>{
        dispatch(getPaymentSlab(slabId))
    },[])

    const saveHandler=()=>{
        const postData={
            key:key,
            value:value,
            date_to_ask:date 
        }
        dispatch(updatePaymentSlab(postData,slabId,navigate));
    }

    if(slabData){

	return (
		<Layout pageTitle={"Slab Edit"}>
			<div className="d-grid p-3 gap-3">
				<div className="rounded border p-3 d-grid gap-3">
					<div>
						<label>Label</label>
						<input
							type="text"
							value={key}
							onChange={(e) => setKey(e.target.value)}
							className="form-control"
						/>
					</div>
					<div>
						<label>Value</label>
						<input
							type="text"
							value={value}
							onChange={(e) => setValue(e.target.value)}
							className="form-control"
						/>
					</div>
					<div>
						<label>Date</label>
						<input
							type="date"
							value={date}
							onChange={(e) => setDate(e.target.value)}
							className="form-control"
						/>
					</div>
					<div className="text-end p-2">
						<button className="btn btn-success" onClick={saveHandler}>
							Save
						</button>
					</div>
				</div>
			</div>
		</Layout>
	);
    }
};

export default SlabEdit;
