import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/eazyapp-logo-blue.png";
import * as Icon from "react-bootstrap-icons";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { userLogout } from "../../../Actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
function Sidebar(props) {
	const dispatch = useDispatch();
	/**
	 * A function that checks the value of props.props and returns "active" if it matches either "ServiceOptions" or "AddServiceScreen".
	 *
	 * @return {string} The string "active" if the condition is met, otherwise undefined.
	 */
	const check = () => (props.props === "ServiceOptions" || props.props === "AddServiceScreen") ? "active" : undefined;


	const permissions = useSelector(state => state?.permissions)

	return (
		<div className="sidebar_left d-none d-lg-block">
			<div className="sidbar_logo d-flex justify-content-center">
				{/* <h2 className="text-warning">EazyApp</h2> */}
				<img src={logo} alt="logo" className="img-fluid" />
			</div>
			<div>
				<ul className="list-group">
					<li className="menu_links">
						<NavLink
							to="/"
							className={`d-flex align-items-center link_options `}
							activeclassname="active"
						>
							<Icon.HouseFill /> <span className="ms-2"> Home</span>
						</NavLink>
					</li>
					{
						permissions.developer_view &&

						<li className="menu_links">
							<NavLink
								to="/developers"
								className={`d-flex align-items-center link_options `}
								activeclassname="active"
							>
								<Icon.PersonBadge /> <span className="ms-2"> Developers</span>
							</NavLink>
						</li>

					}
					{
						permissions.projects_view &&

						<li className="menu_links">
							<NavLink
								to="/projects"
								className={`d-flex align-items-center link_options `}
								activeclassname="active"
							>
								<Icon.Building /> <span className="ms-2"> Projects</span>
							</NavLink>
						</li>

					}
					{
						permissions.channel_partners_view &&

						<li className="menu_links">
							<NavLink
								to="/channel-partners"
								className={`d-flex align-items-center link_options `}
								activeclassname="active"
							>
								<Icon.Bezier /> <span className="ms-2"> Channel Partners</span>
							</NavLink>
						</li>

					}
					{
						permissions.admin_user_view &&

						<li className="menu_links">
							<NavLink
								to="/admin-users"
								className={`d-flex align-items-center link_options `}
								activeclassname="active"
							>
								<Icon.People /> <span className="ms-2"> Admin Users</span>
							</NavLink>
						</li>

					}
					{
						permissions.role_view &&

						<li className="menu_links">
							<NavLink
									to="/admin-user-roles"
								className={`d-flex align-items-center link_options `}
								activeclassname="active"
							>
								<Icon.People /> <span className="ms-2"> Admin Role</span>
							</NavLink>
						</li>

					}
					<li className="menu_links mt-auto">
						<button
							className="d-flex btn-danger m-0 border-0 align-items-center link_options "
							onClick={() => dispatch(userLogout())}
						>
							<Icon.BoxArrowLeft className="me-2" /> Logout
						</button>
						{/* <NavLink
							to="/channel-partner"
							className={`d-flex align-items-center link_options `}
							activeclassname="active"
						>
							<FontAwesomeIcon icon={icon.faUserTie} />
							<span className="ms-2"> Channel Partner</span>
						</NavLink> */}
					</li>
					{/* <li className="menu_links">
						<NavLink
							to="/profile"
							className={`d-flex align-items-center link_options `}
							activeclassname="active"
						>
							<Icon.Person /> <span className="ms-2"> Profile</span>
						</NavLink>
					</li> */}
					{/* <li className="menu_links">
						<NavLink
							to="/channel-partner"
							className={`d-flex align-items-center link_options `}
							activeclassname="active"
						>
							<FontAwesomeIcon icon={icon.faUserTie} />
							<span className="ms-2"> Channel Partner</span>
						</NavLink>
					</li> */}
				</ul>
			</div>
		</div>
	);
}

export default Sidebar;
