import { combineReducers, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { UserLoginReducer, userDataReducer } from "./Reducers/UserReducer";
import Cookies from "js-cookie";
import logger, { createLogger } from 'redux-logger';
import immutableStateInvariantMiddleware from 'redux-immutable-state-invariant';


import {
	creDashboardReducer,
	creTeamsReducer,
	creVisitReducer,
	checkInDetialsReducer,
	AvailableExcutivesReducer,
	leadDetailReducer,
} from "./Reducers/CREReducer";
import {
	SEDashboardReducer,
	visitorStatusReducer,
	visitorDetailReducer,
	visitorsReducer,
	VisitorsDetailReducer,
	MyTeamReducer,
	ChannelPartnerReducer,
	ChannelPartnerDetailReducer,
	inventoryReducer,
	channelPartnerReducer,
	cpReportReducer
} from "./Reducers/SEReducer";

import {
	taskReducer,
	projectReducer,
	ReportReducer,
	ProjectReportReducer,
	projectWingsReducer,
	ProjectCollateralsReducer,
	ProjectOffersReducer,
	ProjectPaymentScheduleReducer,
	ProjectPricingListReducer,
	loadingReducer
} from "./Reducers/CommanReducer";
import {
	dashboardDataReducer,
	cpLeadsReducer,
	detailLeadReducer,
	projectDataReducer,
	bucketProjectReducer,
	tagProjectReducer,
	projectDetailReducer,
	projectDocumentReducer,
	CPEmployeesReducer,
	CPEmployeeDetailReducer,
} from "./Reducers/CPReducer";

import {
	developerDashBoardDataReducer,
	developerProjectsListReducer,
	developerProjectDetailReducer,
	projectConfigDetailReducer,
	projectGalleryDetailReducer,
	developerProjectAmenitieHandlerReducer,
	documentDetailReducer,
	developerEmployeesReducer,
	developerTeamListReducer,
	developerTeamDetailReducer,
	developerDetailReducer,
	developerChannelPartnerReducer,
	developerChannelPartnerDetailReducer,
	developerProjectPaymentSchedule,
	paymentSlabReducer,
	paymentScheduleReducer,
	projectPricingListReducer,
	pricingDetailsReducer,
	projectOfferListReducer,
	offerDetailReducer,
	inventoryDataReducer,
	getDeveloperSettingDetailReducer,
	getEmployeeDetailReducer,
	customerBookingListReducer,
	customerBookingDetailReducer,
	projectConfigListReducer,
	projectAutomationListReducer,
	whatsappTemplateReducer,
	whatsappAccountReducer,
	visitorListReducer
} from "./Reducers/AdminReducer";
import { permissionReducer } from "./Reducers/PermissonReducer";
import {
	developerListReducer,
	developerDetailsReducer,
	developerEmployeeListReducer,
	developerEmployeeDetailsReducer,
	projectListReducer,
	projectDetailsReducer,
	channelPartnerListReducer,
	channelPartnerDetailReducer,
	cpEmployeeDetailReducer,
	developerSettingTypeReducer,
	settingTypeReducer,
	AdmindashboardDataReducer,
	AdminUserListReducer,
	adminUserDetailReducer,
	adminRoleListReducer,
	adminPermissionsListReducer,
} from "./Reducers/MyAdminReducer";


import {
	customerDashboardDataReducer, customerProjectDocumentReducer, customerPricingPaymentReducer, customerPaymentScheduleReducer, customerPaymentListReducer, MyBookingsReducer,
	CurrentBookingIdReducer
} from './Reducers/CustomerReducer'

const reducer = combineReducers({
	loading: loadingReducer,
	userState: UserLoginReducer,
	userProfile: userDataReducer,
	projectOffers: ProjectOffersReducer,
	projectPaymentSchedule: ProjectPaymentScheduleReducer,
	projectPricing: ProjectPricingListReducer,
	project: projectReducer,
	collaterals: ProjectCollateralsReducer,
	cre: combineReducers({
		dashboardData: creDashboardReducer,
		teamsData: creTeamsReducer,
		visitsData: creVisitReducer,
		checkInDetial: checkInDetialsReducer,
		availableExcutives: AvailableExcutivesReducer,
		lead: leadDetailReducer,
	}),
	se: combineReducers({
		dashboardData: SEDashboardReducer,
		visitor: visitorStatusReducer,
		visitorData: visitorsReducer,
		visitorDetail: visitorDetailReducer,
		visitorsDetail: VisitorsDetailReducer,
		MyTeam: MyTeamReducer,
		taskData: taskReducer,
		channelPartner: channelPartnerReducer,
		channelPartnerState: ChannelPartnerReducer,
		channelPartnerDetailState: ChannelPartnerDetailReducer,
		report: ReportReducer,
		projectReport: ProjectReportReducer,
		inventory: inventoryReducer,
		projectWings: projectWingsReducer,
		cpReport: cpReportReducer,
	}),
	cp: combineReducers({
		dashboard: dashboardDataReducer,
		leads: cpLeadsReducer,
		detailLead: detailLeadReducer,
		projects: projectDataReducer,
		myBucket: bucketProjectReducer,
		tagProject: tagProjectReducer,
		project: projectDetailReducer,
		document: projectDocumentReducer,
		employees: CPEmployeesReducer,
		employeeDetail: CPEmployeeDetailReducer,
	}),
	developer: combineReducers({
		dashData: developerDashBoardDataReducer,
		projectList: developerProjectsListReducer,
		whatsappAuth: whatsappAccountReducer,
		visitorData:combineReducers({
			list:visitorListReducer
		}),
		project: combineReducers({
			detail: developerProjectDetailReducer,
			configDetail: projectConfigDetailReducer,
			galleryItem: projectGalleryDetailReducer,
			amenitie: developerProjectAmenitieHandlerReducer,
			document: documentDetailReducer,
			paymentScheduleList: developerProjectPaymentSchedule,
			pricingList: projectPricingListReducer,
			offerList: projectOfferListReducer,
			projectAutomationList: projectAutomationListReducer,
			whatsappTemplate: whatsappTemplateReducer	
		}),
		employee: combineReducers({
			list: developerEmployeesReducer,
			detail: getEmployeeDetailReducer
		}),
		customer: combineReducers({
			list: customerBookingListReducer,
			bookingDetail: customerBookingDetailReducer,
		}),
		teamList: developerTeamListReducer,
		teamDetail: developerTeamDetailReducer,
		detail: developerDetailReducer,
		channelPartnerList: developerChannelPartnerReducer,
		channelPartner: developerChannelPartnerDetailReducer,
		paymentSlab: paymentSlabReducer,
		paymentSchedule: paymentScheduleReducer,
		pricingDetail: pricingDetailsReducer,
		offerDetail: offerDetailReducer,
		inventoryData: inventoryDataReducer,
		projectConfigList: projectConfigListReducer,
	}),
	permissions: permissionReducer,
	myAdmin: combineReducers({
		developerList: developerListReducer,
		developerDetails: developerDetailsReducer,
		developerEmployeeList: developerEmployeeListReducer,
		developerEmployeeDetails: developerEmployeeDetailsReducer,
		developerSettingType: developerSettingTypeReducer,
		projectList: projectListReducer,
		projectDetails: projectDetailsReducer,
		channelPartnerList: channelPartnerListReducer,
		channelPartnerDetail: channelPartnerDetailReducer,
		cpEmployeeDetail: cpEmployeeDetailReducer,
		settingTypes: settingTypeReducer,
		dashboardData: AdmindashboardDataReducer,
		adminUserList: AdminUserListReducer,
		adminUserDetail: adminUserDetailReducer,
		adminRoleList: adminRoleListReducer,
		adminPermissionsList: adminPermissionsListReducer,
		getDeveloperSettingDetail: getDeveloperSettingDetailReducer,
	}),

	customer: combineReducers({
		dashboardData: customerDashboardDataReducer,
		projectDocument: customerProjectDocumentReducer,
		pricingPayment: customerPricingPaymentReducer,
		paymentSchedule: customerPaymentScheduleReducer,
		paymentList: customerPaymentListReducer,
		currentBookingId: CurrentBookingIdReducer,
		myBookings: MyBookingsReducer,

	})

});

const userInfoFromStorage = Cookies.get("userInfo")
	? JSON.parse(Cookies.get("userInfo"))
	: null;
const ChechkInDetail = localStorage.getItem("checkInData")
	? JSON.parse(localStorage.getItem("checkInData"))
	: {};


const initialState = {
	userState: { userInfo: userInfoFromStorage },
	cre: {
		checkInDetial: { checkInData: ChechkInDetail },
	},
};

// const middleware = [immutableStateInvariantMiddleware(), logger];
const middleware = [];

const store = configureStore({
	reducer: reducer,
	preloadedState: initialState,
	/**
	 * Returns a middleware array that concatenates the default middleware with additional middleware.
	 *
	 * @param {function} getDefaultMiddleware - A function to retrieve the default middleware
	 * @return {array} The concatenated middleware array
	 */
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(...middleware),
	devTools: true,
	// devTools: composeWithDevTools(),
	// middleware: composeWithDevTools(applyMiddleware(...middleware)),
});

export default store;
