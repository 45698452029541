import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";
import { useDispatch } from "react-redux";
import { addNoteAction,editLogAction } from "../Actions/CommonAction";
import { useNavigate, useLocation } from "react-router-dom";
const AddNote = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { state } = location;
	const dispatch = useDispatch();
	const [comment, setComment] = useState("");
	const [createTask, setCreateTask] = useState(false);
	const [followUpDate, setFollowUpDate] = useState(
		moment().add(3, "days").format("YYYY-MM-DD")
	);
	const [getNewFollowUpdate, setGetNewFollowupDate] = useState(false);

	useEffect(() => {
		if (state?.log?.comment !== null && state?.log?.comment !== undefined) {
			setComment(state?.log?.comment);
		}
	}, []);

	if (getNewFollowUpdate) {
		return (
			<>
				<div className="main_container m-0 p-0">
					<div className="w-100">
						<button
							className="btn ms-0"
							onClick={() => {
								setGetNewFollowupDate(false);
							}}
						>
							<Icon.ArrowLeft />
						</button>
					</div>
					<div className="row ">
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(1, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(moment().add(1, "days").format("YYYY-MM-DD"));
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">Tomorrow</p>
								<p className=" fs-6 text-muted">
									{moment().add(1, "days").format("dddd")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(2, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(moment().add(2, "days").format("YYYY-MM-DD"));
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">In 2 Days</p>
								<p className=" fs-6 text-muted">
									{moment().add(2, "days").format("dddd")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(3, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(moment().add(3, "days").format("YYYY-MM-DD"));
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">In 3 Days</p>
								<p className=" fs-6 text-muted">
									{moment().add(3, "days").format("dddd")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(7, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(moment().add(7, "days").format("YYYY-MM-DD"));
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">1 Week</p>
								<p className=" fs-6 text-muted">
									{moment().add(7, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(14, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(14, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">2 Week</p>
								<p className=" fs-6 text-muted">
									{moment().add(14, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(30, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(30, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">1 Month</p>
								<p className=" fs-6 text-muted">
									{moment().add(30, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(90, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(90, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">3 Month</p>
								<p className=" fs-6 text-muted">
									{moment().add(90, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(180, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(180, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">6 Month</p>
								<p className=" fs-6 text-muted">
									{moment().add(180, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-12">
							{/* <button
								className={`btn border w-100 border-primary ${moment().add(180, "days").format(
									"YYYY-MM-DD"
								) === followUpDate && "border-3"}`}
								value={moment().add(180, "days").format("YYYY-MM-DD")}
								onClick={(e) => {
									setFollowUpDate(e.target.value);
									setGetNewFollowupDate(false);
								}}
							></button> */}
						</div>
					</div>
				</div>
			</>
		);
	}

	const AddNoteHandler = (e) => {
		e.preventDefault();
		var formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		if (state?.log?.id === undefined) {
			dispatch(addNoteAction(object, navigate));
		} else {
			dispatch(editLogAction(object, navigate));
		}
	};

	return (
		<div className="vh-100 px-1 py-1">
			<div className="mx-1 border-bottom">
				<h3 className="">
					{" "}
					<button className="btn fs-5" onClick={() => navigate(-1)}>
						<Icon.ArrowLeft />
					</button>{" "}
					Add Note{" "}
				</h3>
			</div>
			<div className="h-92 px-3">
				<div className="px-3 d-flex align-items-start pt-5 h-100 justify-content-center ">
					<form onSubmit={AddNoteHandler} className="w-80 d-grid gap-3">
						{state?.log?.id !== undefined && (
							<input type="text" name="log_id" value={state?.log?.id} hidden />
						)}
						<input
							type="text"
							name="visitor_id"
							value={state.visitorId}
							hidden
						/>
						<input type="text" name="log_type" value="Note" hidden />
						<div className="">
							<label className=" fs-8 text-muted bg-white ">Note :</label>
							<textarea
								className="form-control"
								value={comment}
								onChange={(e) => {
									setComment(e.target.value);
								}}
								name="comment"
								cols="20"
								rows="4"
								required
							></textarea>
						</div>
						{state?.log === undefined && (
							<div className="d-flex justify-content-end">
								<Form.Check
									type="switch"
									checked={createTask}
									id="custom-switch"
									label="Follow up"
									onChange={(e) => {
										setCreateTask(e.target.checked);
									}}
								/>
							</div>
						)}
						{createTask && (
							<div>
								{/* <hr /> */}
								<input type="text" value="True" name="add_task" hidden />
								<input
									type="text"
									name="follow_up_date"
									value={moment(followUpDate).format("YYYY-MM-DD")}
									hidden
								/>
								<input type="text" name="task_type" value={"Todo"} hidden />
								<input
									type="text"
									name="task_description"
									value="Follow up for Note"
									hidden
								/>
								<button
									type="button"
									className="btn p-0  text-start w-100"
									onClick={() => {
										setGetNewFollowupDate(true);
									}}
								>
									<div className="row mx-3">
										<div className="col-9">
											<p
												className="text-muted p-0 m-0"
												style={{ fontSize: "x-small" }}
											>
												Follow up date :
											</p>
											<p className="fw-bold p-0 m-0">
												{moment(followUpDate).format("ddd, MMMM Do, YYYY")}
											</p>
										</div>
										<div className="col-3 d-flex align-items-center justify-content-end">
											<Icon.ArrowRight />
										</div>
									</div>
								</button>
								{/* <hr /> */}
							</div>
						)}
						<div className="text-end">
							<button className="btn btn-warning mt-3" type="submit">
								{state?.log?.id !== undefined ? "Edit" : "Add"} Note
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddNote;
