import React,{useEffect,useState} from 'react'
import Layout from './Layout/Layout'
import axios from 'axios'
import { useDispatch ,useSelector} from 'react-redux'
import { getWhatsappAuth } from '../../Actions/AdminAction'
import WhatsappView from '../../Components/WhatsappView'
const WhatsappTemplates = () => {
    const [templates,setTemplates]=useState([])
    const [paging,setPaging]=useState(1)
    const dispatch=useDispatch()
    const {extra} = useSelector(state=>state?.userState?.userInfo)
    const {whatsappAuth}=useSelector(state=>state?.developer?.whatsappAuth)
    useEffect(()=>{
        if(whatsappAuth?.wba_id !== undefined && whatsappAuth?.access_token !== undefined ){
        async function fetchData(){
                try{
                    const config={
                        headers:{
                            "Content-type":"application/json",
                            Authorization:`Bearer ${whatsappAuth?.access_token}`
                        }
                    }
                    const {data}=await axios.get(`https://graph.facebook.com/v20.0/${whatsappAuth?.wba_id}/message_templates`,config)
                    console.log(data)
                    setTemplates(data?.data)
                    setPaging(data?.paging)
                }catch(error){
                    console.log(error)
                }
            }
            fetchData()
        }
    },[whatsappAuth])
    useEffect(()=>{
        if(extra !== null && extra?.organizaition !== undefined ){
            dispatch(getWhatsappAuth(extra?.organizaition))
        }
    },[extra !== null])
  return (
    <Layout pageTitle={"Whatsapp Templates"	}  >
        {templates?.length > 0 && templates.map((template)=>{
            return <WhatsappView template={template} />
        })}
    </Layout>
  )
}

export default WhatsappTemplates