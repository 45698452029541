import axios from "./axios";
import {
	GET_SE_DASHBOARD_DATA_REQUEST,
	GET_SE_DASHBOARD_DATA_SUCCESS,
	GET_SE_DASHBOARD_DATA_FAIL,

	//
	VISITOR_ASSIGNED_STATUS_REQUEST,
	VISITOR_ASSIGNED_STATUS_SUCCESS,
	VISITOR_ASSIGNED_STATUS_FAIL,
	//
	GET_VISITOR_DETAIL_REQUEST,
	GET_VISITOR_DETAIL_SUCCESS,
	GET_VISITOR_DETAIL_FAIL,
	//
	UPDATE_VISITOR_DETAIL_REQUEST,
	UPDATE_VISITOR_DETAIL_SUCCESS,
	UPDATE_VISITOR_DETAIL_FAIL,
	//
	CHECKOUT_VISITOR_REQUEST,
	CHECKOUT_VISITOR_SUCCESS,
	CHECKOUT_VISITOR_FAIL,
	//
	GET_VISITORS_REQUEST,
	GET_VISITORS_SUCCESS,
	GET_VISITORS_FAIL,
	//
	GET_VISITORS_DETAIL_REQUEST,
	GET_VISITORS_DETAIL_SUCCESS,
	GET_VISITORS_DETAIL_FAIL,
	//
	GET_MY_TEAM_MEMBERS_REQUEST,
	GET_MY_TEAM_MEMBERS_SUCCESS,
	GET_MY_TEAM_MEMBERS_FAIL,
	//
	GET_CHANNEL_PARTNERS_REQUEST,
	GET_CHANNEL_PARTNERS_SUCCESS,
	GET_CHANNEL_PARTNERS_FAIL,
	//
	GET_DETAIL_CHANNEL_PARTNER_REQUEST,
	GET_DETAIL_CHANNEL_PARTNER_SUCCESS,
	GET_DETAIL_CHANNEL_PARTNER_FAIL,
	//
	GET_INVENTORY_REQUEST,
	GET_INVENTORY_SUCCESS,
	GET_INVENTORY_FAIL,
	//
	BOOK_UNIT_REQUEST,
	BOOK_UNIT_SUCCESS,
	BOOK_UNIT_FAIL,
	//
	GET_CHANNEL_PARTNER_REQUEST,
	GET_CHANNEL_PARTNER_SUCCESS,
	GET_CHANNEL_PARTNER_FAIL,
	//
	LINK_CHANNEL_PARTNER_REQUEST,
	LINK_CHANNEL_PARTNER_SUCCESS,
	LINK_CHANNEL_PARTNER_FAIL,
	// 
	SE_ADD_BOOKING_PRICING_LIST_REQUEST,
	SE_ADD_BOOKING_PRICING_LIST_SUCCESS,
	SE_ADD_BOOKING_PRICING_LIST_FAIL,
	// 
	GET_CP_REPORT_REQUEST,
	GET_CP_REPORT_SUCCESS,
	GET_CP_REPORT_FAIL,
	// 
} from "../Constants/SEConstatant";
import toast from "react-hot-toast";

export const getDashboardData = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_SE_DASHBOARD_DATA_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.get("se/dashborad/", config);
		dispatch({
			type: GET_SE_DASHBOARD_DATA_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_SE_DASHBOARD_DATA_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getVisitorStatus = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: VISITOR_ASSIGNED_STATUS_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.get("get/visitor-status/", config);

		// toast.success("Visitor Status Updated Successfully");

		dispatch({
			type: VISITOR_ASSIGNED_STATUS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: VISITOR_ASSIGNED_STATUS_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getVisitorDetail = (visit_id) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_VISITOR_DETAIL_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.get(`get/visitor-detail/${visit_id}/`, config);
		dispatch({
			type: GET_VISITOR_DETAIL_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_VISITOR_DETAIL_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const updateVisitorDetail = (postData) => async (dispatch, getState) => {
	try {
		dispatch({ type: UPDATE_VISITOR_DETAIL_REQUEST });
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.post(
			"update/visitor-detail/",
			postData,
			config
		);

		toast.success("Visitor Detail Updated Successfully");

		dispatch({
			type: UPDATE_VISITOR_DETAIL_SUCCESS,
			payload: data,
		});
		dispatch(getVisitorDetail(postData.visit_id));
	} catch (error) {
		dispatch({
			type: UPDATE_VISITOR_DETAIL_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const checkOutVisitor =
	(postData, navigate) => async (dispatch, getState) => {
		try {
			dispatch({
				type: CHECKOUT_VISITOR_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};
			const { data } = await axios.post("checkout/visitor/", postData, config);

			toast.success("Visitor Checked Out Successfully");

			dispatch(getVisitorStatus());
			dispatch({
				type: CHECKOUT_VISITOR_SUCCESS,
				payload: data,
			});
			navigate("/");
		} catch (error) {
			dispatch({
				type: CHECKOUT_VISITOR_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getVisitors =
	(page = 1) =>
		async (dispatch, getState) => {
			try {
				dispatch({
					type: GET_VISITORS_REQUEST,
				});
				const {
					userState: { userInfo },
				} = getState();

				const config = {
					headers: {
						"Content-type": "application/json",
						Authorization: `Bearer ${userInfo?.token}`,
					},
				};

				const { data } = await axios.get(`get/visitors/?page=${page}`, config);
				dispatch({
					type: GET_VISITORS_SUCCESS,
					payload: data,
				});
			} catch (error) {
				dispatch({
					type: GET_VISITORS_FAIL,
					payload:
						error.response && error.response.data.error
							? error.response.data.error
							: error.message,
				});
			}
		};

export const getVisitorsDetail = (visitorsId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_VISITORS_DETAIL_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.get(
			`get/visitors-detail/${visitorsId}/`,
			config
		);

		dispatch({
			type: GET_VISITORS_DETAIL_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_VISITORS_DETAIL_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getMyTeamMemberAction = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_MY_TEAM_MEMBERS_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.get("get/my-team/", config);
		dispatch({
			type: GET_MY_TEAM_MEMBERS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_MY_TEAM_MEMBERS_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getChannelPartners = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: GET_CHANNEL_PARTNERS_REQUEST,
		});
		const {
			userState: { userInfo },
		} = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};
		const { data } = await axios.get("get/channel-partners/", config);
		dispatch({
			type: GET_CHANNEL_PARTNERS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_CHANNEL_PARTNERS_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const getDetailChannelPartners =
	(channel_partner_id) => async (dispatch, getState) => {
		try {
			dispatch({
				type: GET_DETAIL_CHANNEL_PARTNER_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();
			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};
			const { data } = await axios.get(
				"get/channel-partners-detail/" + channel_partner_id + "/",
				config
			);
			dispatch({
				type: GET_DETAIL_CHANNEL_PARTNER_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_DETAIL_CHANNEL_PARTNER_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const getInventory =
	(params = {}) =>
		async (dispatch, getState) => {
			try {
				dispatch({
					type: GET_INVENTORY_REQUEST,
				});
				const {
					userState: { userInfo },
				} = getState();
				const config = {
					headers: {
						"Content-type": "appalication/json",
						Authorization: `Bearer ${userInfo?.token}`,
					},
					params: params,
				};
				const { data } = await axios.get("get/project-inventory/", config);
				dispatch({
					type: GET_INVENTORY_SUCCESS,
					payload: data,
				});
			} catch (error) {
				dispatch({
					type: GET_INVENTORY_FAIL,
					payload:
						error.response && error.response.data.error
							? error.response.data.error
							: error.message,
				});
			}
		};

export const getChannelPartner =
	(rera_number) => async (dispatch, getState) => {
		try {
			dispatch({
				type: GET_CHANNEL_PARTNER_REQUEST,
			});

			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};
			const { data } = await axios.get(
				`get-channel-partner/${rera_number}`,
				config
			);
			dispatch({
				type: GET_CHANNEL_PARTNER_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_CHANNEL_PARTNER_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};

export const bookUnit = (postData, navigate, projectId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: BOOK_UNIT_REQUEST,
		});

		const {
			userState: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-type": "multipart/form-data",
				Authorization: `Bearer ${userInfo?.token}`,
			},
		};

		const { data } = await axios.post("book-inventory/", postData, config);

		toast.success("Unit Booked Successfully");

		navigate(`/add-price-list/${projectId}/${data.booking_id}`);
		dispatch({
			type: BOOK_UNIT_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: BOOK_UNIT_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
};

export const link_channel_partner =
	(postData, setToFalse) => async (dispatch, getState) => {
		try {
			dispatch({
				type: LINK_CHANNEL_PARTNER_REQUEST,
			});
			const {
				userState: { userInfo },
			} = getState();

			const config = {
				headers: {
					"Content-type": "application/json",
					Authorization: `Bearer ${userInfo?.token}`,
				},
			};
			const { data } = await axios.post(
				"link/channel-partner/",
				postData,
				config
			);

			toast.success("Linked Successfully");

			dispatch(getChannelPartners());
			setToFalse(false);
			dispatch({
				type: LINK_CHANNEL_PARTNER_SUCCESS,
				payload: postData
			})
		} catch (error) {
			dispatch({
				type: LINK_CHANNEL_PARTNER_FAIL,
				payload:
					error.response && error.response.data.error
						? error.response.data.error
						: error.message,
			});
		}
	};



export const addBookingPriceList = (postData, navigate, bookingId) => async (dispatch, getState) => {
	try {
		dispatch({
			type: SE_ADD_BOOKING_PRICING_LIST_REQUEST
		})
		const { userState: { userInfo } } = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}

		const { data } = await axios.post(`booking/add-pricing-list/${bookingId}`, postData, config)
		navigate(`/`)
		toast.success("Added Successfully")
		dispatch({
			type: SE_ADD_BOOKING_PRICING_LIST_SUCCESS,
			payload: data
		})

	} catch (error) {
		dispatch({
			type: SE_ADD_BOOKING_PRICING_LIST_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
}


export const getCPReport = (postData) => async (dispatch, getState) => {
	try {

		dispatch({
			type: GET_CP_REPORT_REQUEST
		})
		const { userState: { userInfo } } = getState();
		const config = {
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${userInfo?.token}`
			}
		}
		const { data } = await axios.post(`get-cp-report/`, postData, config)
		dispatch({
			type: GET_CP_REPORT_SUCCESS,
			payload: data
		})

	} catch (error) {
		dispatch({
			type: GET_CP_REPORT_FAIL,
			payload:
				error.response && error.response.data.error
					? error.response.data.error
					: error.message,
		});
	}
}