import React, { useState } from 'react'
import Layout from '../../Layout/Layout'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { addProjectOffers } from '../../../../Actions/AdminAction'

const OfferItem = ({ offer, offerHandler }) => {
    return <div className='position-relative ' >
        <div className="p-3 d-grid gap-2 rounded border">
            <div>
                <label>Offer Name</label>
                <input type="text" value={offer.key} className='form-control' onChange={(e) => {
                    const data = { ...offer }
                    data["key"] = e.target.value
                    offerHandler(data)
                }} />
            </div>
            <div>
                <label>Offer Value</label>
                <input type="text" className='form-control' value={offer.value} onChange={(e) => {
                    const data = { ...offer }
                    data["value"] = e.target.value
                    offerHandler(data)
                }} />
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={offer.use_value} onChange={(e) => {
                    const data = { ...offer }
                    data["use_value"] = e.target.checked
                    offerHandler(data)
                }} />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                    Use Value
                </label>
            </div>
            <div className="d-flex gap-3">
                <label class="form-check-label" for="flexSwitchCheckDefault">Flat Value</label>
            <div className="form-check form-switch d-flex">
                <input className="form-check-input" checked={offer.in_percantage}  onChange={(e)=>{
                    const data={...offer}
                    data["in_percantage"]=e.target.checked
                    offerHandler(data)
                }}  type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                <label className="form-check-label" for="flexSwitchCheckDefault">In Percentage</label>
            </div>
            </div>

        </div>

    </div>
}


const AddOffersData = () => {
    const [offers, setOffers] = useState([])
    const {projectId}=useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitHandler=()=>{
        const postData={
            project_offers:offers,
        }
        dispatch(addProjectOffers(postData,projectId,navigate))
    }

    return (
        <Layout pageTitle={"Add Offers"}>
            <div className="d-grid gap-3 p-3 ">
                <div className="d-grid gap-3 p-3 position-relative">
                    {
                        offers.map(offer => {
                            return <OfferItem offer={offer} offerHandler={(e) => {
                                setOffers([...offers.filter(d => d.id !== offer.id), e].sort((a, b) => {
                                    return a.id - b.id
                                }))
                            }} />
                        })
                    }
                    <div className="sticky-bottom text-center p-3 bg-white">
                        <button className='btn btn-primary' onClick={() => {
                            setOffers([...offers, { id: offers.length > 0 ? offers[offers.length - 1].id + 1 : offers.length, key: "", value: "", use_value: false, in_percantage:false }])
                        }} >Add More</button>
                    </div>
                </div>
                <div className="col-12 sticky-bottom bg-white py-3 d-flex justify-content-end gap-3">
                    <button className='btn btn-success ' onClick={submitHandler} >Save</button>
                    <button className="btn btn-dark" type="button" onClick={()=>navigate(-1)}>Cancel</button>
                </div>
                
                
            </div>
        </Layout>
    )
}

export default AddOffersData
