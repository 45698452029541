import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDeveloperDetails } from "../../Actions/AdminAction";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout/Layout";
import moment from "moment";
const DeveloperView = () => {
	const dispatch = useDispatch();
    const navigate=useNavigate();
	const { loading, error, detail } = useSelector(
		(state) => state?.developer?.detail
	);
	const user = useSelector((state) => state?.userState?.userInfo);
	useEffect(() => {
		dispatch(getDeveloperDetails());
	}, []);
	return (
		<Layout pageTitle={"Developer View"}>
			<div className="d-grid p-3 gap-3">
				{!user.extra?.viewer_only &&<div className="position-relative">
                    <button className="btn btn-primary position-absolute top-0 end-0" onClick={()=>{
                        navigate("/edit/developer");
                    }} >Edit</button>
                </div>}
				<div className="">
					<div className="d-flex justify-content-center">
						<img
							style={{ height: "200px", aspectRatio: "1/1" }}
							src={"https://api.eazyapp.in" + detail?.logo}
							alt=""
						/>
					</div>
					<div className="text-center">
						<p className="m-0">
							Yr est. {moment(detail?.established_year).format("yyyy")}
						</p>
					</div>
					<h2 className="section_heading text-center">{detail?.name}</h2>
				</div>
				<div>
					<label className="text-muted card_heading">About</label>
					<p>{detail?.about}</p>
				</div>

				<div>
					<label className="text-muted card_heading">Contact</label>
					<p className="m-0">
						<span className="fw-bold">Email:</span>
						{detail?.contact?.contact_email}
					</p>
					<p className="m-0">
						<span className="fw-bold">Number:</span>{" "}
						{detail?.contact?.contact_number}
					</p>
				</div>
			</div>
		</Layout>
	);
};

export default DeveloperView;
