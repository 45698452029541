export const GET_DEVELOPER_PROJECTS_LIST_REQUEST =
	"GET_DEVELOPER_PROJECTS_LIST_REQUEST";
export const GET_DEVELOPER_PROJECTS_LIST_SUCCESS =
	"GET_DEVELOPER_PROJECTS_LIST_SUCCESS";
export const GET_DEVELOPER_PROJECTS_LIST_FAIL =
	"GET_DEVELOPER_PROJECTS_LIST_FAIL";

export const GET_PROJECT_DETAIL_REQUEST = "GET_PROJECT_DETAIL_REQUEST";
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS";
export const GET_PROJECT_DETAIL_FAIL = "GET_PROJECT_DETAIL_FAIL";


export const UPDATE_PROJECT_DETAIL_REQUEST = "UPDATE_PROJECT_DETAIL_REQUEST"
export const UPDATE_PROJECT_DETAIL_SUCCESS = "UPDATE_PROJECT_DETAIL_SUCCESS"
export const UPDATE_PROJECT_DETAIL_FAIL = "UPDATE_PROJECT_DETAIL_FAIL"

export const GET_CONFIG_DETAIL_REQUEST = "GET_CONFIG_DETAIL_REQUEST";
export const GET_CONFIG_DETAIL_SUCCESS = "GET_CONFIG_DETAIL_SUCCESS";
export const GET_CONFIG_DETAIL_FAIL = "GET_CONFIG_DETAIL_FAIL";

export const EDIT_CONFIG_DETAIL_REQUEST = "EDIT_CONFIG_DETAIL_REQUEST";
export const EDIT_CONFIG_DETAIL_SUCCESS = "EDIT_CONFIG_DETAIL_SUCCESS";
export const EDIT_CONFIG_DETAIL_FAIL = "EDIT_CONFIG_DETAIL_FAIL";

export const ADD_CONFIG_DETAIL_REQUEST = "ADD_CONFIG_DETAIL_REQUEST";
export const ADD_CONFIG_DETAIL_SUCCESS = "ADD_CONFIG_DETAIL_SUCCESS";
export const ADD_CONFIG_DETAIL_FAIL = "ADD_CONFIG_DETAIL_FAIL";

export const DELETE_CONFIG_REQUEST = "DELETE_CONFIG_REQUEST";
export const DELETE_CONFIG_SUCCESS = "DELETE_CONFIG_SUCCESS";
export const DELETE_CONFIG_FAIL = "DELETE_CONFIG_FAIL";

export const GET_GALLERY_DETAILS_REQUEST = "GET_GALLERY_DETAILS_REQUEST";
export const GET_GALLERY_DETAILS_SUCCESS = "GET_GALLERY_DETAILS_SUCCESS";
export const GET_GALLERY_DETAILS_FAIL = "GET_GALLERY_DETAILS_FAIL";

export const ADD_GALLERY_ITEM_REQUEST = "ADD_GALLERY_ITEM_REQUEST";
export const ADD_GALLERY_ITEM_SUCCESS = "ADD_GALLERY_ITEM_SUCCESS";
export const ADD_GALLERY_ITEM_FAIL = "ADD_GALLERY_ITEM_FAIL";

export const DELETE_GALLERY_ITEM_REQUEST = "DELETE_GALLERY_ITEM_REQUEST";
export const DELETE_GALLERY_ITEM_SUCCESS = "DELETE_GALLERY_ITEM_SUCCESS";
export const DELETE_GALLERY_ITEM_FAIL = "DELETE_GALLERY_ITEM_FAIL";

export const EDIT_GALLERY_DETAIL_REQUEST = "EDIT_GALLERY_DETAIL_REQUEST";
export const EDIT_GALLERY_DETAIL_SUCCESS = "EDIT_GALLERY_DETAIL_SUCCESS";
export const EDIT_GALLERY_DETAIL_FAIL = "EDIT_GALLERY_DETAIL_FAIL";

export const REMOVE_AMENITIE_REQUEST = "REMOVE_AMENITIE_REQUEST";
export const REMOVE_AMENITIE_SUCCESS = "REMOVE_AMENITIE_SUCCESS";
export const REMOVE_AMENITIE_FAIL = "REMOVE_AMENITIE_FAIL";

export const GET_DEVELOPER_AMENITIES_REQUEST =
	"GET_DEVELOPER_AMENITIES_REQUEST";
export const GET_DEVELOPER_AMENITIES_SUCCESS =
	"GET_DEVELOPER_AMENITIES_SUCCESS";
export const GET_DEVELOPER_AMENITIES_FAIL = "GET_DEVELOPER_AMENITIES_FAIL";

export const UPDATE_AMENITIE_LIST_REQUEST = "UPDATE_AMENITIE_LIST_REQUEST";
export const UPDATE_AMENITIE_LIST_SUCCESS = "UPDATE_AMENITIE_LIST_SUCCESS";
export const UPDATE_AMENITIE_LIST_FAIL = "UPDATE_AMENITIE_LIST_FAIL";

export const GET_DOCUMENT_DETAIL_REQUEST = "GET_DOCUMENT_DETAIL_REQUEST";
export const GET_DOCUMENT_DETAIL_SUCCESS = "GET_DOCUMENT_DETAIL_SUCCESS";
export const GET_DOCUMENT_DETAIL_FAIL = "GET_DOCUMENT_DETAIL_FAIL";

export const UPLOAD_DOCUMENT_REQUEST = "UPLOAD_DOCUMENT_REQUEST";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAIL = "UPLOAD_DOCUMENT_FAIL";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL";

export const UPDATE_DOCUMENT_DETAILS_REQUEST =
	"UPDATE_DOCUMENT_DETAILS_REQUEST";
export const UPDATE_DOCUMENT_DETAILS_SUCCESS =
	"UPDATE_DOCUMENT_DETAILS_SUCCESS";
export const UPDATE_DOCUMENT_DETAILS_FAIL = "UPDATE_DOCUMENT_DETAILS_FAIL";

export const DEVELOPER_EMPLOYEES_REQUEST = "DEVELOPER_EMPLOYEES_REQUEST";
export const DEVELOPER_EMPLOYEES_SUCCESS = "DEVELOPER_EMPLOYEES_SUCCESS";
export const DEVELOPER_EMPLOYEES_FAIL = "DEVELOPER_EMPLOYEES_FAIL";

export const ADD_NEW_EMPLOYEES_REQUEST = "ADD_NEW_EMPLOYEES_REQUEST";
export const ADD_NEW_EMPLOYEES_SUCCESS = "ADD_NEW_EMPLOYEES_SUCCESS";
export const ADD_NEW_EMPLOYEES_FAIL = "ADD_NEW_EMPLOYEES_FAIL";

export const DELETE_DEVELOPER_EMPLOYEE_REQUEST =
	"DELETE_DEVELOPER_EMPLOYEE_REQUEST";
export const DELETE_DEVELOPER_EMPLOYEE_SUCCESS =
	"DELETE_DEVELOPER_EMPLOYEE_SUCCESS";
export const DELETE_DEVELOPER_EMPLOYEE_FAIL = "DELETE_DEVELOPER_EMPLOYEE_FAIL";

export const GET_TEAM_MEMBERS_REQUEST = "GET_TEAM_MEMBERS_REQUEST";
export const GET_TEAM_MEMBERS_SUCCESS = "GET_TEAM_MEMBERS_SUCCESS";
export const GET_TEAM_MEMBERS_FAIL = "GET_TEAM_MEMBERS_FAIL";


export const ADD_NEW_TEAM_MEMBER_REQUEST = "ADD_NEW_TEAM_MEMBER_REQUEST"
export const ADD_NEW_TEAM_MEMBER_SUCCESS = "ADD_NEW_TEAM_MEMBER_SUCCESS"
export const ADD_NEW_TEAM_MEMBER_FAIL = "ADD_NEW_TEAM_MEMBER_FAIL"


export const REMOVE_TEAM_MEMBER_REQUEST = "REMOVE_TEAM_MEMBER_REQUEST"
export const REMOVE_TEAM_MEMBER_SUCCESS = "REMOVE_TEAM_MEMBER_SUCCESS"
export const REMOVE_TEAM_MEMBER_FAIL = "REMOVE_TEAM_MEMBER_FAIL"

export const GET_TEAM_MEMBER_DETAIL_REQUEST = "GET_TEAM_MEMBER_DETAIL_REQUEST"
export const GET_TEAM_MEMBER_DETAIL_SUCCESS = "GET_TEAM_MEMBER_DETAIL_SUCCESS"
export const GET_TEAM_MEMBER_DETAIL_FAIL = "GET_TEAM_MEMBER_DETAIL_FAIL"


export const EDIT_TEAM_MEMBER_DETAIL_REQUEST = "EDIT_TEAM_MEMBER_DETAIL_REQUEST"
export const EDIT_TEAM_MEMBER_DETAIL_SUCCESS = "EDIT_TEAM_MEMBER_DETAIL_SUCCESS"
export const EDIT_TEAM_MEMBER_DETAIL_FAIL = "EDIT_TEAM_MEMBER_DETAIL_FAIL"


export const DEVELOPER_DETAIL_REQUEST = "DEVELOPER_DETAIL_REQUEST"
export const DEVELOPER_DETAIL_SUCCESS = "DEVELOPER_DETAIL_SUCCESS"
export const DEVELOPER_DETAIL_FAIL = "DEVELOPER_DETAIL_FAIL"


export const EDIT_DEVELOPER_DETAIL_REQUEST = "EDIT_DEVELOPER_DETAIL_REQUEST"
export const EDIT_DEVELOPER_DETAIL_SUCCESS = "EDIT_DEVELOPER_DETAIL_SUCCESS"
export const EDIT_DEVELOPER_DETAIL_FAIL = "EDIT_DEVELOPER_DETAIL_FAIL"


export const GET_DEVELOPER_DASHBOARD_REQUEST = "GET_DEVELOPER_DASHBOARD_REQUEST"
export const GET_DEVELOPER_DASHBOARD_SUCCESS = "GET_DEVELOPER_DASHBOARD_SUCCESS"
export const GET_DEVELOPER_DASHBOARD_FAIL = "GET_DEVELOPER_DASHBOARD_FAIL"


export const GET_DEVELOEPR_CHANNEL_PARTNER_REQUEST = "GET_DEVELOEPR_CHANNEL_PARTNER_REQUEST"
export const GET_DEVELOEPR_CHANNEL_PARTNER_SUCCESS = "GET_DEVELOEPR_CHANNEL_PARTNER_SUCCESS"
export const GET_DEVELOEPR_CHANNEL_PARTNER_FAIL = "GET_DEVELOEPR_CHANNEL_PARTNER_FAIL"


export const DEVELOPER_CHANNEL_PARTNER_DETAIL_REQUEST = "DEVELOPER_CHANNEL_PARTNER_DETAIL_REQUEST"
export const DEVELOPER_CHANNEL_PARTNER_DETAIL_SUCCESS = "DEVELOPER_CHANNEL_PARTNER_DETAIL_SUCCESS"
export const DEVELOPER_CHANNEL_PARTNER_DETAIL_FAIL = "DEVELOPER_CHANNEL_PARTNER_DETAIL_FAIL"


export const SAVE_INVENTORY_DATA_REQUEST = "SAVE_INVENTORY_DATA_REQUEST"
export const SAVE_INVENTORY_DATA_SUCCESS = "SAVE_INVENTORY_DATA_SUCCESS"
export const SAVE_INVENTORY_DATA_FAIL = "SAVE_INVENTORY_DATA_FAIL"


export const EDIT_INVENTORY_DATA_REQUEST = "EDIT_INVENTORY_DATA_REQUEST"
export const EDIT_INVENTORY_DATA_SUCCESS = "EDIT_INVENTORY_DATA_SUCCESS"
export const EDIT_INVENTORY_DATA_FAIL = "EDIT_INVENTORY_DATA_FAIL"


export const GET_PROJECT_PAYMENT_SCHEDULE_REQUEST = "GET_PROJECT_PAYMENT_SCHEDULE_REQUEST"
export const GET_PROJECT_PAYMENT_SCHEDULE_SUCCESS = "GET_PROJECT_PAYMENT_SCHEDULE_SUCCESS"
export const GET_PROJECT_PAYMENT_SCHEDULE_FAIL = "GET_PROJECT_PAYMENT_SCHEDULE_FAIL"


export const ADD_PROJECT_PAYMENT_PLAN_REQUEST = "ADD_PROJECT_PAYMENT_PLAN_REQUEST"
export const ADD_PROJECT_PAYMENT_PLAN_SUCCESS = "ADD_PROJECT_PAYMENT_PLAN_SUCCESS"
export const ADD_PROJECT_PAYMENT_PLAN_FAIL = "ADD_PROJECT_PAYMENT_PLAN_FAIL"


export const GET_PAYMENT_SLAB_DATA_REQUEST = "GET_PAYMENT_SLAB_DATA_REQUEST"
export const GET_PAYMENT_SLAB_DATA_SUCCESS = "GET_PAYMENT_SLAB_DATA_SUCCESS"
export const GET_PAYMENT_SLAB_DATA_FAIL = "GET_PAYMENT_SLAB_DATA_FAIL"


export const UPDATE_PAYMENT_SLAB_DATA_REQUEST = "UPDATE_PAYMENT_SLAB_DATA_REQUEST"
export const UPDATE_PAYMENT_SLAB_DATA_SUCCESS = "UPDATE_PAYMENT_SLAB_DATA_SUCCESS"
export const UPDATE_PAYMENT_SLAB_DATA_FAIL = "UPDATE_PAYMENT_SLAB_DATA_FAIL"


export const GET_PAYMENT_SCHEDULE_REQUEST = "GET_PAYMENT_SCHEDULE_REQUEST"
export const GET_PAYMENT_SCHEDULE_SUCCESS = "GET_PAYMENT_SCHEDULE_SUCCESS"
export const GET_PAYMENT_SCHEDULE_FAIL = "GET_PAYMENT_SCHEDULE_FAIL"
export const GET_PAYMENT_SCHEDULE_RESET = "GET_PAYMENT_SCHEDULE_RESET"

export const COMPLETE_PAYMENT_SLAB_REQUEST = "COMPLETE_PAYMENT_SLAB_REQUEST"
export const COMPLETE_PAYMENT_SLAB_SUCCESS = "COMPLETE_PAYMENT_SLAB_SUCCESS"
export const COMPLETE_PAYMENT_SLAB_FAIL = "COMPLETE_PAYMENT_SLAB_FAIL"


export const DELETE_PAYMENT_SLAB_REQUEST = 'DELETE_PAYMENT_SLAB_REQUEST'
export const DELETE_PAYMENT_SLAB_SUCCESS = 'DELETE_PAYMENT_SLAB_SUCCESS'
export const DELETE_PAYMENT_SLAB_FAIL = 'DELETE_PAYMENT_SLAB_FAIL'

export const DELETE_PAYMENT_SCHEDULE_REQUEST = 'DELETE_PAYMENT_SCHEDULE_REQUEST'
export const DELETE_PAYMENT_SCHEDULE_SUCCESS = 'DELETE_PAYMENT_SCHEDULE_SUCCESS'
export const DELETE_PAYMENT_SCHEDULE_FAIL = 'DELETE_PAYMENT_SCHEDULE_FAIL'

export const EDIT_PAYMENT_SCHEDULE_REQUEST = "EDIT_PAYMENT_SCHEDULE_REQUEST"
export const EDIT_PAYMENT_SCHEDULE_SUCCESS = "EDIT_PAYMENT_SCHEDULE_SUCCESS"
export const EDIT_PAYMENT_SCHEDULE_FAIL = "EDIT_PAYMENT_SCHEDULE_FAIL"


export const GET_PROJECT_PRICING_LIST_REQUEST = 'GET_PROJECT_PRICING_LIST_REQUEST'
export const GET_PROJECT_PRICING_LIST_SUCCESS = 'GET_PROJECT_PRICING_LIST_SUCCESS'
export const GET_PROJECT_PRICING_LIST_FAIL = 'GET_PROJECT_PRICING_LIST_FAIL'


export const ADD_PROJECT_PRICING_LIST_REQUEST = 'ADD_PROJECT_PRICING_LIST_REQUEST'
export const ADD_PROJECT_PRICING_LIST_SUCCESS = 'ADD_PROJECT_PRICING_LIST_SUCCESS'
export const ADD_PROJECT_PRICING_LIST_FAIL = 'ADD_PROJECT_PRICING_LIST_FAIL'


export const GET_PRICING_DETAILS_REQUEST = 'GET_PRICING_DETAILS_REQUEST'
export const GET_PRICING_DETAILS_SUCCESS = 'GET_PRICING_DETAILS_SUCCESS'
export const GET_PRICING_DETAILS_FAIL = 'GET_PRICING_DETAILS_FAIL'


export const UPDATE_PRICING_DETAIL_REQUEST = 'UPDATE_PRICING_DETAIL_REQUEST'
export const UPDATE_PRICING_DETAIL_SUCCESS = 'UPDATE_PRICING_DETAIL_SUCCESS'
export const UPDATE_PRICING_DETAIL_FAIL = 'UPDATE_PRICING_DETAIL_FAIL'


export const DELETE_PRICING_DETAIL_REQUEST = 'DELETE_PRICING_DETAIL_REQUEST'
export const DELETE_PRICING_DETAIL_SUCCESS = 'DELETE_PRICING_DETAIL_SUCCESS'
export const DELETE_PRICING_DETAIL_FAIL = 'DELETE_PRICING_DETAIL_FAIL'


export const GET_PROJECT_OFFER_LIST_REQUEST = 'GET_PROJECT_OFFER_LIST_REQUEST'
export const GET_PROJECT_OFFER_LIST_SUCCESS = 'GET_PROJECT_OFFER_LIST_SUCCESS'
export const GET_PROJECT_OFFER_LIST_FAIL = 'GET_PROJECT_OFFER_LIST_FAIL'


export const ADD_PROJECT_OFFERS_REQUEST = 'ADD_PROJECT_OFFERS_REQUEST'
export const ADD_PROJECT_OFFERS_SUCCESS = 'ADD_PROJECT_OFFERS_SUCCESS'
export const ADD_PROJECT_OFFERS_FAIL = 'ADD_PROJECT_OFFERS_FAIL'

export const GET_OFFER_DETAIL_REQUEST = 'GET_OFFER_DETAIL_REQUEST'
export const GET_OFFER_DETAIL_SUCCESS = 'GET_OFFER_DETAIL_SUCCESS'
export const GET_OFFER_DETAIL_FAIL = 'GET_OFFER_DETAIL_FAIL'

export const UPDATE_OFFER_DETAIL_REQUEST = 'UPDATE_OFFER_DETAIL_REQUEST'
export const UPDATE_OFFER_DETAIL_SUCCESS = 'UPDATE_OFFER_DETAIL_SUCCESS'
export const UPDATE_OFFER_DETAIL_FAIL = 'UPDATE_OFFER_DETAIL_FAIL'


export const DELETE_OFFER_DETAIL_REQUEST = 'DELETE_OFFER_DETAIL_REQUEST'
export const DELETE_OFFER_DETAIL_SUCCESS = 'DELETE_OFFER_DETAIL_SUCCESS'
export const DELETE_OFFER_DETAIL_FAIL = 'DELETE_OFFER_DETAIL_FAIL'

export const GET_INVENTORY_DATA_REQUEST = "GET_INVENTORY_DATA_REQUEST"
export const GET_INVENTORY_DATA_SUCCESS = "GET_INVENTORY_DATA_SUCCESS"
export const GET_INVENTORY_DATA_FAIL = "GET_INVENTORY_DATA_FAIL"


export const DELETE_INVENTORY_REQUEST = "DELETE_INVENTORY_REQUEST"
export const DELETE_INVENTORY_SUCCESS = "DELETE_INVENTORY_SUCCESS"
export const DELETE_INVENTORY_FAIL = "DELETE_INVENTORY_FAIL"


export const EDIT_DEVELOPER_SETTING_REQUEST = "EDIT_DEVELOPER_SETTING_REQUEST"
export const EDIT_DEVELOPER_SETTING_SUCCESS = "EDIT_DEVELOPER_SETTING_SUCCESS"
export const EDIT_DEVELOPER_SETTING_FAIL = "EDIT_DEVELOPER_SETTING_FAIL"


export const GET_DEVELOPER_SETTING_REQUEST = "GET_DEVELOPER_SETTING_REQUEST"
export const GET_DEVELOPER_SETTING_SUCCESS = "GET_DEVELOPER_SETTING_SUCCESS"
export const GET_DEVELOPER_SETTING_FAIL = "GET_DEVELOPER_SETTING_FAIL"


export const DELETE_DEVELOPER_SETTING_REQUEST = "DELETE_DEVELOPER_SETTING_REQUEST"
export const DELETE_DEVELOPER_SETTING_SUCCESS = "DELETE_DEVELOPER_SETTING_SUCCESS"
export const DELETE_DEVELOPER_SETTING_FAIL = "DELETE_DEVELOPER_SETTING_FAIL"

export const GET_EMPLOYEE_DETAIL_REQUEST = "GET_EMPLOYEE_DETAIL_REQUEST"
export const GET_EMPLOYEE_DETAIL_SUCCESS = "GET_EMPLOYEE_DETAIL_SUCCESS"
export const GET_EMPLOYEE_DETAIL_FAIL = "GET_EMPLOYEE_DETAIL_FAIL"


export const EDIT_EMPLOYEE_DETAIL_REQUEST = "EDIT_EMPLOYEE_DETAIL_REQUEST"
export const EDIT_EMPLOYEE_DETAIL_SUCCESS = "EDIT_EMPLOYEE_DETAIL_SUCCESS"
export const EDIT_EMPLOYEE_DETAIL_FAIL = "EDIT_EMPLOYEE_DETAIL_FAIL"


export const GET_CUSTOMER_BOOKING_LIST_REQUEST = "GET_CUSTOMER_BOOKING_LIST_REQUEST"
export const GET_CUSTOMER_BOOKING_LIST_SUCCESS = "GET_CUSTOMER_BOOKING_LIST_SUCCESS"
export const GET_CUSTOMER_BOOKING_LIST_FAIL = "GET_CUSTOMER_BOOKING_LIST_FAIL"


export const GET_CUSTOMER_BOOKING_DETAIL_REQUEST = "GET_CUSTOMER_BOOKING_DETAIL_REQUEST"
export const GET_CUSTOMER_BOOKING_DETAIL_SUCCESS = "GET_CUSTOMER_BOOKING_DETAIL_SUCCESS"
export const GET_CUSTOMER_BOOKING_DETAIL_FAIL = "GET_CUSTOMER_BOOKING_DETAIL_FAIL"


export const GET_PROJECT_CONFIGS_REQUEST = "GET_PROJECT_CONFIGS_REQUEST"
export const GET_PROJECT_CONFIGS_SUCCESS = "GET_PROJECT_CONFIGS_SUCCESS"
export const GET_PROJECT_CONFIGS_FAIL = "GET_PROJECT_CONFIGS_FAIL"


export const BOOK_UNIT_REQUEST = "BOOK_UNIT_REQUEST"
export const BOOK_UNIT_SUCCESS = "BOOK_UNIT_SUCCESS"
export const BOOK_UNIT_FAIL = "BOOK_UNIT_FAIL"


export const ADD_BOOKING_PRICING_LIST_REQUEST = "ADD_BOOKING_PRICING_LIST_REQUEST"
export const ADD_BOOKING_PRICING_LIST_SUCCESS = "ADD_BOOKING_PRICING_LIST_SUCCESS"
export const ADD_BOOKING_PRICING_LIST_FAIL = "ADD_BOOKING_PRICING_LIST_FAIL"


export const EDIT_PROJECT_DOMAIN_SETTING_REQUEST = "EDIT_PROJECT_DOMAIN_SETTING_REQUEST"
export const EDIT_PROJECT_DOMAIN_SETTING_SUCCESS = "EDIT_PROJECT_DOMAIN_SETTING_SUCCESS"
export const EDIT_PROJECT_DOMAIN_SETTING_FAIL = "EDIT_PROJECT_DOMAIN_SETTING_FAIL"


export const GET_PROJECT_AUTOMATION_LIST_REQUEST = "GET_PROJECT_AUTOMATION_LIST_REQUEST"
export const GET_PROJECT_AUTOMATION_LIST_SUCCESS = "GET_PROJECT_AUTOMATION_LIST_SUCCESS"
export const GET_PROJECT_AUTOMATION_LIST_FAIL = "GET_PROJECT_AUTOMATION_LIST_FAIL"


export const SAVE_PROJECT_AUTOMATION_REQUEST = "SAVE_PROJECT_AUTOMATION_REQUEST"
export const SAVE_PROJECT_AUTOMATION_SUCCESS = "SAVE_PROJECT_AUTOMATION_SUCCESS"
export const SAVE_PROJECT_AUTOMATION_FAIL = "SAVE_PROJECT_AUTOMATION_FAIL"


export const DELETE_PROJECT_AUTOMATION_REQUEST = "DELETE_PROJECT_AUTOMATION_REQUEST"
export const DELETE_PROJECT_AUTOMATION_SUCCESS = "DELETE_PROJECT_AUTOMATION_SUCCESS"
export const DELETE_PROJECT_AUTOMATION_FAIL = "DELETE_PROJECT_AUTOMATION_FAIL"


export const GET_WHATSAPP_TEMPLATES_REQUEST = "GET_WHATSAPP_TEMPLATES_REQUEST"
export const GET_WHATSAPP_TEMPLATES_SUCCESS = "GET_WHATSAPP_TEMPLATES_SUCCESS"
export const GET_WHATSAPP_TEMPLATES_FAIL = "GET_WHATSAPP_TEMPLATES_FAIL"



export const ADD_AUTOMATION_REQUEST = "ADD_AUTOMATION_REQUEST"
export const ADD_AUTOMATION_SUCCESS = "ADD_AUTOMATION_SUCCESS"
export const ADD_AUTOMATION_FAIL = "ADD_AUTOMATION_FAIL"


export const GET_WHATSAPP_AUTH_REQUEST = "GET_WHATSAPP_AUTH_REQUEST"
export const GET_WHATSAPP_AUTH_SUCCESS = "GET_WHATSAPP_AUTH_SUCCESS"
export const GET_WHATSAPP_AUTH_FAIL = "GET_WHATSAPP_AUTH_FAIL"



export const GET_VISITORS_REQUEST = "GET_VISITORS_REQUEST"
export const GET_VISITORS_SUCCESS = "GET_VISITORS_SUCCESS"
export const GET_VISITORS_FAIL = "GET_VISITORS_FAIL"