import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout/Layout";
import { getPaymentScheduleList, deletePaymentSchedule, deletePaymentSlab, completePaymentSlab } from "../../../Actions/AdminAction";
import LoadingPage from "../../LoadingPage";
import Accordion from "react-bootstrap/Accordion";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmDelete from "../Components/ConfirmDelete";
import { Modal } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
const CheckSlabComplete=({slab,projectId})=>{
	const [show,setShow]=useState(false)
	const {userInfo}=useSelector(state=>state?.userState)
	const [documentId,setDocumentId]=useState(null)
	const dispatch=useDispatch();
	const CompleteHandler=()=>{
		toast.success("Slab Marked Completed")
		dispatch(getPaymentScheduleList(projectId))
		setShow(false)
	}
	return <>
		<button
			className="btn btn-primary rounded-circle"
			style={{ height: "64px", width: "64px" }}
			onClick={() => {
				setShow(true)
			}}
		>
			<FontAwesomeIcon icon={icon.faCheck} />
		</button>
		<Modal show={show} onHide={() => setShow(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Confirm Check Complete</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Are you sure you want To Check Complete?</p>
				<input type="file" className="form-control" onChange={(e)=>async()=>{
					const originalFile = e.target.files[0];

					// Create a new File with the same content but a different name
					const newFileName = `${slab.key.replaceAll(" ","_")}.${originalFile.name.split('.').pop()}`; // Change this logic as per your needs
					const renamedFile = new File([originalFile], newFileName, { type: originalFile.type });
					const formData= new FormData()
					formData.append("file_type","Construction Progress")
					formData.append("available_to", "Customer , Visitors , Channel Partner , Employees")
					formData.append("file", renamedFile)
					const config={
						header :{
							"Content-type":"multipart/form-data",
							Authorization:`Bearer ${userInfo.token}`
						}
					}
					const { data } = await axios.post("https://api.eazyapp.in/api/add/document/"+projectId,formData,config)
					setDocumentId(data.document_id)
				}}   />				
			</Modal.Body>
			<Modal.Footer>
				<button
					className="btn btn-danger"
					onClick={() => {
						if (documentId == null ){
							toast.error("Please Add a Document Related to Project Completion")
							return 
						}
						dispatch(completePaymentSlab({ "document_id": documentId }, slab.id, CompleteHandler))
					}}
				>
					Complete Check
				</button>
				<button
					className="btn btn-secondary"
					onClick={() => setShow(false)}
				>
					Cancel
				</button>
			</Modal.Footer>
		</Modal>
	</>
}


const PaymentSetting = () => {
	const { projectId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { loading, error, paymentScheduleList } = useSelector(
		(state) => state?.developer?.project?.paymentScheduleList
	);
	const user = useSelector((state) => state?.userState?.userInfo);
	useEffect(() => {
		dispatch(getPaymentScheduleList(projectId));
	}, []);

	if (loading) {
		return <LoadingPage />;
	}

	return (
		<Layout pageTitle={"Payment Setting"}>
			<div className="d-grid gap-2 p-3">
				{!user.extra?.viewer_only &&	<div className="text-end">
					<button
						className="btn btn-primary"
						onClick={() => {
							navigate(`/add-payment-schedule/${projectId}`);
						}}
					>
						Add New Payment Schedule
					</button>
				</div>}
				<div className="">
					<Accordion defaultActiveKey={0}>
						{paymentScheduleList?.map((item, index) => {
							return (
								<Accordion.Item eventKey={index}>
									<Accordion.Header>
										{item.name} {item.payment_type}
									</Accordion.Header>
									<Accordion.Body>
										<ul class="list-group">
											<li class="list-group-item">
												<div className="d-flex gap-2">
													<div
														style={{
															width: `${
																item.payment_type == "Date Driven"
																	? 100 / 4
																	: 100 / 5
															}%`,
														}}
													>
														Label
													</div>
													<div
														style={{
															width: `${
																item.payment_type == "Date Driven"
																	? 100 / 4
																	: 100 / 5
															}%`,
														}}
													>
														Value
													</div>
													{item.payment_type == "Date Driven" ? (
														<div
															style={{
																width: `${
																	item.payment_type == "Date Driven"
																		? 100 / 4
																		: 100 / 5
																}%`,
															}}
														>
															Date
														</div>
													) : (
														<>
															<div
																style={{
																	width: `${
																		item.payment_type == "Date Driven"
																			? 100 / 4
																			: 100 / 5
																	}%`,
																}}
															>
																Completed
															</div>
															<div
																style={{
																	width: `${
																		item.payment_type == "Date Driven"
																			? 100 / 4
																			: 100 / 5
																	}%`,
																}}
															>
																File
															</div>
														</>
													)}

													<div
														style={{
															width: `${
																item.payment_type == "Date Driven"
																	? 100 / 4
																	: 100 / 5
															}%`,
														}}
													>
														Action
													</div>
												</div>
											</li>
											{item.payment_slabs.map((slab) => {
												return (
													<li class="list-group-item">
														<div className="d-flex gap-2">
															<div
																style={{
																	width: `${
																		item.payment_type == "Date Driven"
																			? 100 / 4
																			: 100 / 5
																	}%`,
																}}
															>
																{slab.key}
															</div>
															<div
																style={{
																	width: `${
																		item.payment_type == "Date Driven"
																			? 100 / 4
																			: 100 / 5
																	}%`,
																}}
															>
																{slab.value}
															</div>
															{item.payment_type == "Date Driven" ? (
																<div
																	style={{
																		width: `${
																			item.payment_type == "Date Driven"
																				? 100 / 4
																				: 100 / 5
																		}%`,
																	}}
																>
																	{slab.date_to_ask}
																</div>
															) : (
																<>
																	<div
																		style={{
																			width: `${
																				item.payment_type == "Date Driven"
																					? 100 / 4
																					: 100 / 5
																			}%`,
																		}}
																	>
																		{slab.is_completed ? "Yes" : "No"}
																	</div>
																	<div
																		style={{
																			width: `${
																				item.payment_type == "Date Driven"
																					? 100 / 4
																					: 100 / 5
																			}%`,
																		}}
																	>
																		{slab.file}
																	</div>
																</>
															)}
															<div
																className="gap-2 d-flex align-items-center "
																style={{
																	width: `${
																		item.payment_type == "Date Driven"
																			? 100 / 4
																			: 100 / 5
																	}%`,
																}}
															>
																{item.can_delete_or_update && !user.extra?.viewer_only && (
																	<>
																
																		<CheckSlabComplete   />

																		
																			<button
																			className="btn btn-success rounded-circle"
																			style={{ height: "64px", width: "64px" }}
																			onClick={() => {
																				navigate(
																					`/edit-payment-slab/${slab.id}`
																				);
																			}}
																		>
																			<FontAwesomeIcon icon={icon.faEdit} />
																		</button>
																		<button className="btn btn-danger rounded-circle" 
																			style={{ height: "64px", width: "64px" }}
																			onClick={()=>{
																				dispatch(deletePaymentSlab(slab.id))
																			}}
																		>
																			<FontAwesomeIcon icon={icon.faTrash} />
																		</button>
																	</>
																)}
															</div>
														</div>
													</li>
												);
											})}
										</ul>
										{item.can_delete_or_update && !user.extra?.viewer_only && (
											<div className="d-flex justify-content-end gap-3 p-3"
											>
												<button
													className="btn btn-success"
													onClick={() =>
														navigate(`/edit-payment-schedule/${projectId}/${item.id}`)
													}
												>
													Edit 
												</button>
												<button
													className="btn btn-danger"
													

													// onClick={()=>{
													// 	dispatch(deletePaymentSchedule(item.id,projectId));
													// }}
												>
													<ConfirmDelete id={item.id} projectId={projectId} callback={deletePaymentSchedule}  label="Delete" />
												</button>
											</div>
										)}
									</Accordion.Body>
								</Accordion.Item>
							);
						})}
					</Accordion>
				</div>
			</div>
		</Layout>
	);
};

export default PaymentSetting;
