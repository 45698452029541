import React,{useState,useEffect} from 'react'
import Layout from '../Layout/Layout'
import ImageUploader from '../../../Components/ImageUploader'
import DatePicker from "react-datepicker";
import { useDispatch , useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDeveloperDetails,editDeveloperDetail } from '../../../Actions/AdminAction';
const EditDeveloper = () => {
	const [name,setName]=useState("")
    const [year, setYear] = useState(new Date());
	const [about,setAbout]=useState("");
	const [contact,setContact]=useState({contact_email:"",contact_number:""})
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading, error, detail } = useSelector(
		(state) => state?.developer?.detail
	);
	useEffect(()=>{
		if(detail !== undefined){
			setName(detail?.name)
			setAbout(detail?.about)
			setContact(detail?.contact)
			setYear(new Date(detail?.established_year));
		}
	},[detail])
	useEffect(() => {
		dispatch(getDeveloperDetails());
	}, []);
	const submitHandler=(event)=>{
		event.preventDefault()
		const formData=new FormData(event.target)
		dispatch(editDeveloperDetail(formData,navigate));
	}
  return (
		<Layout pageTitle={"Edit Developer"}>
			<form onSubmit={submitHandler}>
				<div className="d-grid gap-3 p-3 position-relative">
					<div className="row">
						<div className="col-2">
							<ImageUploader
								dbImage={"https://api.eazyapp.in" + detail?.logo}
								name={"file"}
							/>
						</div>
						<div className="col-10">
							<div>
								<label>Name</label>
								<input
									type="text"
									className="form-control"
									placeholder="Enter Project Name"
									name="name"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div>
								<label>Established Year</label>
								<div>
									<DatePicker
										selected={year}
										onChange={setYear}
										className="form-control"
										name="established_year"
										showYearOnly={true}
										showYearPicker
										dateFormat="yyyy"
									/>
								</div>
							</div>
						</div>
					</div>
					<div>
						<label className="card_heading">About</label>
						<textarea
							className="form-control"
							cols="30"
							rows="10"
							name="about"
							value={about}
							onChange={(e) => setAbout(e.target.value)}
						></textarea>
					</div>
					<div>
						<label className="card_heading">Contact Details</label>
						<div className="p-3">
							<div>
								<label>Contact Email</label>
								<input
									type="email"
									className="form-control"
									value={contact.contact_email}
									name="contact-contact_email"
									onChange={(e) =>
										setContact({
											contact_email: e.target.value,
											contact_number: contact.contact_number,
										})
									}
								/>
							</div>
							<div>
								<label>Contact Number</label>
								<input
									type="text"
									className="form-control"
									value={contact.contact_email}
									name="contact-contact_number"
									onChange={(e) =>
										setContact({
											contact_number: e.target.value,
											contact_email: contact.contact_email,
										})
									}
								/>
							</div>
						</div>
					</div>
					<div className="col-12 sticky-bottom bg-white py-3 d-flex justify-content-end gap-3">
						<button className="btn btn-success" type="submit">
							Save
						</button>
						<button className="btn btn-dark">Cancel</button>
						

					</div>
				</div>
			</form>
		</Layout>
	);
}

export default EditDeveloper
