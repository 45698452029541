import React, { useEffect, useState } from 'react'
import { LIST_OF_AVAILABLE_LANGUAGES } from "../../Constants/CommonConstants";
const EnterTemplateName = ({whatsappDataHandler}) => {
    const [data, setData] = useState({
        language:"en",
        category:"AUTHENTICATION",
    })
    useEffect(() => {
        whatsappDataHandler(data)
    },[data])
    return (
        <>
        <div className='d-flex' >
            <div className='flex-fill' >
                <label htmlFor="template_name">Template Name</label>
                <input type="text" name="template_name" id='template_name' onChange={(e) => {
                    e.target.value = e.target.value.replaceAll(" ", "_").toLowerCase()
                    setData({ ...data, name: e.target.value })
                }} className="form-control" />
            </div>
            <div>
                    <label htmlFor="language">Template Language</label>
                <select name="language" id="language" className='form-select' value={data.language}  onChange={(e) => setData({ ...data, language: e.target.value })} >
                        {LIST_OF_AVAILABLE_LANGUAGES.map(obj => {
                            return <option key={obj.key} value={obj.value}>{obj.key}</option>
                        })}
                </select>
            </div>
        </div>
            <div>
                <select className='form-select' name="category" id="category" value={data.category} onChange={(e) => setData({ ...data, category: e.target.value })} >
                    {["AUTHENTICATION",
                        "MARKETING",
                        "UTILITY"].map(obj => {
                            return <option key={obj} value={obj}>{obj}</option>
                        })}
                </select>
            </div>
        </>
    )
}

export default EnterTemplateName
