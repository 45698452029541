import React,{useEffect} from 'react'
import Layout from "./Layout/Layout"
import { useDispatch , useSelector } from 'react-redux'
import { getDashBoardData } from '../../Actions/MyAdminAction'
import LoadingPage from '../LoadingPage'
const MyAdminDashboard = () => {
  const dispatch = useDispatch();
  const { dashboardData , loading, error} = useSelector(state => state.myAdmin?.dashboardData)
  useEffect(() => {
    dispatch(getDashBoardData())
  },[])  


  if(loading){
    return <LoadingPage />
  }

  return (
    <Layout pageTitle={"Dashboard"}>
      <div className="d-flex gap-3 p-3">
        <div className="w-30">
          <div className="card">
            <div className="card-body">
              <h5 className="card_heading">Total Projects</h5>
              <p className="card-text display-3 ">{dashboardData?.projects}</p>
            </div>
          </div>
        </div>
        <div className="w-30">
          <div className="card">
            <div className="card-body">
              <h5 className="card_heading">Total Developers</h5>
              <p className="card-text display-3 ">{dashboardData?.developers}</p>
            </div>
          </div>
        </div>
        <div className="w-30">
          <div className="card">
            <div className="card-body">
              <h5 className="card_heading">Total Channel Partners</h5>
              <p className="card-text display-3 ">{dashboardData?.channel_partners}</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MyAdminDashboard
