import React from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { Navbar, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {userLogout} from "../../../Actions/UserAction";
import { UPDATE_CURRENT_BOOKING_ID } from "../../../Constants/CustomerConstants";
import { getMyBookings } from "../../../Actions/CustomerAction";

const Header = ({pageTitle}) => {
	const navigate = useNavigate();
    const dispatch = useDispatch(); 
    const location = useLocation();
	React.useEffect(() => {
		dispatch(getMyBookings())
	},[])
	const showCheckInbutton=()=>{
		if(location.pathname == "/" || location.pathname == "/team" || location.pathname == "/visit"){
			return true;
		}
		return false;
	}
	const user = useSelector((state) => state?.userState?.userInfo);
	const { myBookings } = useSelector((state) => state?.customer?.myBookings);
	const currentBookingId = useSelector((state) => state?.customer?.currentBookingId);
	return (
		<div>
			<Navbar
				collapseOnSelect
				expand="lg"
				className="bg-accent"
				// bg={location.pathname == "/" ? "primary" : "accent"}
				variant="light"
				sticky="top"
			>
				<Navbar.Brand
					className="ms-3 text-light"
				>
					{pageTitle}
				</Navbar.Brand>

				{/* <Dropdown className="ms-auto me-2">
					<Dropdown.Toggle variant="white" id="dropdown-basic">
						{user?.name}
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => {
								dispatch(userLogout());
							}}
						>
							<Icon.BoxArrowLeft />
							Logout
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown> */}
				<select className='bg-white p-2 rounded ms-auto me-2' defaultValue={currentBookingId} onChange={(e) => dispatch({
					type: UPDATE_CURRENT_BOOKING_ID,
					payload: e.target.value
				}) } >
					{myBookings?.map((item) => {
						return <option key={item.id} value={item.id}>{item.project}- {item.wing} {item.unit}</option>
					})}
				</select>
			</Navbar>
		</div>
	);
};

export default Header;
