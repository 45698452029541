import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
	getDeveloperAmenities,
	updateAmenitieList,
} from "../../../Actions/AdminAction";
const Amenities = () => {
	const [amenitieObject, setAmenities] = useState({
		amenities: [],
		in_project: [],
	});
	const { projectId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading, error, amenities } = useSelector(
		(state) => state?.developer?.project?.amenitie
	);

	const amenitiesEditHandler = () => {
		const amenitiesId = {
			add_amenities: amenitieObject?.in_project.filter(
				(element) => !amenities.in_project.includes(element)
			),
			remove_amenities: amenities?.in_project.filter(
				(element) => !amenitieObject.in_project.includes(element)
			),
		};
        dispatch(updateAmenitieList(amenitiesId, projectId));
	};

	useEffect(() => {
		if (amenities !== undefined) {
			setAmenities(amenities);
		}
	}, [amenities]);

	useEffect(() => {
		dispatch(getDeveloperAmenities(projectId));
	}, []);
	if (loading) {
		return <>Loading</>;
	}
	if (error) {
		return <>{JSON.stringify(error)}</>;
	}
	return (
		<Layout pageTitle={"Edit Amenities List"}>
			{/* {JSON.stringify(amenitieObject)} */}
			<div className="row p-3 row-cols-auto gap-4">
				{amenitieObject?.amenities.map((item, index) => {
					return (
						<>
							<input
								type="checkbox"
								class="btn-check"
								id={`amenitie_${index}`}
								autocomplete="off"
								checked={amenitieObject?.in_project.includes(item.id)}
								onChange={(e) => {
									if (e.target.checked) {
										setAmenities({
											amenities: amenitieObject.amenities,
											in_project: [...amenitieObject.in_project, item.id],
										});
									} else {
										setAmenities({
											amenities: amenitieObject.amenities,
											in_project: amenitieObject.in_project.filter(
												(id) => id !== item.id
											),
										});
									}
								}}
							/>
							<label class="btn btn-outline-primary" for={`amenitie_${index}`}>
								<div className="d-grid  p-4 place-items-center">
									<img
										src={item.icon.replace("/media/", "").replace("%3A", ":/")}
										alt=""
										style={{
											width: "64px",
											aspectRatio: "1/1",
											objectFit: "contain",
										}}
									/>
									<p className="m-0">{item.name}</p>
								</div>
							</label>
						</>
					);
				})}
				{amenitieObject?.in_project.filter(
					(element) => !amenities.in_project.includes(element)
				).length > 0 ||
				amenities?.in_project.filter(
					(element) => !amenitieObject.in_project.includes(element)
				).length > 0 ? (
					<div className="col-12 sticky-bottom bg-white py-3 d-flex justify-content-end gap-3">
						<button className="btn btn-success" onClick={amenitiesEditHandler}>
							Save
						</button>
						<button
							className="btn btn-outline-danger"
							onClick={() => setAmenities(amenities)}
						>
							Cancel
						</button>
					</div>
				) : (
					<div className="col-12 sticky-bottom bg-white py-3 d-flex justify-content-end gap-3">
						<button className="btn btn-success" type="submit">Save</button>
						<button className="btn btn-dark" onClick={()=>navigate(-1)}>
							Cancel
						</button>
						
					</div>
				)}
			</div>
		</Layout>
	);
};

export default Amenities;
