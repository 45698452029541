import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProjectCollaterals } from "../Actions/CommonAction";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const CollateralsDownload = () => {
	const [colaterals, setCollaterals] = useState([]);
	const dispatch = useDispatch();
	const { loading, files, error } = useSelector((state) => state?.collaterals);
	const params = useParams();
	useEffect(() => {
		if (params.projectId !== undefined && params.projectId !== null) {
			dispatch(getProjectCollaterals(params.projectId));
		}
	}, [params.projectId]);
	useEffect(() => {
		if (files !== undefined) {
			setCollaterals(files);
		}
	}, [files]);
	return (
		<div className="vh-100 vw-100 d-grid align-items-center py-3 " style={{overflowX:"hidden",overflowY:"scroll"}} >
      <div className="row">
        <div className="col-lg-4"></div>
        <div className="col-lg-4 d-flex justify-content-center">
          	<div className="d-grid w-80  gap-3">
				{colaterals.map((file) => {
					return (
						<a
							href={"https://api.eazyapp.in" + file.file}
							target="_blank"
							className="text-decoration-none"
							download
							key={file.key}
						>
							<div className="border rounded p-3">

							<div className="d-flex justify-content-between ">
								<div className="">{file.key}</div>
								<div className=""><FontAwesomeIcon icon={icon.faDownload} /></div>
							</div>
              </div>
						</a>
					);
				})}
			</div>
        </div>
        <div className="col-lg-4"></div>
      </div>
		
		</div>
	);
};

export default CollateralsDownload;
