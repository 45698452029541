import React, { useState,useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import phone_countries from "../assets/PhoneNumberValidations";
import { useDispatch,useSelector } from "react-redux";
import { getEmployeeDetail } from "../Actions/CPAction";
import { editEmployee } from "../Actions/CommonAction";
import { useNavigate } from "react-router-dom";
const EditProfile = () => {
    const dispatch=useDispatch()
	const navigate=useNavigate()
    const {id} = useSelector((state) => state?.userState?.userInfo);
    useEffect(()=>{
        dispatch(getEmployeeDetail(id))
    },[id])
    const { detail } = useSelector(
			(state) => state?.cp?.employeeDetail
		);
	const [validNumber, setValidNumber] = useState(false);
	const EditHandler = (e) => {
        e.preventDefault()
		const formData = new FormData(e.target);
        dispatch(editEmployee(formData, navigate));
	};
	return (
		<div>
			<form className="d-grid gap-3 p-2" onSubmit={EditHandler}>
				<div>
					<label>First Name</label>
					<input
						type="text"
						defaultValue={detail?.first_name}
						className="form-control"
						name="first_name"
					/>
				</div>
				<div>
					<label>Last Name</label>
					<input
						type="text"
						defaultValue={detail?.last_name}
						className="form-control"
						name="last_name"
					/>
				</div>
				<div>
					<label>email</label>
					<input
						type="email"
						defaultValue={detail?.email}
						className="form-control"
						name="email"
					/>
				</div>
				<div>
					<label>Mobile Number</label>
					<PhoneInput
						country={"in"}
						countryCodeEditable={false}
						placeholder="Enter phone number"
						inputProps={{
							name: "mobile_number",
							required: true,
						}}
						isValid={(inputNumber, country, countries) => {
							let validation = phone_countries.filter(
								(phone) => phone.iso2 === country.iso2.toUpperCase()
							)[0].validation;
							if (validation.test(inputNumber)) {
								setValidNumber(true);
								return true;
							} else {
								setValidNumber(false);
								return false;
							}
						}}
						value={detail?.phone_number}
						enableSearch={true}
						containerClass="my-container-class  "
						// className="form-control"
						inputClass="form-control"
					/>
				</div>
				<div>
					<label>Photo</label>
					<input type="file" className="form-control" name="photo" />
				</div>
				<button className="btn btn-success">Save</button>
			</form>
		</div>	
	);
};

export default EditProfile;
