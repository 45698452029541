import React,{useEffect,useState} from 'react'
import Layout from "../Layout/Layout"
import { useDispatch,useSelector } from 'react-redux'
import { useParams,useNavigate } from 'react-router-dom'
import { deleteOfferData, getProjectOfferList } from '../../../Actions/AdminAction'
import ConfirmDelete from '../Components/ConfirmDelete'
const OfferSetting = () => {
    const dispatch = useDispatch();
    const navigate=useNavigate()
    const {projectId}=useParams()
    const {loading,error,offerList}=useSelector(state=>state?.developer?.project?.offerList)
    const user=useSelector(state=>state?.userState?.userInfo)
    useEffect(()=>{
        dispatch(getProjectOfferList(projectId))
    },[])
  return (
    <Layout pageTitle={"Offer Setting"}>
        <div className="d-grid gap-3 p-3">
              {!user.extra?.viewer_only &&<div className="text-end">
                <button className="btn btn-primary" onClick={()=>{
                      navigate(`/add-offer-setting/${projectId}`)
                }}>Add Offer</button>
            </div>}
            <div className="d-grid p-3 gap-3">
                {offerList?.map(item=>{
                    return <div className='p-3 border rounded d-flex flex-grow'>
                        <div className="flex-fill">{item.key}</div>
                        {!user.extra?.viewer_only &&<><div className='flex-shrink-1 p-2' ><button className="btn btn-success" onClick={()=>{
                            navigate(`/edit-offer-data/${item.id}`)
                        }} >Edit</button></div>
                        <div className='flex-shrink-1 p-2' ><button className="btn btn-danger" 
                        // onClick={()=>{
                        //     dispatch(deleteOfferData(item.id,projectId))
                        // }} 
                        
                        >
                            <ConfirmDelete id={item.id} idToCall={projectId} callback={deleteOfferData} />
                            </button></div></>  }
                    </div>
                })}
            </div>
        </div>
    </Layout>
  )
}

export default OfferSetting
