import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	getDeveloperEmployees,
	deletedeveloperEmployee,
} from "../../Actions/AdminAction";
import Layout from "./Layout/Layout";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import ConfirmDelete from "./Components/ConfirmDelete";

const TeamManagement = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading, error, employeeList } = useSelector(
		(state) => state?.developer?.employee?.list
	);
	const user=useSelector(state=>state?.userState?.userInfo)
	useEffect(() => {
		dispatch(getDeveloperEmployees());
	}, []);
	if (loading) {
		return <>Loading</>;
	}
	if (error) {
		return <>Error</>;
	}
	if (employeeList) {
		return (
			<Layout pageTitle={"Employee List"}>
				<div className="p-3">
				<div className="row  w-100">
					{
						!user.extra?.viewer_only &&
					<div className=" col-12 text-end">
						<button
							className="btn btn-primary"
							onClick={() => {
								navigate("/add/employee");
							}}
						>
							Add Employee 
						</button>
					</div>
					}
					{employeeList.map((employee) => {
						return (
							<div className="col-md-6 p-3">

							<div className="p-2 rounded border shadow-sm position-relative">
								<Dropdown
									className="position-absolute end-0  "
									style={{ zIndex: "1" }}
								>
									<Dropdown.Toggle
										className="hide-arrow"
										variant=""
										id="dropdown-basic"
									>
										<FontAwesomeIcon icon={icon.faEllipsis} />
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item onClick={() => {navigate(`/edit/employee/${employee.id}`)}}>Edit</Dropdown.Item>
										<Dropdown.Item 
										// onClick={() => {
										// 	dispatch(deletedeveloperEmployee(employee.id));
										// }}
										
										>
											<ConfirmDelete id={employee.id} callback={deletedeveloperEmployee} />

										</Dropdown.Item>
										{employee.project_working_on.map((item) => {
											return (
												<Dropdown.Item
													onClick={() => {
														alert(
															`remove from ${item.project} ${item.project_id} ${employee.id}`
														);
													}}
												>
													Remove From {item.project}
												</Dropdown.Item>
											);
										})}
									</Dropdown.Menu>
								</Dropdown>
								<div className="d-flex">
									<div className="">
										<img
											src={"https://api.eazyapp.in" + employee.photo}
											alt=""
											className="img-thumbnail"
											style={{ maxHeight: "150px", width: "auto" }}
										/>
									</div>
									<div className="ms-2">
										<p className="m-0 card_heading">
											{employee.first_name} {employee.last_name}
										</p>
										<p className="m-0">
											<span className="text-muted">Email:</span>{" "}
											{employee.email}
										</p>
										<p className="m-0">
											<span className="text-muted">Mobile Number:</span>{" "}
											{employee.phone_number}
										</p>
										<p className="m-0">
											<span className="text-muted">
												Working on Number Of Projects:
											</span>{" "}
											{employee.project_working_on.length}
										</p>
									</div>
								</div>
							</div>
							</div>
						);
					})}
				</div>
				</div>
			</Layout>
		);
	}
};

export default TeamManagement;
