import React, { useEffect, useState } from 'react'
import Layout from './Layout/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { getCustomerDashboardData } from '../../Actions/CustomerAction';
import numberToWords from '../numberFormater';
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import { Pie } from 'react-chartjs-2';

const Dashboard = () => {
    const [bookingId, setBookingId] = useState(null)
    const dispatch = useDispatch();
    const currentBookingId = useSelector((state) => state?.customer?.currentBookingId);
    const { dashboardData, loading, error } = useSelector((state) => state?.customer?.dashboardData);
    useEffect(() => {
        const params = {}
        if (currentBookingId !== null) {
            params["booking_id"] = currentBookingId
        }
        dispatch(getCustomerDashboardData(params))
    }, [currentBookingId])

    if (loading) {
        return <div>Loading</div>
    }

    if (error) {
        return <div>Error</div>
    }

    return (
        <Layout pageTitle={"DashBoard"}>
            <div className="p-3">
                {/* <div className="d-flex justify-content-end">
                    <div>
                        
                    </div>
                </div> */}
                <div className="row w-100">

                    <div className="col-md-6 p-3">
                        <div className="rounded border h-100 border-info overflow-hidden">
                            <div className="bg-info p-2">
                                <h3 className='text-white'><FontAwesomeIcon icon={icon.faFileInvoice} /> Last 3 Payements</h3>
                            </div>
                            <div className='p-3'>

                                {dashboardData?.last_three_payements?.length > 0 ? <>

                                    <div>
                                        <div className="row">
                                            <div className="col-3 px-2"><p className='text-muted'>Recipet No</p></div>
                                            <div className="col-3 px-2"><p className='text-muted'>Payment Mode</p></div>
                                            <div className="col-3 px-2"><p className='text-muted'>Date of Payment</p></div>
                                            <div className="col-3 px-2"><p className='fw-bold'>Amount</p></div>
                                        </div>
                                        {dashboardData.last_three_payements?.map((item) => {
                                            return <div className="row">
                                                <div className="col-3 px-2"><p className='text-muted'>{item.id}</p></div>
                                                <div className="col-3 px-2"><p className='text-muted'>{item.payment_mode}</p></div>
                                                <div className="col-3 px-2"><p className='text-muted'>{moment(item.date_of_payment).format("DD-MM-YYYY")}</p></div>
                                                <div className="col-3 px-2"><p className='fw-bold'>{numberToWords(item.amount)}</p></div>
                                            </div>
                                        })}
                                    </div>
                                </> : <div className='h-100 d-grid place-items-center'><h4>NO Payments Yet</h4></div>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="w-50">

                        <Pie data={{
                            labels: ['Unpaid', 'Paid'],
                            datasets: [
                                {
                                    data: [dashboardData?.total_amount - dashboardData?.paid, dashboardData?.paid],
                                    backgroundColor: ['#FF6384', '#36A2EB'],
                                    hoverBackgroundColor: ['#FF6384', '#36A2EB'],
                                },
                            ],
                        }} />
                        </div>
                    </div>

                    <div className="col-md-6 p-3">
                        <div className="rounded border h-100 border-danger overflow-hidden">
                            <div className="bg-danger p-2">
                                <h3 className='text-white'> <FontAwesomeIcon icon={icon.faIndianRupeeSign} /> Outstanding Dues</h3>

                            </div>
                            <div className='p-3'>
                                <div className="row">
                                    <div className="col-6">
                                        <label className='text-muted'>Outstanding Amount</label>
                                        <p className='fw-bold fs-4'>{numberToWords(dashboardData?.due_amount?.total_due)}</p>
                                    </div>
                                    <div className="col-6">
                                        <label className="text-muted">Due Date</label>
                                        <p className='fw-bold fs-4'>{dashboardData?.due_amount?.due_date !== "-" ? moment(dashboardData?.due_amount?.due_date).format("DD-MM-YYYY") : dashboardData?.due_amount?.due_date}</p>
                                    </div>
                                    <div className="col-6">
                                        <label className="text-muted">Fresh Dues</label>
                                        <p className='fw-bold fs-4'>{numberToWords(dashboardData?.due_amount?.fresh_due)}</p>
                                    </div>
                                    <div className="col-6">
                                        <label className="text-muted">Past Dues</label>
                                        <p className='fw-bold fs-4'>{numberToWords(dashboardData?.due_amount?.total_due - dashboardData?.due_amount?.fresh_due)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                    <div className="col-md-6 p-3">
                        <div className="rounded border border-success h-100 overflow-hidden">
                            <div className="bg-success p-2">
                                <h3 className='text-white'>Notifications </h3>
                            </div>
                            <div className="h-100 d-grid place-items-center ">
                            <h4>No Notifications</h4>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default Dashboard
