var a = [
	"",
	"one ",
	"two ",
	"three ",
	"four ",
	"five ",
	"six ",
	"seven ",
	"eight ",
	"nine ",
	"ten ",
	"eleven ",
	"twelve ",
	"thirteen ",
	"fourteen ",
	"fifteen ",
	"sixteen ",
	"seventeen ",
	"eighteen ",
	"nineteen ",
];
var b = [
	"",
	"",
	"twenty",
	"thirty",
	"forty",
	"fifty",
	"sixty",
	"seventy",
	"eighty",
	"ninety",
];

const numberToWords = (num) => {
	const formatter = new Intl.NumberFormat("en-IN", {
		style: "currency",
		currency: "INR",
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
		notation: "compact",
		compactDisplay: "long",
	});

	var money = num;
    if (toString(money).length > 5) {
			let amount = formatter.format(money);
			let formated_amout = amount
			return formated_amout;
		} else {
			let formated_amout = money.toLocaleString("en-IN", {
				maximumFractionDigits: 0,
				style: "currency",
				currency: "INR",
			});
			return formated_amout
        };
	
};

export default numberToWords;
