import React ,{useEffect,useState}from 'react'
import Layout from '../AdminScreens/Layout/Layout'
import { getInventoryData } from '../../Actions/AdminAction'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Table from "react-bootstrap/Table";
import { Modal } from 'react-bootstrap'



const Unit= ({unit,projectId,wing,inventoryId}) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <td
        onClick={() => setShow(true)}
        className={!unit.useable ? 'bg-dark text-white text-center' : unit.booked ? 'bg-danger text-center text-white' : 'bg-success text-center text-white'}
        colSpan={unit?.colSpan ? unit?.colSpan : "1"}
        rowSpan={unit?.rowSpan ? unit?.rowSpan : "1"}
        style={{
          width: unit?.colSpan
            ? unit?.colSpan !== "100%"
              ? `${10 * unit?.colSpan}%`
              : "100%"
            : "10%",
        }}
      >
        {unit.label}
      </td>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{unit.label}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Configuration : {unit.config}</p>
          <p>Carpet Area : {unit.carpet_area} sq.ft.</p>
          <p>Booked : {unit.booked ? "Yes" : "No"}</p>
          {!unit.booked && (
            <button
              className="btn btn-dark w-100"
              onClick={() => navigate(`/add-customer/${inventoryId}/${projectId}/${unit.unit_no}`)}
            >
              Book Unit {unit.unit_no}
            </button>
          )}
        </Modal.Body>
      </Modal>

    </>
  )
}

const InventoryView = () => {


  const { projectId, inventoryId } = useParams()
  const dispatch = useDispatch();
  const { inventoryData } = useSelector((state) => state?.developer?.inventoryData);
  useEffect(() => {
    dispatch(getInventoryData(inventoryId))
  }, [])
  return (
    <Layout pageTitle={"Inventory View"}>
      <h2 className="text-center my-2">{inventoryData?.wing}</h2>
      {inventoryData &&
      <div className="p-3">
        <Table responsive={true} bordered hover>
          <thead>
            <tr>
              <th className="text-nowrap text-center">Floor</th>
              {[
                ...Array(
                  inventoryData?.units?.max_unit ? inventoryData?.units?.max_unit : 0
                ).keys(),
              ].map((unit) => {
                return <th className="text-center">Unit {unit + 1}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {Object.keys(inventoryData?.units).filter((key) => key !== "length" && key !== "max_unit").sort(function (a, b) {
              return b - a;
            }).map((key) => {
              const floor = inventoryData?.units[key];
              return (
                <tr style={{ width: "100%" }}>
                  <td className="text-center"  style={{width:"10%"}} >{key}</td>
                  {floor.map((unit) => {
                    return (
                      <Unit unit={unit} projectId={projectId} floorNo={key} wing={inventoryData?.wing} inventoryId={inventoryData.id} />
                    );
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
      }
    </Layout>
  )
}

export default InventoryView
