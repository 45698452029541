import axios from "./axios";
import {
    GET_DEVELOPER_LIST_REQUEST,
    GET_DEVELOPER_LIST_SUCCESS,
    GET_DEVELOPER_LIST_FAIL,
    // 
    GET_DEVELOPER_DETAILS_REQUEST,
    GET_DEVELOPER_DETAILS_SUCCESS,
    GET_DEVELOPER_DETAILS_FAIL,
    // 
    UPDATE_DEVELOPER_DETAILS_REQUEST,
    UPDATE_DEVELOPER_DETAILS_SUCCESS,
    UPDATE_DEVELOPER_DETAILS_FAIL,
    // 
    GET_DEVELOPER_EMPLOYEES_REQUEST,
    GET_DEVELOPER_EMPLOYEES_SUCCESS,
    GET_DEVELOPER_EMPLOYEES_FAIL,
    // 
    GET_DEVELOPER_EMPLOYEE_DETAILS_REQUEST,
    GET_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS,
    GET_DEVELOPER_EMPLOYEE_DETAILS_FAIL,
    // 
    UPDATE_DEVELOPER_EMPLOYEE_DETAILS_REQUEST,
    UPDATE_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS,
    UPDATE_DEVELOPER_EMPLOYEE_DETAILS_FAIL,
    // 
    ADD_DEVELOPER_EMPLOYEE_DETAILS_REQUEST,
    ADD_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS,
    ADD_DEVELOPER_EMPLOYEE_DETAILS_FAIL,
    // 
    DELETE_DEVELOPER_EMPLOYEE_REQUEST,
    DELETE_DEVELOPER_EMPLOYEE_SUCCESS,
    DELETE_DEVELOPER_EMPLOYEE_FAIL,
    // 
    GET_DEVELOPER_SETTING_TYPE_REQUEST,
    GET_DEVELOPER_SETTING_TYPE_SUCCESS,
    GET_DEVELOPER_SETTING_TYPE_FAIL,
    // 
    GET_SETTING_TYPE_REQUEST,
    GET_SETTING_TYPE_SUCCESS,
    GET_SETTING_TYPE_FAIL,
    // 
    ADD_DEVELOPER_SETTING_REQUEST,
    ADD_DEVELOPER_SETTING_SUCCESS,
    ADD_DEVELOPER_SETTING_FAIL,
    // 
    GET_PROJECT_LIST_REQUEST,
    GET_PROJECT_LIST_SUCCESS,
    GET_PROJECT_LIST_FAIL,
    // 
    GET_PROJECT_DETAILS_REQUEST,
    GET_PROJECT_DETAILS_SUCCESS,
    GET_PROJECT_DETAILS_FAIL,
    // 
    ADD_PROJECT_REQUEST,
    ADD_PROJECT_SUCCESS,
    ADD_PROJECT_FAIL,
    // 
    ADD_MANDATE_COMPANY_REQUEST,
    ADD_MANDATE_COMPANY_SUCCESS,
    ADD_MANDATE_COMPANY_FAIL,
    // 
    REMOVE_MANDATE_COMPANY_REQUEST,
    REMOVE_MANDATE_COMPANY_SUCCESS,
    REMOVE_MANDATE_COMPANY_FAIL,
    // 
    GET_CHANNEL_PARTNER_LIST_REQUEST,
    GET_CHANNEL_PARTNER_LIST_SUCCESS,
    GET_CHANNEL_PARTNER_LIST_FAIL,
    // 
    GET_CHANNEL_PARTNER_DETAIL_REQEUST,
    GET_CHANNEL_PARTNER_DETAIL_SUCCESS,
    GET_CHANNEL_PARTNER_DETAIL_FAIL,
    // 
    ADD_CHANNEL_PARTNER_REQUEST,
    ADD_CHANNEL_PARTNER_SUCCESS,
    ADD_CHANNEL_PARTNER_FAIL,
    // 
    ADD_CHANNEL_PARTNER_EMPLOYEE_REQUEST,
    ADD_CHANNEL_PARTNER_EMPLOYEE_SUCCESS,
    ADD_CHANNEL_PARTNER_EMPLOYEE_FAIL,
    // 
    GET_CP_EMPLOYEE_DETAILS_REQUEST,
    GET_CP_EMPLOYEE_DETAILS_SUCCESS,
    GET_CP_EMPLOYEE_DETAILS_FAIL,
    // 
    UPDATE_CP_EMPLOYEE_DETAIL_REQUEST,
    UPDATE_CP_EMPLOYEE_DETAIL_SUCCESS,
    UPDATE_CP_EMPLOYEE_DETAIL_FAIL,
    // 
    GET_DASHBOARD_DATA_REQUEST,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_FAIL,
    // 
    GET_ADMIN_USER_LIST_REQUEST,
    GET_ADMIN_USER_LIST_SUCCESS,
    GET_ADMIN_USER_LIST_FAIL,
    // 
    GET_ADMIN_USER_DETAIL_REQUEST,
    GET_ADMIN_USER_DETAIL_SUCCESS,
    GET_ADMIN_USER_DETAIL_FAIL,
    // 
    ADD_ADMIN_USER_REQUEST,
    ADD_ADMIN_USER_SUCCESS,
    ADD_ADMIN_USER_FAIL,
    // 
    GET_ADMIN_ROLES_REQUEST,
    GET_ADMIN_ROLES_SUCCESS,
    GET_ADMIN_ROLES_FAIL,
    // 
    UPDATE_ADMIN_USER_DETAIL_REQUEST,
    UPDATE_ADMIN_USER_DETAIL_SUCCESS,
    UPDATE_ADMIN_USER_DETAIL_FAIL,
    // 
    GET_ADMIN_PERMISSIONS_REQUEST,
    GET_ADMIN_PERMISSIONS_SUCCESS,
    GET_ADMIN_PERMISSIONS_FAIL,
    // 
    CHANGE_ROLE_PERMISSION_REQUEST,
    CHANGE_ROLE_PERMISSION_SUCCESS,
    CHANGE_ROLE_PERMISSION_FAIL,
    // 
    ADD_NEW_ADMIN_ROLE_REQUEST,
    ADD_NEW_ADMIN_ROLE_SUCCESS,
    ADD_NEW_ADMIN_ROLE_FAIL,
    // 
    ADD_DEVELOPER_REQUEST,
    ADD_DEVELOPER_SUCCESS,
    ADD_DEVELOPER_FAIL,
    // 
    EDIT_ADMIN_PROJECT_DETAIL_REQUEST,
    EDIT_ADMIN_PROJECT_DETAIL_SUCCESS,
    EDIT_ADMIN_PROJECT_DETAIL_FAIL,
    // 
    EDIT_ADMIN_CHANNEL_PARTNER_DETAIL_REQUEST,
    EDIT_ADMIN_CHANNEL_PARTNER_DETAIL_SUCCESS,
    EDIT_ADMIN_CHANNEL_PARTNER_DETAIL_FAIL,
} from "../Constants/MyAdminConstants.js"
import toast from "react-hot-toast";


/**
 * Asynchronous function to fetch a list of developers from the API and update the Redux store state accordingly.
 *
 * @return {Promise} The data returned from the API call.
 */
export const getDeveloperList = () => async (dispatch, getState) => {
    try {
        // Dispatch action to indicate the start of fetching developer list
        dispatch({
            type: GET_DEVELOPER_LIST_REQUEST
        })
        // Get the user info from the current state
        const {
            userState: { userInfo },
        } = getState();
        // Set the request headers
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`,
            },
        };
        // Fetch the list of developers from the API
        const { data } = await axios.get("get/developer-list/", config);
        // Dispatch action to update the state with the fetched developer list
        dispatch({
            type: GET_DEVELOPER_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        // Dispatch action to update the state with the error message
        dispatch({
            type: GET_DEVELOPER_LIST_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message,
        })
    }
}

export const getDeveloperDetails = (id) => async (dispatch, getState) => {
    /**
     * Retrieves the developer details from the API based on the provided ID.
     *
     * @param {string} id - The ID of the developer
     * @return {Promise} A Promise that resolves with the fetched developer details
     */
    try {
        // Dispatch action to indicate the start of fetching developer details
        dispatch({
            type: GET_DEVELOPER_DETAILS_REQUEST
        })
        // Get the user info from the current state
        const {
            userState: { userInfo },
        } = getState();
        // Set the request headers
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`,
            },
        };
        // Fetch the developer details from the API
        const { data } = await axios.get(`get/developer-details/${id}`, config);
        // Dispatch action to update the state with the fetched developer details 
        dispatch({
            type: GET_DEVELOPER_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_DEVELOPER_DETAILS_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message,
        })
    }
}

export const updateDeveloperDetails = (postData, developerId, setEdit) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPDATE_DEVELOPER_DETAILS_REQUEST
        })
        const {
            userState: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.put(`update/developer-details/${developerId}`, postData, config)

        toast.success("Developer Details Updated Successfully")

        dispatch({
            type: UPDATE_DEVELOPER_DETAILS_SUCCESS,
            payload: data
        })
        dispatch(getDeveloperDetails(developerId))
        setEdit(false)
    } catch (error) {
        dispatch({
            type: UPDATE_DEVELOPER_DETAILS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.message,
        });
    }
}


export const getDeveloperEmployeeList = (developerId, params) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_DEVELOPER_EMPLOYEES_REQUEST
        })

        const {
            userState: { userInfo }
        } = getState()

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            },
            params: params,
        }
        const { data } = await axios.get(`get/developer-employees/${developerId}`, config)
        dispatch({
            type: GET_DEVELOPER_EMPLOYEES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_DEVELOPER_EMPLOYEES_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}

export const getDeveloperEmployeeDetails = (employeeId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_DEVELOPER_EMPLOYEE_DETAILS_REQUEST
        })

        const {
            userState: { userInfo }
        } = getState()

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.get(`get/developer-employee-details/${employeeId}`, config)
        dispatch({
            type: GET_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_DEVELOPER_EMPLOYEE_DETAILS_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}

export const updateDeveloperEmployeeDetails = (postData, employeeId, setEdit) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPDATE_DEVELOPER_EMPLOYEE_DETAILS_REQUEST
        })
        const {
            userState: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.put(`update/developer-employee-details/${employeeId}`, postData, config)

        toast.success("Developer Employee Details Updated Successfully")

        dispatch({
            type: UPDATE_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS,
            payload: data
        })
        dispatch(getDeveloperEmployeeDetails(employeeId))
        setEdit(false)
    } catch (error) {
        dispatch({
            type: UPDATE_DEVELOPER_EMPLOYEE_DETAILS_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}


export const addDeveloperEmployeeDetails = (postData, developerId, navigate) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_DEVELOPER_EMPLOYEE_DETAILS_REQUEST
        })
        const {
            userState: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.post(`add/developer-employee-details/${developerId}`, postData, config)

        toast.success("Developer Employee Details Added Successfully")

        dispatch({
            type: ADD_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS,
            payload: data
        })
        navigate(-1)

    } catch (error) {
        dispatch({
            type: ADD_DEVELOPER_EMPLOYEE_DETAILS_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}


export const deleteDeveloperEmployee = (employeeId, navigate) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DELETE_DEVELOPER_EMPLOYEE_REQUEST
        })
        const {
            userState: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.delete(`delete/developer-employee-details/${employeeId}`, config)

        toast.success("Developer Employee Details Deleted Successfully")

        dispatch({
            type: DELETE_DEVELOPER_EMPLOYEE_SUCCESS,
            payload: data
        })
        navigate(-1)
    } catch (error) {
        dispatch({
            type: DELETE_DEVELOPER_EMPLOYEE_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })

    }
}



export const getDeveloperSettingType = (developerId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_DEVELOPER_SETTING_TYPE_REQUEST
        })
        const {
            userState: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.get(`get/developer-setting-type/${developerId}`, config)
        dispatch({
            type: GET_DEVELOPER_SETTING_TYPE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_DEVELOPER_SETTING_TYPE_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}

export const getSettingType = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_SETTING_TYPE_REQUEST
        })
        const { userState: { userInfo } } = getState();
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.get("admin/get/setting-type", config)
        dispatch({
            type: GET_SETTING_TYPE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_SETTING_TYPE_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}

export const addDeveloperSetting = (postData, navigate) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_DEVELOPER_SETTING_REQUEST
        })
        const {
            userState: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.post("admin/add/developer-setting/", postData, config)

        toast.success("Developer Setting Added Successfully")

        dispatch({
            type: ADD_DEVELOPER_SETTING_SUCCESS,
            payload: data
        })
        navigate(-1)
    } catch (error) {
        dispatch({
            type: ADD_DEVELOPER_SETTING_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const getProjectList = (params) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PROJECT_LIST_REQUEST
        })
        const {
            userState: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            },
            params: params
        }
        const { data } = await axios.get("admin/get/all-projects", config)
        dispatch({
            type: GET_PROJECT_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_PROJECT_LIST_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const getProjectDetails = (projectId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_PROJECT_DETAILS_REQUEST
        })
        const {
            userState: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.get(`admin/get/project-details/${projectId}`, config)
        dispatch({
            type: GET_PROJECT_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_PROJECT_DETAILS_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const addProject = (postData, navigate) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_PROJECT_REQUEST
        })
        const { userState: { userInfo } } = getState()
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.post("admin/add/project", postData, config)
        toast.success("Project Added Successfully")
        navigate("/project-detail/" + data.id)
        dispatch({
            type: ADD_PROJECT_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ADD_PROJECT_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}

export const addMandateCompany = (postData, navigate, mandateId = null) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_MANDATE_COMPANY_REQUEST
        })
        const { userState: { userInfo } } = getState()
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        if (mandateId != null) {
            const { data } = await axios.put(`admin/add-mandate-company/${mandateId}`, postData, config)

            toast.success("Mandate Company Added Successfully")

            dispatch({
                type: ADD_MANDATE_COMPANY_SUCCESS,
                payload: data
            })

            navigate(postData) // this is not getprojectDetdetails and projectId

        } else {

            const { data } = await axios.post("admin/add-mandate-company", postData, config)

            toast.success("Mandate Company Added Successfully")

            navigate(-1)
            dispatch({
                type: ADD_MANDATE_COMPANY_SUCCESS,
                payload: data
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_MANDATE_COMPANY_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const removeMandateCompnay = (mandateId, projectId, navigate) => async (dispatch, getState) => {
    try {

        dispatch({ type: REMOVE_MANDATE_COMPANY_REQUEST })

        const { userState: { userInfo } } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.delete(`admin/remove-mandate-company/${mandateId}`, config)

        toast.success("Mandate Company Removed Successfully")

        dispatch({
            type: REMOVE_MANDATE_COMPANY_SUCCESS,
            payload: data
        })

        dispatch(getProjectDetails(projectId))


    } catch (error) {
        dispatch({
            type: REMOVE_MANDATE_COMPANY_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const getChannelPartnerList = (params) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_CHANNEL_PARTNER_LIST_REQUEST
        })
        const { userState: { userInfo } } = getState();
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            },
            params: params,
        }
        const { data } = await axios.get("admin/get/channel-partner-list", config)
        dispatch({
            type: GET_CHANNEL_PARTNER_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_CHANNEL_PARTNER_LIST_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}


export const getChannelPartnerDetail = (cpId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_CHANNEL_PARTNER_DETAIL_REQEUST
        })
        const { userState: { userInfo } } = getState()
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.get(`admin/get/channel-partner-details/${cpId}`, config)
        dispatch({
            type: GET_CHANNEL_PARTNER_DETAIL_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_CHANNEL_PARTNER_DETAIL_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}


export const addChannelPartner = (postData, navigate) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_CHANNEL_PARTNER_REQUEST
        })
        const { userState: { userInfo } } = getState()
        const config = {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.post("admin/add/channel-partner", postData, config)

        toast.success("Channel Partner Added Successfully")

        navigate(`channel-partner/${data.id}`)
        dispatch({
            type: ADD_CHANNEL_PARTNER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ADD_CHANNEL_PARTNER_FAIL,
            error: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}


export const addChannelPartnerEmployee = (postData, cpId, navigate) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_CHANNEL_PARTNER_EMPLOYEE_REQUEST
        })
        const { userState: { userInfo } } = getState()
        const config = {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.post(`admin/add/cp-employee/${cpId}`, postData, config)

        toast.success("Channel Partner Employee Added Successfully")

        navigate(-1)
        dispatch({
            type: ADD_CHANNEL_PARTNER_EMPLOYEE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ADD_CHANNEL_PARTNER_EMPLOYEE_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const getCPEmployeeDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_CP_EMPLOYEE_DETAILS_REQUEST
        })
        const { userState: { userInfo } } = getState()
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.get(`admin/get/cp-employee-detail/${id}`, config)
        dispatch({
            type: GET_CP_EMPLOYEE_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_CP_EMPLOYEE_DETAILS_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const updateCPEmployeeDetail = (postData, id, navigate) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPDATE_CP_EMPLOYEE_DETAIL_REQUEST
        })
        const { userState: { userInfo } } = getState();
        const config = {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.put(`admin/update/cp-employee/${id}`, postData, config)

        toast.success("Channel Partner Employee Updated Successfully")

        navigate(-1)
        dispatch({
            type: UPDATE_CP_EMPLOYEE_DETAIL_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: UPDATE_CP_EMPLOYEE_DETAIL_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}


export const getDashBoardData = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_DASHBOARD_DATA_REQUEST
        })
        const { userState: { userInfo } } = getState()
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.get("admin/get/dashboard-data", config)
        dispatch({
            type: GET_DASHBOARD_DATA_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_DASHBOARD_DATA_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}




export const getAdminUserList = (params) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ADMIN_USER_LIST_REQUEST
        })
        const { userState: { userInfo } } = getState();
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            },
            params: params
        }

        const { data } = await axios.get("admin/get/admin-employees", config)
        dispatch({
            type: GET_ADMIN_USER_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_ADMIN_USER_LIST_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const getAdminUserDetail = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ADMIN_USER_DETAIL_REQUEST
        })
        const { userState: { userInfo } } = getState();
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.get(`admin/get/admin-employee-detail/${id}`, config)
        dispatch({
            type: GET_ADMIN_USER_DETAIL_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: GET_ADMIN_USER_DETAIL_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const getAdminRoles = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_ADMIN_ROLES_REQUEST
        })
        const { userState: { userInfo } } = getState();
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.get("admin/get/admin-roles", config)
        dispatch({
            type: GET_ADMIN_ROLES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_ADMIN_ROLES_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}

export const updateAdminUserDetail = (id, postData, setEdit) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPDATE_ADMIN_USER_DETAIL_REQUEST
        })

        const { userState: { userInfo } } = getState();

        const config = {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.put(`admin/edit/admin-employee-detail/${id}`, postData, config)

        toast.success("Admin User Updated Successfully");

        setEdit(false)
        dispatch({
            type: UPDATE_ADMIN_USER_DETAIL_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_ADMIN_USER_DETAIL_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}


export const addAdminUser = (postData, navigate) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_ADMIN_USER_REQUEST
        })

        const { userState: { userInfo } } = getState();

        const config = {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.post("admin/add/admin-user", postData, config)

        toast.success("Admin User Added Successfully");

        navigate(-1)
        dispatch({
            type: ADD_ADMIN_USER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ADD_ADMIN_USER_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}


export const getPermissionList = () => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_ADMIN_PERMISSIONS_REQUEST
        })

        const { userState: { userInfo } } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.get("admin/get/admin-permissions", config)
        dispatch({
            type: GET_ADMIN_PERMISSIONS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_ADMIN_PERMISSIONS_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const changeRolePermissions = (postData) => async (dispatch, getState) => {
    try {

        dispatch({
            type: CHANGE_ROLE_PERMISSION_REQUEST
        })

        const { userState: { userInfo } } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.post("admin/change/admin-role-permissions", postData, config)

        dispatch({
            type: CHANGE_ROLE_PERMISSION_SUCCESS,
            payload: data
        })
        dispatch(getAdminRoles())
    } catch (error) {
        dispatch({
            type: CHANGE_ROLE_PERMISSION_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}


export const addNewAdminRole = (postData) => async (dispatch, getState) => {
    try {

        dispatch({
            type: ADD_NEW_ADMIN_ROLE_REQUEST
        })

        const { userState: { userInfo } } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.post("admin/add/admin-role", postData, config)
        dispatch(getAdminRoles())
        dispatch({
            type: ADD_NEW_ADMIN_ROLE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ADD_NEW_ADMIN_ROLE_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}


export const addDeveloper = (postData, navigate) => async (dispatch, getState) => {
    try {

        dispatch({
            type: ADD_DEVELOPER_REQUEST
        })
        const { userState: { userInfo } } = getState()
        const config = {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.post("admin/add/developer", postData, config)
        dispatch({
            type: ADD_DEVELOPER_SUCCESS,
            payload: data
        })
        navigate("/developers")

    } catch (error) {
        dispatch({
            type: ADD_DEVELOPER_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const updateProjectDetail = (projectId, postData, navigate) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EDIT_ADMIN_PROJECT_DETAIL_REQUEST
        })
        const { userState: { userInfo } } = getState();
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.post(`admin/edit/project-detail/${projectId}`, postData, config)

        toast.success("Project Detail Updated Successfully")
        dispatch(getProjectDetails(projectId))
        dispatch({
            type: EDIT_ADMIN_PROJECT_DETAIL_SUCCESS,
            payload: data
        })
        navigate("/project-detail/" + projectId)

    } catch (error) {
        dispatch({
            type: EDIT_ADMIN_PROJECT_DETAIL_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message

        })
    }
}


export const editAdminChannelPartner = (channelPartnerId, postData, setEdit) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EDIT_ADMIN_CHANNEL_PARTNER_DETAIL_REQUEST
        })
        const { userState: { userInfo } } = getState();
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }
        const { data } = await axios.post(`admin/edit/channel-partner/${channelPartnerId}`, postData, config)

        toast.success("Channel Partner Detail Updated Successfully")

        dispatch(getChannelPartnerDetail(channelPartnerId))
        dispatch({
            type: EDIT_ADMIN_CHANNEL_PARTNER_DETAIL_SUCCESS,
            payload: data
        })
        // navigate("/channel-partner-detail/"+channelPartnerId)
        setEdit(false)

    } catch (error) {
        dispatch({
            type: EDIT_ADMIN_CHANNEL_PARTNER_DETAIL_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}