import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { getWhatsappAuth } from '../../Actions/AdminAction'
const Text = ({ textExampleHandler }) => {
    const [headerText, setHeaderText] = useState("")
    const [variables, setVariables] = useState({})
    const textRef = useRef()
    useEffect(() => {
        const header_handle = [Object.keys(variables).map(i => { return variables[i] })]
        if (header_handle.length > 0) {
            textExampleHandler(variables, headerText)
        }
    }, [variables, headerText])
    return <>
        <input ref={textRef} type="text" name="value" placeholder='Enter Header Text' onKeyUp={(e) => {
            const data = {
                ...variables
            }
            if (e.key === "{") {
                var new_var = `{{${Object.keys(variables).length + 1}}}`
                textRef.current.value = textRef.current.value.slice(0, -2) + new_var
                data[new_var] = null
            }
            setVariables(data)
        }} onChange={e => {
            setHeaderText(e.target.value)
            const data = {
                ...variables
            }
            Object.keys(data).map(i => {
                if (!e.target.value.includes(i)) {
                    delete data[i]
                }
            })
            setVariables(data)

        }} className="form-control" />
        <span onClick={() => {
            var new_var = `{{${Object.keys(variables).length + 1}}}`
            textRef.current.value = textRef.current.value + new_var
            const data = {
                ...variables
            }
            data[new_var] = null
            setVariables(data)
        }} >Add Variable</span>
        {Object.keys(variables).filter(i => "length" !== i).map(i => <p>{i} : <input type="text" className='form-control' onChange={(e) => { const data = { ...variables }; data[i] = e.target.value; setVariables(data) }} /></p>)}

    </>
}



const ImageHandler = ({ developerId, header, setHeader, setHeaderValue }) => {

    const dispatch = useDispatch()

    const { whatsappAuth } = useSelector((state) => state?.developer?.whatsappAuth)

    console.log(whatsappAuth, developerId,)

    useEffect(() => {
        dispatch(getWhatsappAuth(developerId))
    }, [developerId])

    const handleFileUpload = async (e) => {
        // const user_access_token = "EAAOV1rmrJzQBO2TRH38w01ITQhDYtUp0RB4kpBMBOWuj3bAUEdj2SVsIqZB94bRYyNul0kqlI7kiQ4KJXde9R6OS5gkKlwLsKm4JoSRw0GeGaHBgKbWUwNTN2tLIwld1mKml1GXOZCNGz1IyuVHxxPeNtbbZBYSEWlc51ZCfQtjHH80j3dOn0ruIjxbPIem6yi1mDFojkYptCpbA"
        // const app_id = "1009174400673588"
        const user_access_token = whatsappAuth.access_token
        const app_id = whatsappAuth.app_id


        const file = e.target.files[0];
        setHeaderValue({ type: "image", value: file });

        if (file.size === 0) {
            toast.error("Please Select File");
            e.target.value = "";
            return;
        }


        try {
            const startResponse = await axios.post(
                `https://graph.facebook.com/v20.0/${app_id}/uploads`,
                {
                    file_name: file.name,
                    file_length: file.size,
                    file_type: file.type,
                    access_token: user_access_token
                }
            );

            const uploadSessionId = startResponse.data.id;

            const formData = new FormData();

            formData.append('file', file)

            const uploadResponse = await axios.post("https://cors-anywhere.herokuapp.com/" + `https://graph.facebook.com/v20.0/${uploadSessionId}`, formData, {
                headers: {
                    Authorization: `OAuth ${user_access_token}`,
                    'Content-Type': `multipart/form-data`,
                    "file_offset": 0,
                    'Accept': '*/*',
                    'Access-Control-Allow-Credentials': 'true',
                    ' Access-Control-Allow-Origin': "https://secure.eazyapp.in",
                    withCredentials: true,
                    mode: 'no-cors',
                }
            })

            setHeader({ type: header.type, format: header.format, example: { header_handle: [uploadResponse.data.h] } });

        } catch (err) {
            console.error('Upload Error:', err);
        }

    }
    return <div>
        <h5>Samples for header content</h5>
        <p>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</p>
        <span>Image</span> <input type="file" className="form-control" onChange={handleFileUpload} />

    </div>
}


const VideoHandler = ({ developerId, header, setHeader, setHeaderValue }) => {
    const dispatch = useDispatch()

    const { whatsappAuth } = useSelector((state) => state?.developer?.whatsappAuth)

    useEffect(() => {
        dispatch(getWhatsappAuth({ developerId }))
    }, [])

    const handleFileUpload = async (e) => {
        const user_access_token = whatsappAuth.access_token
        const app_id = whatsappAuth.app_id

        const file = e.target.files[0];
        setHeaderValue({ type: "video", value: file })
        if (file.size == 0) {
            toast.error("Please Select File")
            e.target.value = ""
            return
        }
        try {
            const startResponse = await axios.post(
                "https://cors-anywhere.herokuapp.com/" + `https://graph-video.facebook.com/v20.0/${app_id}/uploads`,
                {
                    file_name: file.name,
                    file_length: file.size,
                    file_type: file.type,
                    access_token: user_access_token
                }
            );

            const uploadSessionId = startResponse.data.id;

            const formData = new FormData();

            formData.append('file', file)

            const uploadResponse = await axios.post("https://cors-anywhere.herokuapp.com/" + `https://graph.facebook.com/v20.0/${uploadSessionId}`, formData, {
                headers: {
                    Authorization: `OAuth ${user_access_token}`,
                    'Content-Type': `multipart/form-data`,
                    'Accept': '*/*',
                    'Access-Control-Allow-Origin': 'https://secure.eazyapp.in',
                    'Access-Control-Allow-Credentials': 'true',
                }
            })

            setHeader({ type: header.type, format: header.format, example: { header_handle: [uploadResponse.data.h] } });

        } catch (err) {
            console.error('Upload Error:', err);
        }
    }
    return <div>
        <h5>Samples for header content</h5>
        <p>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</p>
        <span>Video</span> <input type="file" className="form-control" onChange={handleFileUpload} />
    </div>
}


const DocumentHandler = ({ developerId, header, setHeader, setHeaderValue }) => {

    const dispatch = useDispatch()

    const { whatsappAuth } = useSelector((state) => state?.developer?.whatsappAuth)

    useEffect(() => {
        dispatch(getWhatsappAuth({ developerId }))
    }, [])

    const handleFileUpload = async (e) => {

        const user_access_token = whatsappAuth.access_token
        const app_id = whatsappAuth.app_id
        const file = e.target.files[0];
        setHeaderValue({ type: "document", value: file })
        if (file.size == 0) {
            toast.error("Please Select File")
            e.target.value = ""
            return
        }

        try {
            const startResponse = await axios.post(
                `https://graph.facebook.com/v20.0/${app_id}/uploads`,
                {
                    file_name: file.name,
                    file_length: file.size,
                    file_type: file.type,
                    access_token: user_access_token
                }
            );

            const uploadSessionId = startResponse.data.id;

            const formData = new FormData();

            formData.append('file', file)

            const uploadResponse = await axios.post(`https://graph.facebook.com/v20.0/${uploadSessionId}`, formData, {
                headers: {
                    Authorization: `OAuth ${user_access_token}`,
                    'Content-Type': `multipart/form-data`,
                    'Accept': '*/*',
                    'Access-Control-Allow-Origin': 'https://secure.eazyapp.in',
                    'Access-Control-Allow-Credentials': 'true',
                }
            })

            setHeader({ type: header.type, format: header.format, example: { header_handle: [uploadResponse.data.h] } });

        } catch (err) {
            console.error('Upload Error:', err);
        }

    }
    return <div>
        <h5>Samples for header content</h5>
        <p>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</p>
        <span>Document</span> <input type="file" className="form-control" onChange={handleFileUpload} />
    </div>
}

const ExampleHandler = ({ developerId, header, setHeader, setHeaderValue }) => {


    const exampleHandler = (header_handle, header_value) => {
        console.log(header, header_handle)
        setHeader({ type: header.type, format: header.format, value: header_value, example: { header_handle: header_handle } })
        let text = header_value
        if (text !== undefined) {
            Object.keys(header_handle).map(i => {
                console.log(i, header_handle[i])
                text = text.replace(i, header_handle[i])
            })
            setHeaderValue({ type: "text", value: text })
        }
    }
    useEffect(()=>{
        if (!["None", "TEXT","LOCATION"].includes(header.format)){
            window.open("https://cors-anywhere.herokuapp.com/corsdemo"); 
        }
    },[header.format])
    switch (header.format) {
        case "None":
            return <></>
        case "TEXT":
            return <Text textExampleHandler={exampleHandler} />
        case "IMAGE":
            return <ImageHandler developerId={developerId} header={header} setHeader={setHeader} setHeaderValue={setHeaderValue} />
        case "VIDEO":
            return <VideoHandler developerId={developerId} header={header} setHeader={setHeader} setHeaderValue={setHeaderValue} />
        case "DOCUMENT":
            return <DocumentHandler developerId={developerId} header={header} setHeader={setHeader} setHeaderValue={setHeaderValue} />
        case "LOCATION":
            return <></>
    }
}

const CreateHeader = ({ developerId, whatsappDataHandler, dataToShow }) => {
    const [headerValue, setHeaderValue] = useState(null)
    const [header, setHeader] = useState({
        type: "HEADER",
        format: null,
    })


    useEffect(() => {
        if (header.format != "None") {
            whatsappDataHandler(header)
            dataToShow(headerValue)
        }
    }, [header])

    return (
        <div className='d-grid gap-4'>
            <div>
                <label className="text-capitalize">Template header</label>
                <select name="header_type" className="form-select" onChange={(e) => { setHeader({ type: header.type, format: e.target.value }) }}  >
                    <option value="None">None</option>
                    <option value="TEXT">Text</option>
                    <option value="IMAGE">Image</option>
                    <option value="VIDEO">Video</option>
                    <option value="DOCUMENT">Document</option>
                    <option value="LOCATION">Location</option>
                </select>

            </div>
            <ExampleHandler developerId={developerId} header={header} setHeader={setHeader} setHeaderValue={setHeaderValue} />
        </div>
    )
}

export default CreateHeader
