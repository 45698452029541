import React, { useEffect, useState } from "react";
import {
	Route,
	Routes,
	useLocation,
	Navigate,
	useNavigate,
	redirect,
} from "react-router-dom";
import ErrorPage from "./ErrorPage";
import LoginPage from "./Screens/AuthScreens/LoginPage";
import ChannelPartnerSignUp from "./Screens/AuthScreens/ChannelPartnerSignUp.js";
import CollateralsDownload from "./Screens/CollateralsDownload.js";
import { useSelector } from "react-redux";
import {
	CreHomePage,
	Visits,
	Teams,
	CheckInVisitors,
	AddImage,
	AssignSalesExecutive,
	Source,
	BasicInfo,
	ResidentialInfo,
	VerifyNumber,
	VerifyVisitor,
	WorkInfo,
	Requirements,
	CREProfile,
	Scanner,
	CREEditProfile,
} from "./Screens/CreScreens/CREExporter";
import Profile from "./Profile";
import {
	SEDashboard,
	VisitorDetail,
	SEEazyVisits,
	SEVisitorDetail,
	SEProfile,
	SETeams,
	SETasks,
	ChannelPartner,
	ChannelPartnerDetail,
	Report,
	SEReport,
	ProjectReport,
	CPReport,
	Inventory,
	BookUnit,
	SEEditProfile,
	GenrateReport,
	AddBookingPricing
} from "./Screens/SEScreens/SEExporter";
import {
	CPDashboard,
	CPLeads,
	CPLead,
	AddLeads,
	ProjectSearch,
	MyBucket,
	ProjectDetail,
	Colaterals,
	ManageEmployees,
	CPProfile,
	AddEmployee,
	EditEmployee,
	CPEditProfile,
} from "./Screens/CPScreens/CPExporter";
import "react-datepicker/dist/react-datepicker.css";
import {
	AdminDashboard,
	ProjectDetail as AdminProjectDetail,
	Projects,
	AdminEditConfig,
	AddConfig,
	AdminEditGallery,
	AddGalleryItem,
	EditAmenities,
	AddDocument,
	EditDocument,
	TeamManagement,
	AddNewEmployee,
	AddTeamMember,
	TeamMemeberView,
	EditTeamMember,
	DeveloperView,
	EditDeveloper,
	ChannelPartners,
	ChannelPartnerDetailView,
	ProjectsSetting,
	PaymentSetting,
	AddPaymentSchedule,
	SlabEdit,
	PricingSetting,
	AddPricingData,
	EditPricingSlab,
	OfferSetting,
	AddOffersData,
	EditOffersData,
	WingInventoryData,
	InventoryView,
	DeveloperEditEmployee,
	DeveloperCutomerBookingList,
	CustomerBookingDetail,
	AddCustomer,
	AddCustomerBookingPricing,
	ProjectWebsiteSetting,
	AutomationSetting,
	AddAutomation,
	WhatsappCreateTemplate,
	EazyVisitors,
	AdminEazyVisitor
} from "./Screens/DeveloperAdmin/exporter.js";
import AddLog from "./Components/AddLog";
import PasswordChanger from "./Screens/PasswordChanger.js";
import NotFound from "./NotFound.js";
import AddNote from "./Components/AddNote";
import AddTask from "./Components/AddTask";
import AOS from "aos";
import "aos/dist/aos.css";
import QRScanner from "./Components/QRScanner";
import { useDispatch } from "react-redux";
import { permissionsAction } from "./Actions/UserAction.js";
import {
	MyAdminDashboard,
	AdminDevelopers,
	AdminDeveloperView,
	EmployeeScreen,
	MyAdminDeveloperAddEmployee,
	AdminProjects,
	AdminProjectDetail as MyAdminProjectDetail,
	AddProject,
	AddConfiguration,
	AdminEditConfiguration,
	EditGallery,
	AddAdminGalleryItem,
	EditProjectDocument,
	AddProjectDocument,
	AdminEditAmenities,
	AdminTeamMemeberView,
	AdminAddTeamMember,
	AdminEditTeamMember,
	AddMandateCompany,
	AdminChannelPartners,
	AdminChannelPartnerDetail,
	AdminAddChannelPartner,
	AddCPEmployee,
	EditCPEmployee,
	AddSetting,
	AdminUserList,
	AdminUserDetail,
	AddAdminUser,
	AdminRoleList,
	EditSetting,
	AddDeveloper
} from "./Screens/AdminScreens/exporter.js"
import Customer from "./Screens/Customer/exporter.js"
import InactivityTimer from "./InactivityTimer";
import TestPage from "./Screens/TestPage.js";
import Map from "./Components/Map.js";
import LoadingPage from "./Screens/LoadingPage.js";
AOS.init();


Number.prototype.commaSepratedAmount=function(){
	const formatter = new Intl.NumberFormat("en-IN", {
		style: "currency",
		currency: "INR",
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
		notation: "compact",
		compactDisplay: "long",
	});

	return this.toLocaleString("en-IN", {
		maximumFractionDigits: 0,
		style: "currency",
		currency: "INR",
	})
}

Array.prototype.uniqueBy=function(key){
	var unique = this.filter((obj, index, self) =>
		index === self.findIndex((t) => (
			t[key] === obj[key]
		))
	)
	return unique
}

Array.prototype.uniqueList=function(){
	var unique = this.filter((obj, index, self) =>
		index === self.findIndex((t) => (
			t === obj
		))
	)
	return unique
}

Array.prototype.addAllItems = function () {
	try {
		let total = 0;
		for (let i = 0; i < this.length; i++) {
			if (isNaN(this[i])) {
				throw new Error('Numbers Only');
			}
			total += Number(this[i]); // Ensure the value is treated as a number
		}
		return total;
	} catch (error) {
		console.error(error.message);
		throw error; // Re-throw the error if you want it to be handled by the calling code
	}
};

Array.prototype.move = function (from, to) {
	this.splice(to, 0, this.splice(from, 1)[0]);
};

const dynamicSort=(property)=> {
	return function (a, b) {
		return (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
	}
}

Array.prototype.dynamicSort = function(property) {
	return this.sort(dynamicSort(property))
}

const MapView=()=>{
	return (
		<div style={{height:"100vh",width:"100vw"}}>

			<Map coords={[19.121126411610256, 72.93302357723992]}  />
		</div>
	)
}

const App = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state?.userState?.userInfo);
	const isLoggedIn = userInfo !== null;
	const loading = useSelector((state) => state?.loading);
	

	const GetUserBasedRoutes = (designation) => {
		switch (designation) {
			case "CRE":
				return (
					<>
						<Route
							path="/"
							element={<CreHomePage />}
							errorElement={ErrorPage}
						/>
						<Route
							path="/visit"
							element={<Visits />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/team"
							element={<Teams />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/profile"
							element={<CREProfile />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/edit-profile"
							element={<CREEditProfile />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/check-in"
							element={<CheckInVisitors />}
							errorElement={ErrorPage}
						>
							<Route path="" element={<Scanner />} errorElement={ErrorPage} />
							<Route
								path="verify-visitor"
								element={<VerifyVisitor />}
								errorElement={ErrorPage}
							/>
							<Route
								path="verify-mobile-number/:mobile_number"
								element={<VerifyNumber />}
								errorElement={ErrorPage}
							/>
							<Route
								path="basic-info"
								element={<BasicInfo />}
								errorElement={ErrorPage}
							/>
							<Route
								path="residential-info"
								element={<ResidentialInfo />}
								errorElement={ErrorPage}
							/>
							<Route
								path="work-info"
								element={<WorkInfo />}
								errorElement={ErrorPage}
							/>
							<Route
								path="requirments"
								element={<Requirements />}
								errorElement={ErrorPage}
							/>
							<Route
								path="source"
								element={<Source />}
								errorElement={ErrorPage}
							/>
							<Route
								path=":visitor_id/upload-photo/:visit_id"
								element={<AddImage />}
								errorElement={ErrorPage}
							/>
							<Route
								path=":visit_id/assign-sales-executive"
								element={<AssignSalesExecutive />}
								errorElement={ErrorPage}
							/>
						</Route>
					</>
				);
			case "Not A CRE":
				return (
					<>
						{" "}
						<Route path="/" exact element={<SEDashboard />} />
						<Route path="/inventory" exact element={<Inventory />} />
						<Route path="/bookunit/:projectId/:inventoryId/:unit" exact element={<BookUnit />} />
						<Route path="/visit/:visitId" exact element={<VisitorDetail />} />
						<Route path="/add-price-list/:projectId/:bookingId" exact element={<AddBookingPricing />} />
						<Route
							path="/visit"
							element={<SEEazyVisits />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/visitor/:visitorId"
							element={<SEVisitorDetail />}
							errorElement={ErrorPage}
						></Route>
						<Route path="/log" element={<AddLog />} errorElement={ErrorPage} />
						<Route
							path="/channel-partner"
							element={<ChannelPartner />}
							errorElement={ErrorPage}
							exact
						></Route>
						<Route
							path="/channel-partner/:channel_partner_id"
							element={<ChannelPartnerDetail />}
							errorElement={ErrorPage}
						/>
						<Route
							path="/task"
							element={<AddTask />}
							errorElement={ErrorPage}
						/>
						<Route
							path="/note"
							element={<AddNote />}
							errorElement={ErrorPage}
						/>
						<Route
							path="/profile"
							element={<SEProfile />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/edit-profile"
							element={<SEEditProfile />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/team"
							element={<SETeams />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/tasks"
							element={<SETasks />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/report"
							element={<Report />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/sales-report"
							element={<SEReport />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/project-report"
							element={<ProjectReport />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/cp-report"
							element={<CPReport />}
							errorElement={ErrorPage}
						></Route>
						<Route
							path="/genrate-report"
							element={<GenrateReport />}
							errorElement={ErrorPage}
						></Route>
					</>
				);
			case "Channel Partner":
				return (
					<>
						<Route path="/" exact element={<CPDashboard />} />
						<Route path="/leads" exact element={<CPLeads />} />
						<Route path="/addLead" exact element={<AddLeads />} />
						<Route path="/lead/:leadId" exact element={<CPLead />} />
						<Route path="/projects" exact element={<ProjectSearch />} />
						<Route path="/myBucket" exact element={<MyBucket />} />
						<Route path="/profile" exact element={<CPProfile />} />
						<Route path="/edit-profile" exact element={<CPEditProfile />} />
						<Route
							path="/manage-employee"
							exact
							element={<ManageEmployees />}
						/>
						<Route path="/add-employee" exact element={<AddEmployee />} />
						<Route
							path="/edit-employee/:employeeId"
							exact
							element={<EditEmployee />}
						/>
						<Route
							path="/project/:projectId"
							exact
							element={<ProjectDetail />}
						/>
						<Route
							path="/project/:projectId/colaterals"
							exact
							element={<Colaterals />}
						/>
					</>
				);
			case "DeveloperAdmin":
				return (
					<>
						<Route path="/" element={<AdminDashboard />} exact />
						<Route path="/projects" element={<Projects />} exact />
						<Route path="/eazy-visitors" element={<EazyVisitors />} exact />
						<Route path="/eazy-visitor/:visitorId" element={<AdminEazyVisitor />} exact />
						<Route path="/add-customer/:inventoryId/:projectId/:unitId" element={<AddCustomer />} exact />
						<Route
							path="/project-detail/:projectId"
							element={<AdminProjectDetail />}
							exact
						/>
						<Route
							path="/project/:projectId/automation/add"
							element={<AddAutomation />}
							exact
						/>
						<Route
							path="/project-automation/:projectId"
							element={<AutomationSetting  />}
							exact
						/>
						<Route
							path="/whatsapp-template-create/:developerId"
							element={<WhatsappCreateTemplate  />}
							exact
						/>
						<Route
							path="/edit-project-Website-setting/:projectId"
							element={<ProjectWebsiteSetting />}
							exact
						/>
						<Route
							path="/edit/config/:configId"
							element={<AdminEditConfig />}
							exact
						/>
						<Route
							path="/add-pricing-for-booking/:projectId/:bookingId"
							element={<AddCustomerBookingPricing />}
							exact
						/>
						<Route
							path="/add/config/:projectId"
							element={<AddConfig />}
							exact
						/>
						<Route
							path="/edit/gallery-item/:galleryId"
							element={<AdminEditGallery />}
							exact
						/>
						<Route
							path="/edit-inventory/:projectId/:inventoryId"
							element={<WingInventoryData />}
							exact
						/>
						<Route
							path="/view-inventory/:projectId/:inventoryId"
							element={<InventoryView />}
							exact
						/>
						<Route
							path="/add-new-inventory/:projectId"
							element={<WingInventoryData />}
							exact
						/>
						<Route
							path="/add/gallery-item/:projectId"
							element={<AddGalleryItem />}
							exact
						/>
						<Route
							path="/edit/amenitie-list/:projectId"
							element={<EditAmenities />}
							exact
						/>
						<Route
							path="/add/document/:projectId"
							element={<AddDocument />}
							exact
						/>
						<Route
							path="/edit/document/:documentId"
							element={<EditDocument />}
							exact
						/>
						<Route path="/employee" element={<TeamManagement />} exact />
						<Route path="/customer-bookings" element={<DeveloperCutomerBookingList />} exact />
						<Route path="/customer-booking-detail/:bookingId" element={<CustomerBookingDetail />} exact />
						<Route path="/add/employee" element={<AddNewEmployee />} exact />
						<Route path="/edit/employee/:employeeId" element={<DeveloperEditEmployee />} exact />
						<Route
							path="/add/team-member/:projectId"
							element={<AddTeamMember />}
							exact
						/>
						<Route
							path="/view/team-members/:team/:projectId"
							element={<TeamMemeberView />}
							exact
						/>
						<Route
							path="/edit/team-member/:teamMemberId/:projectId"
							element={<EditTeamMember />}
							exact
						/>
						<Route path="/developer/view" element={<DeveloperView />} exact />
						<Route path="/edit/developer" element={<EditDeveloper />} exact />
						<Route
							path="/channel-partners"
							element={<ChannelPartners />}
							exact
						/>
						<Route
							path="/channel-partner/:cpId"
							element={<ChannelPartnerDetailView />}
							exact
						/>
						<Route
							path="/project-setting"
							element={<ProjectsSetting />}
							exact
						/>
						<Route
							path="/project-payment-setting/:projectId"
							element={<PaymentSetting />}
							exact
						/>
						<Route
							path="/add-payment-schedule/:projectId"
							element={<AddPaymentSchedule />}
							exact
						/>
						<Route
							path="/edit-payment-schedule/:projectId/:scheduleId"
							element={<AddPaymentSchedule />}
							exact
						/>
						<Route
							path="/edit-payment-slab/:slabId"
							element={<SlabEdit />}
							exact
						/>
						<Route
							path="/project-pricing-setting/:projectId"
							element={<PricingSetting />}
							exact
						/>
						<Route
							path="/add-pricing-setting/:projectId"
							element={<AddPricingData />}
							exact
						/>
						<Route
							path="/edit-pricing-setting/:pricingId"
							element={<EditPricingSlab />}
							exact
						/>
						<Route
							path="/project-offer-setting/:projectId"
							element={<OfferSetting />}
							exact
						/>
						<Route
							path="/add-offer-setting/:projectId"
							element={<AddOffersData />}
							exact
						/>
						<Route
							path="/edit-offer-data/:offerId"
							element={<EditOffersData />}
							exact
						/>
					</>
				);
			case "MyAdmin":
				return (
					<>
						<Route path="/" element={<MyAdminDashboard />} exact />
						<Route path="/developers" element={<AdminDevelopers />} exact />
						<Route path="/developer-view/:developerId" element={<AdminDeveloperView />} exact />
						<Route path="/employee-view/:employeeId" element={<EmployeeScreen />} exact />
						<Route path="/employee-add/:developerId" element={<MyAdminDeveloperAddEmployee />} exact />
						<Route path="/projects" element={<AdminProjects />} exact />
						<Route path="/project-detail/:projectId" element={<MyAdminProjectDetail />} exact />
						<Route path="/add-project" element={<AddProject />} exact />
						<Route path="/add-developer" element={<AddDeveloper />} exact />
						<Route
							path="/view-inventory/:projectId/:inventoryId"
							element={<InventoryView />}
							exact
						/>
						<Route
							path="/edit-inventory/:projectId/:inventoryId"
							element={<WingInventoryData />}
							exact
						/>
						<Route
							path="/add-new-inventory/:projectId"
							element={<WingInventoryData />}
							exact
						/>
						<Route path="/add-configuration/:projectId" element={<AddConfiguration />} exact />
						<Route path="/edit-configuration/:configId" element={<AdminEditConfiguration />} exact />
						<Route path="/edit-gallery-item/:galleryId" element={<EditGallery />} exact />
						<Route path="/add-gallery-item/:projectId" element={<AddAdminGalleryItem />} exact />
						<Route path="/edit-document/:documentId" element={<EditProjectDocument />} exact />
						<Route path="/add-document/:projectId" element={<AddProjectDocument />} exact />
						<Route path="/edit-amenitie-list/:projectId" element={<AdminEditAmenities />} exact />
						<Route path="/view/team-members/:team/:projectId" element={<AdminTeamMemeberView />} exact />
						<Route path="/add/team-member/:projectId" element={<AdminAddTeamMember />} exact />
						<Route path="/edit/team-member/:teamMemberId/:projectId" element={<AdminEditTeamMember />} exact />
						<Route path="/add/mandate-company/:projectId" element={<AddMandateCompany />} exact />
						<Route path="/channel-partners" element={<AdminChannelPartners />} exact />
						<Route path="/channel-partner/:cpId" element={<AdminChannelPartnerDetail />} exact />
						<Route path="/add-channel-partner" element={<AdminAddChannelPartner />} exact />
						<Route path="/add-channel-partner-employee/:cpId" element={<AddCPEmployee />} exact />
						<Route path="/edit-channel-partner-employee/:employeeId" element={<EditCPEmployee />} exact />
						<Route path="/setting-add/:orgId" element={<AddSetting />} exact />
						<Route path="/setting-edit/:settingId" element={<EditSetting />} exact />
						<Route path="/admin-users" element={<AdminUserList />} exact />
						<Route path="/admin-user-detail/:id" element={<AdminUserDetail />} exact />
						<Route path="/add-admin-user" element={<AddAdminUser />} exact />
						<Route path="/admin-user-roles" element={<AdminRoleList />} exact />
					</>
				);
			case "IsCustomer":
				return (
					<>
						<Route path="/" element={<Customer.Dashboard />} exact />
						<Route path="/document-list" element={<Customer.DocumentList />} exact />
						<Route path="/add-document" element={<Customer.AddDocument />} exact />
						<Route path="/pricing-payment" element={<Customer.PricingPayment />} exact />
						<Route path="/payment-schedule" element={<Customer.PaymentSchedule />} exact />
						<Route path="/all-payment-list" element={<Customer.PaymentList />} exact />
						<Route path="/add-payment" element={<Customer.AddPayment />} exact />
					</>
				);
		}}
	useEffect(() => {
		if (isLoggedIn) {
			navigate(location.pathname !== "/login" ? location.pathname : "/", {
				replace: true,
			});
		} else {
			if (
				!location.pathname.includes("/channel-partner/sign-up/") &&
				!location.pathname.includes("/change-password/") &&
				!location.pathname.includes("/forgot-password/") &&
				!location.pathname.includes("/download/collaterals/") &&
				!location.pathname.includes("/test-page")
			) {
				navigate("/login", { replace: true });
			}
		}
	}, [isLoggedIn]);

	if (loading) {

		return <LoadingPage />
	}

	return (
		<>
			<InactivityTimer />
		<Routes>
			<Route
				path="/login"
				element={<LoginPage />}
				errorElement={ErrorPage}
				exact
			/>
			<Route path="*" element={<NotFound />} />
			<Route path="map-view" element={<MapView />} />
				<Route path="test-page" element={<TestPage />} />
			<Route
				path="/channel-partner/sign-up/:sourceId"
				element={<ChannelPartnerSignUp />}
				errorElement={ErrorPage}
				exact
			/>
			<Route
				path="/download/collaterals/:projectId"
				element={<CollateralsDownload />}
				errorElement={ErrorPage}
				exact
			/>
			<Route
				path="/change-password/:employeeId/"
				element={<PasswordChanger />}
				errorElement={ErrorPage}
				exact
			/>
			<Route path="/test" element={<QRScanner />} />
			{isLoggedIn &&
				GetUserBasedRoutes(
					userInfo?.extra?.is_cre
						? "CRE"
						: userInfo?.extra?.is_channel_partner
							? "Channel Partner"
							: userInfo?.extra?.is_developer_admin
								? "DeveloperAdmin"
								: userInfo?.extra?.is_my_admin ? "MyAdmin" : userInfo?.extra?.is_customer ? "IsCustomer" : "Not A CRE"
				)}
		</Routes>
		</>
	);
};

export default App;
