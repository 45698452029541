import React, { useState, useEffect } from 'react'

const CreateFooter = ({ whatsappDataHandler, dataToShow }) => {
    const [footer, setFooter] = useState({
        type: "FOOTER",
        text: null,
    })
    useEffect(()=>{
        if (footer.text == null) return 
        whatsappDataHandler(footer)
        dataToShow({type:"footer",value:footer.text})
    },[footer])
    return (
        <div>
            <input type="text" className='form-control' maxLength={15} placeholder='Enter Footer' name="value" onChange={(e) => { setFooter({ type: "FOOTER", text: e.target.value }) }} />
        </div>
    )
}

export default CreateFooter
