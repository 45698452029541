import React,{useState,useEffect} from 'react'
import ReactAutocomplete from "react-autocomplete";
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
import {
	getTeamMemberDetail,
	editTeamMemberDetail,
} from "../../../../Actions/AdminAction";
import Layout from '../../Layout/Layout';
import axios from 'axios';
import toast from 'react-hot-toast';
const AdminEditTeamMember = () => {
    const dispatch = useDispatch();
		const navigate = useNavigate();
		const { teamMemberId,projectId } = useParams();
		const [teamName, setTeamName] = useState("");
		const [desgination, setDesignation] = useState("");
		const [reportToQuery, setReportToQuery] = useState("");

		const [selectedReportTo, setSelectedReportTo] = useState({
			employeeName: "",
			employeeId: null,
		});
		const [selectedEmployee, setSelectedEmloyee] = useState({
			employeeName: "",
			teamId: null,
		});
		const [employeeList, setEmployeeList] = useState([]);
		const [reportingManagerList, setReportingManagerList] = useState([]);
		const [employeeQuery, setEmployeeQuery] = useState("");
		const [teamNameItems, setTeamNameItems] = useState([]);
		const [designationItems, setDesignationItems] = useState([]);
    const { getState } = useStore();
    const {loading,error,detail}=useSelector(state=>state?.developer?.teamDetail)

    useEffect(() => {
      if(detail !== undefined){
        setTeamName(detail?.name)
        setDesignation(detail?.designation);
        
        setSelectedEmloyee({
					employeeName: detail.employee.name,
					employeeId:detail.employee.id
				});
		if (detail?.report_to_details){

			setReportToQuery(detail?.report_to_details.name);
        setSelectedReportTo({
					employeeName: detail.report_to_details.name,
					teamId: detail.report_to_details.team_id,
				});
		}
        setEmployeeQuery(detail?.employee.name);
      }
    }, [detail]);

    useEffect(()=>{
      dispatch(getTeamMemberDetail(teamMemberId));
    },[])

		const searchHandler = (value, setResponse, api) => {
			if (value === "") {
				return () => {
					console.log("Nothing to run");
				};
			}
			return async () => {
				try {
					const {
						userState: { userInfo },
					} = getState();
					const config = {
						headers: {
							"Content-type": "application/json",
							Authorization: `Bearer ${userInfo?.token}`,
						},
					};
					const response = await axios.post(
						api,
						{ searched_term: value},
						config
					);
					setResponse(response.data);
				} catch (error) {
					toast.error("Something went wrong");
					console.log(error);
				}
			};
			// 	// Call the returned function to clear the timeout from the previous invocation

			// 	// Call the returned function to clear the timeout from the current invocation
		};

		useEffect(() => {
			const Debouncer = setTimeout(
				searchHandler(
					teamName,
					setTeamNameItems,
					"https://api.eazyapp.in/api/get/team-names/"
				),
				1000
			);
			return () => clearTimeout(Debouncer);
		}, [teamName]);
		useEffect(() => {
			const Debouncer = setTimeout(
				searchHandler(
					employeeQuery,
					setEmployeeList,
					`https://api.eazyapp.in/api/search/employees/${projectId}`
				),
				500
			);
			return () => clearTimeout(Debouncer);
		}, [employeeQuery]);
		useEffect(() => {
			const Debouncer = setTimeout(
				searchHandler(
					reportToQuery,
					setReportingManagerList,
					`https://api.eazyapp.in/api/search/reporting-manager/${projectId}`
				),
				500
			);
			return () => clearTimeout(Debouncer);
		}, [reportToQuery]);
		useEffect(() => {
			const Debouncer = setTimeout(
				searchHandler(
					desgination,
					setDesignationItems,
					"https://api.eazyapp.in/api/search/designations/"
				),
				500
			);
			return () => clearTimeout(Debouncer);
		}, [desgination]);

    const submitHandler = () => {
			const postData = {
				team: teamName,
				employee_id: selectedEmployee.employeeId,
				report_to: selectedReportTo.teamId,
				desigantion: desgination,
				project_id: projectId,
			};
      dispatch(editTeamMemberDetail(postData,teamMemberId,navigate));
		};
  return (
		<Layout pageTitle={"Edit Team Member"}>
			<div className="d-grid p-3 gap-3">
				<div>
					<label>Team</label>
					<ReactAutocomplete
						getItemValue={(item) => item.name}
						items={teamNameItems}
						renderItem={(item, isHighlighted) => (
							<div
								class={`alert m-0  mt-1 ${
									isHighlighted ? "alert-primary" : "alert-light"
								}`}
								role="alert"
							>
								{item.name}
							</div>
						)}
						value={teamName}
						menuStyle={{
							borderRadius: "3px",
							boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
							background: "rgba(255, 255, 255, 0.9)",
							fontSize: "90%", // TODO: don't cheat, let it flow to the bottom
							display: "grid",
							maxHeight: "200px",
							overflow: "scroll",
							position: "fixed",
							zIndex: 999999999999,
						}}
						inputProps={{
							className: "form-control",
							name: "team_name",
							required: true,
						}}
						wrapperProps={{ className: "w-100" }}
						onChange={(e) => {
							setTeamName(e.target.value);
						}}
						onSelect={(val, item) => {
							setTeamName(val);
						}}
					/>
				</div>
				<div>
					<label>Employee</label>
					<ReactAutocomplete
						getItemValue={(item) => item.name}
						items={employeeList}
						renderItem={(item, isHighlighted) => (
							<div className="p-3 border rounded   ">
								<div className="row">
									<div className="col-3">
										<img
											style={{
												width: "64px",
												aspectRatio: "1/1",
												objectFit: "contain",
											}}
											src={"https://api.eazyapp.in" + item.photo}
											alt=""
										/>
									</div>
									<div className="col-9">
										<p className="m-0 card_heading">{item.name}</p>
										<p className="m-0">
											<span className="text-muted">Email:</span> {item.email}
										</p>
									</div>
								</div>
							</div>
						)}
						value={employeeQuery}
						menuStyle={{
							borderRadius: "3px",
							boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
							background: "rgba(255, 255, 255, 0.9)",
							fontSize: "90%", // TODO: don't cheat, let it flow to the bottom
							display: "grid",
							maxHeight: "200px",
							overflow: "scroll",
							position: "fixed",
							zIndex: 999999999999,
						}}
						inputProps={{
							className: "form-control",
							name: "team_name",
							required: true,
						}}
						wrapperProps={{ className: "w-100" }}
						onChange={(e) => {
							setEmployeeQuery(e.target.value);
							setSelectedEmloyee({
								employeeId: "",
								employeeName: e.target.value,
							});
						}}
						onSelect={(val, item) => {
							setEmployeeQuery(val);
							setSelectedEmloyee({
								employeeId: item.id,
								employeeName: item.name,
							});
						}}
					/>
				</div>
				<div>
					<label>Reporting To</label>
					<ReactAutocomplete
						getItemValue={(item) => item.employee.name}
						items={reportingManagerList}
						renderItem={(item, isHighlighted) => (
							<div className="p-3 border rounded   ">
								<div className="row">
									<div className="col-3">
										<img
											style={{
												width: "64px",
												aspectRatio: "1/1",
												objectFit: "contain",
											}}
											src={"https://api.eazyapp.in" + item.employee.photo}
											alt=""
										/>
									</div>
									<div className="col-9">
										<p className="m-0 card_heading">{item.employee.name}</p>
										<p className="m-0">
											<span className="text-muted">Designation:</span>
											{item.desgination}
										</p>
										<p className="m-0">
											<span className="text-muted">Email:</span>{" "}
											{item.employee.email}
										</p>
									</div>
								</div>
							</div>
						)}
						value={reportToQuery}
						menuStyle={{
							borderRadius: "3px",
							boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
							background: "rgba(255, 255, 255, 0.9)",
							fontSize: "90%", // TODO: don't cheat, let it flow to the bottom
							display: "grid",
							maxHeight: "200px",
							overflow: "scroll",
							position: "fixed",
							zIndex: 999999999999,
						}}
						inputProps={{
							className: "form-control",
							name: "team_name",
							required: true,
						}}
						wrapperProps={{ className: "w-100" }}
						onChange={(e) => {
							setReportToQuery(e.target.value);
							setSelectedReportTo({
								teamId: "",
								employeeName: e.target.value,
							});
						}}
						onSelect={(val, item) => {
							setReportToQuery(val);
							setSelectedReportTo({
								teamId: item.id,
								employeeName: item.employee.name,
							});
						}}
					/>
				</div>
				<div>
					<label>Designation</label>
					<ReactAutocomplete
						getItemValue={(item) => item.name}
						items={designationItems}
						renderItem={(item, isHighlighted) => (
							<div
								class={`alert m-0  mt-1 ${
									isHighlighted ? "alert-primary" : "alert-light"
								}`}
								role="alert"
							>
								{item.name}
							</div>
						)}
						value={desgination}
						menuStyle={{
							borderRadius: "3px",
							boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
							background: "rgba(255, 255, 255, 0.9)",
							fontSize: "90%", // TODO: don't cheat, let it flow to the bottom
							display: "grid",
							maxHeight: "200px",
							overflow: "scroll",
							position: "fixed",
							zIndex: 999999999999,
						}}
						inputProps={{
							className: "form-control",
							name: "team_name",
							required: true,
						}}
						wrapperProps={{ className: "w-100" }}
						onChange={(e) => {
							setDesignation(e.target.value);
						}}
						onSelect={(val, item) => {
							setDesignation(val);
						}}
					/>
				</div>
				<div className="text-end">
					<button className="btn btn-success" onClick={submitHandler}>
						Save
					</button>
				  <button
					  className="btn btn-dark"
					  type='button'
					  onClick={() => {
						  navigate(-1);
					  }}
				  >
					  Cancel
				  </button>
				</div>
			</div>
		</Layout>
	);
}

export default AdminEditTeamMember
