import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { addLogAction,editLogAction } from "../Actions/CommonAction";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
const AddLog = (props) => {
	// const params = useParams();
	const location = useLocation();
	const { state } = location;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [comment, setComment] = useState("");
	const [callOutcome, setCallOutcome] = useState("");
	const [calledOn, setCalledOn] = useState("");
	const [createTask, setCreateTask] = useState(false);
	const [followUpDate, setFollowUpDate] = useState(
		moment().add(3, "days").format("YYYY-MM-DD")
	);
	const [getNewFollowUpdate, setGetNewFollowupDate] = useState(false);
	const [logType, setLogType] = useState(null);

	useEffect(() => {
		if (state?.logType !== null && state?.logType !== undefined) {
			setLogType(state.logType);
		}
		if (state?.log?.comment !== null && state?.log?.comment !== undefined) {
			setComment(state?.log?.comment);
		}
		if(state?.log?.call_outcome !== null && state?.log?.call_outcome !== undefined){
			setCallOutcome(state?.log?.call_outcome);
		}
		if(state?.log?.called_on !== null && state?.log?.called_on !== undefined){
			setCalledOn(state?.log?.called_on);
		}
	}, []);

	const addLogHandler = (e) => {
		e.preventDefault();
		var formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		if (state?.log?.id === undefined){
			dispatch(addLogAction(object, navigate));
		}else{
			dispatch(editLogAction(object, navigate));
		}
	};

	if (getNewFollowUpdate) {
		return (
			<>
				<div className="main_container m-0 p-0">
					<div className="w-100">
						<button
							className="btn ms-0"
							onClick={() => {
								setGetNewFollowupDate(false);
							}}
						>
							<Icon.ArrowLeft />
						</button>
					</div>
					<div className="row ">
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(1, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(moment().add(1, "days").format("YYYY-MM-DD"));
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">Tomorrow</p>
								<p className=" fs-6 text-muted">
									{moment().add(1, "days").format("dddd")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(2, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(moment().add(2, "days").format("YYYY-MM-DD"));
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">In 2 Days</p>
								<p className=" fs-6 text-muted">
									{moment().add(2, "days").format("dddd")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(3, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(moment().add(3, "days").format("YYYY-MM-DD"));
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">In 3 Days</p>
								<p className=" fs-6 text-muted">
									{moment().add(3, "days").format("dddd")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(7, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(moment().add(7, "days").format("YYYY-MM-DD"));
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">1 Week</p>
								<p className=" fs-6 text-muted">
									{moment().add(7, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(14, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(14, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">2 Week</p>
								<p className=" fs-6 text-muted">
									{moment().add(14, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(30, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(30, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">1 Month</p>
								<p className=" fs-6 text-muted">
									{moment().add(30, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(90, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(90, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">3 Month</p>
								<p className=" fs-6 text-muted">
									{moment().add(90, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-6 p-3">
							<button
								className={`btn border w-100 border-primary ${
									moment().add(180, "days").format("YYYY-MM-DD") ===
										followUpDate && "border-3"
								}`}
								onClick={(e) => {
									setFollowUpDate(
										moment().add(180, "days").format("YYYY-MM-DD")
									);
									setGetNewFollowupDate(false);
								}}
							>
								<p className="fw-bold fs-5">6 Month</p>
								<p className=" fs-6 text-muted">
									{moment().add(180, "days").format(" MMMM Do, YYYY")}
								</p>
							</button>
						</div>
						<div className="col-12">
							{/* <button
								className={`btn border w-100 border-primary ${moment().add(180, "days").format(
									"YYYY-MM-DD"
								) === followUpDate && "border-3"}`}
								value={moment().add(180, "days").format("YYYY-MM-DD")}
								onClick={(e) => {
									setFollowUpDate(e.target.value);
									setGetNewFollowupDate(false);
								}}
							></button> */}
						</div>
					</div>
				</div>
			</>
		);
	}

	if (logType === null) {
		return (
			<div className="vh-100">
				<div className="mx-1 border-bottom">
					<h3 className="">
						{" "}
						<button className="btn fs-5" onClick={() => navigate(-1)}>
							<Icon.ArrowLeft />
						</button>{" "}
						Add Log{" "}
					</h3>
				</div>
				<div className="h-92 px-3">
					<div className="pxd-flex align-items-start pt-5 h-100 justify-content-center">
						<div className="d-grid gap-3 mt-5 mx-5 w-80 align-items-center">
							<button
								className="btn btn-outline-primary"
								onClick={() => {
									setLogType("Call");
								}}
							>
								Call
							</button>

							<button
								className="btn btn-outline-primary"
								onClick={() => {
									setLogType("Email");
								}}
							>
								Email
							</button>
							<button
								className="btn btn-outline-primary"
								onClick={() => {
									setLogType("Message");
								}}
							>
								Message
							</button>
							<button
								className="btn btn-outline-primary"
								onClick={() => {
									setLogType("WhatsApp Message");
								}}
							>
								WhatsApp Message
							</button>
							<button
								className="btn btn-outline-primary"
								onClick={() => {
									setLogType("Feedback");
								}}
							>
								Feedback
							</button>
							<button
								className="btn btn-outline-primary"
								onClick={() => {
									setLogType("Meeting");
								}}
							>
								Meeting
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="vh-100">
			<div className="mx-1 border-bottom">
				<h3 className="">
					{" "}
					<button className="btn fs-5" onClick={() => navigate(-1)}>
						<Icon.ArrowLeft />
					</button>{" "}
					Add Log{" "}
				</h3>
			</div>
			<div className="h-93">
				<div className="px-3 d-flex align-items-start pt-5 h-100 justify-content-center ">
					<form
						action=""
						className="w-80 d-grid gap-3"
						onSubmit={addLogHandler}
					>
						{state?.log?.id !== undefined && (
							<input type="text" name="log_id" value={state?.log?.id} hidden />
						)}
						<input
							type="text"
							name="visitor_id"
							value={state.visitorId}
							hidden
						/>
						<input type="text" name="log_type" value={logType} hidden />
						<div className="">
							<label className=" fs-8 text-muted bg-white ">
								Describe the {logType} :
							</label>
							<textarea
								className="form-control"
								value={comment}
								onChange={(e) => {
									setComment(e.target.value);
								}}
								name="comment"
								cols="20"
								rows="4"
								required
							></textarea>
						</div>
						{logType === "Call" && (
							<>
								<div className="row">
									<div className="col-6">
										<label className=" fs-8 text-muted bg-white ">
											Call Outcome:
										</label>
										<select
											name="call_outcome"
											className="form-select"
											value={callOutcome}
											onChange={(e) => setCallOutcome(e.target.value)}
										>
											<option value="Connected">Connected</option>
											<option value="Busy">Busy</option>
											<option value="Left live message">
												Left live message
											</option>
											<option value="Left voicemail">Left voicemail</option>
											<option value="No Answer">No Answer</option>
											<option value="Out of Reach">Out of Reach</option>
											<option value="Switch off">Switch off</option>
										</select>
									</div>
									<div className="col-6">
										<label className=" fs-8 text-muted bg-white ">
											Called on
										</label>
										<input
											type="datetime-local"
											className="form-control"
											name="called_on"
											value={moment(calledOn).format("YYYY-MM-DDTHH:mm")}
											onChange={(e) => setCalledOn(e.target.value)}
										/>
									</div>
								</div>
							</>
						)}
						{state?.log === undefined && (
							<div className="d-flex justify-content-end">
								<Form.Check
									type="switch"
									checked={createTask}
									id="custom-switch"
									label="Follow up"
									onChange={(e) => {
										setCreateTask(e.target.checked);
									}}
								/>
							</div>
						)}
						{createTask && (
							<div>
								{/* <hr /> */}
								<input type="text" value="True" name="add_task" hidden />
								<input
									type="text"
									name="follow_up_date"
									value={moment(followUpDate).format("YYYY-MM-DD")}
									hidden
								/>
								<input type="text" name="task_type" value={"Todo"} hidden />
								<input
									type="text"
									name="task_description"
									value="Follow up for Note"
									hidden
								/>
								<button
									type="button"
									className="btn p-0  text-start w-100"
									onClick={() => {
										setGetNewFollowupDate(true);
									}}
								>
									<div className="row mx-3">
										<div className="col-9">
											<p
												className="text-muted p-0 m-0"
												style={{ fontSize: "x-small" }}
											>
												Follow up date :
											</p>
											<p className="fw-bold p-0 m-0">
												{moment(followUpDate).format("ddd, MMMM Do, YYYY")}
											</p>
										</div>
										<div className="col-3 d-flex align-items-center justify-content-end">
											<Icon.ArrowRight />
										</div>
									</div>
								</button>
								{/* <hr /> */}
							</div>
						)}
						<div className="text-center">
							<button className="btn btn-warning">
								{state?.log === undefined ? "Add" : "Edit"} Log
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddLog;
