import React from 'react'
import Loading from '../Components/Loading';
const LoadingPage = () => {
  return (
    <div className='vh-100 w-100 d-grid place-items-center '>
        <Loading />
    </div>
  )
}

export default LoadingPage;
