import axios from "axios";
import toast from "react-hot-toast";

const LOCAL_API_URL = "https://api.eazyapp.in/api/";
const HOSTED_API_URL = "https://thenestiny.com/api/v1/";
const CURRENT_API_URL = LOCAL_API_URL;
// const CURRENT_API_URL = LOCAL_API_URL;
const instance = axios.create({
	baseURL: CURRENT_API_URL,
});
instance.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		
		if (error?.message == "Network Error") {
			toast.error("Network error");
			return;
		}else{
			toast.error("Something went wrong. Please try again later.");
		}
		return Promise.reject(error);
	}
);

export const isObject=(objValue) =>{
	return (
		objValue && typeof objValue === "object" && objValue.constructor === Object
	);
}

export { CURRENT_API_URL };
export default instance;
