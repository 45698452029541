import React, { useEffect, useState } from 'react'
import Layout from '../../Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getPricingDetails, updatePricingDetails, updateProjectDetail } from '../../../../Actions/AdminAction'
const EditPricingSlab = () => {
  const { pricingId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [key, setkey] = useState();
  const [value, setValue] = useState();
  const [inPercentage, setInPercentage] = useState(false)

  const { loading, error, pricingDetail } = useSelector(state => state?.developer?.pricingDetail)


  const submitHandler = () => {
    const postData = {
      key: key,
      value: value,
      in_percantage: inPercentage
    }
    dispatch(updatePricingDetails(postData, pricingId, navigate))
  }

  useEffect(() => {
    if (pricingDetail) {
      setkey(pricingDetail.key)
      setValue(pricingDetail.value)
      setInPercentage(pricingDetail.in_percantage)
    }
  }, [pricingDetail])

  useEffect(() => {
    dispatch(getPricingDetails(pricingId))
  }, [])

  return (
    <Layout pageTitle={"Edit Pricing Detail"}>
      <div className="d-grid p-2 gap-2">
        <div>
          <label>Label</label>
          <input
            type="text"
            value={key}
            onChange={(e) => {
              setkey(e.target.value)
            }}
            name="key"
            className="form-control"
          />
        </div>
        <div>
          <label>Value</label>
          <input
            type="number"
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
            }}
            name="value"
            className="form-control"
          />
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            checked={inPercentage}
            onChange={(e) => {
              setInPercentage(e.target.checked)
            }}
            id="flexCheckDefault"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Value is in Percantage
          </label>
        </div>
        <div className="col-12 sticky-bottom bg-white py-3 d-flex justify-content-end gap-3">
          <button className='btn btn-success ' onClick={submitHandler} >Save</button>
          <button className="btn btn-dark" type="button" onClick={()=>navigate(-1)}>Cancel</button>
        </div>
      </div>
    </Layout>
  )
}



export default EditPricingSlab
