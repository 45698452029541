export const CUSTOMER_GET_MY_BOOKINGS_REQUEST = "CUSTOMER_GET_MY_BOOKINGS_REQUEST"
export const CUSTOMER_GET_MY_BOOKINGS_SUCCESS = "CUSTOMER_GET_MY_BOOKINGS_SUCCESS"
export const CUSTOMER_GET_MY_BOOKINGS_FAIL = "CUSTOMER_GET_MY_BOOKINGS_FAIL"
export const UPDATE_CURRENT_BOOKING_ID = "UPDATE_CURRENT_BOOKING_ID"



export const GET_CUSTOMER_DASHBOARD_DATA_REQUEST = 'GET_CUSTOMER_DASHBOARD_DATA_REQUEST'
export const GET_CUSTOMER_DASHBOARD_DATA_SUCCESS = 'GET_CUSTOMER_DASHBOARD_DATA_SUCCESS'
export const GET_CUSTOMER_DASHBOARD_DATA_FAIL = 'GET_CUSTOMER_DASHBOARD_DATA_FAIL'


export const GET_CUSTOMER_PROJECT_DOCUMNETS_REQUEST = 'GET_CUSTOMER_PROJECT_DOCUMNETS_REQUEST'
export const GET_CUSTOMER_PROJECT_DOCUMNETS_SUCCESS = 'GET_CUSTOMER_PROJECT_DOCUMNETS_SUCCESS'
export const GET_CUSTOMER_PROJECT_DOCUMNETS_FAIL = 'GET_CUSTOMER_PROJECT_DOCUMNETS_FAIL'


export const GET_CUSTOMER_PRICING_PAYMENTS_REQUEST = 'GET_CUSTOMER_PRICING_PAYMENTS_REQUEST'
export const GET_CUSTOMER_PRICING_PAYMENTS_SUCCESS = 'GET_CUSTOMER_PRICING_PAYMENTS_SUCCESS'
export const GET_CUSTOMER_PRICING_PAYMENTS_FAIL = 'GET_CUSTOMER_PRICING_PAYMENTS_FAIL'


export const GET_CUSTOMER_PAYMENT_SCHEDULE_REQUEST = 'GET_CUSTOMER_PAYMENT_SCHEDULE_REQUEST'
export const GET_CUSTOMER_PAYMENT_SCHEDULE_SUCCESS = 'GET_CUSTOMER_PAYMENT_SCHEDULE_SUCCESS'
export const GET_CUSTOMER_PAYMENT_SCHEDULE_FAIL = 'GET_CUSTOMER_PAYMENT_SCHEDULE_FAIL'



export const GET_ALL_CUSTOMER_PAYMENT_LIST_REQUEST = 'GET_ALL_CUSTOMER_PAYMENT_LIST_REQUEST'
export const GET_ALL_CUSTOMER_PAYMENT_LIST_SUCCESS = 'GET_ALL_CUSTOMER_PAYMENT_LIST_SUCCESS'
export const GET_ALL_CUSTOMER_PAYMENT_LIST_FAIL = 'GET_ALL_CUSTOMER_PAYMENT_LIST_FAIL'



export const ADD_CUSTOMER_DOCUMENT_REQUEST = 'ADD_CUSTOMER_DOCUMENT_REQUEST'
export const ADD_CUSTOMER_DOCUMENT_SUCCESS = 'ADD_CUSTOMER_DOCUMENT_SUCCESS'
export const ADD_CUSTOMER_DOCUMENT_FAIL = 'ADD_CUSTOMER_DOCUMENT_FAIL'

export const ADD_CUSTOMER_PAYMENT_REQUEST = 'ADD_CUSTOMER_PAYMENT_REQUEST'
export const ADD_CUSTOMER_PAYMENT_SUCCESS = 'ADD_CUSTOMER_PAYMENT_SUCCESS'
export const ADD_CUSTOMER_PAYMENT_FAIL = 'ADD_CUSTOMER_PAYMENT_FAIL'