import React,{useEffect,useState} from 'react'
import Layout from './Layout/Layout'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getCustomerBookingList } from '../../Actions/AdminAction'



const DeveloperCutomerBookingList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { loading, error, customerList }=useSelector(state=>state?.developer?.customer.list)

    useEffect(()=>{
        dispatch(getCustomerBookingList())
    },[])


  return (
    <Layout pageTitle={"Customer List"}>
        <div className="d-grid gap-2 p-2">
            {customerList?.map(item=>{
                return (
                    <>
                        <div className="d-flex gap-2  rounded border p-2 position-realtive">
                            <img src={item.customer?.photo} alt="" style={{height:"150px",width:"150px"}} />
                            <div className="p-2">
                                <h3 className='fw-bold' >{item.customer?.first_name} {item.customer?.last_name}</h3>
                                <p>{item.customer?.email}</p>
                                <div className="d-flex  flex-wrap gap-1">
                                    <span style={{width:"49%"}}  >Project: <span className='fw-bold' > {item?.project}  </span></span>
                                    <span style={{width:"49%"}}  >Configuration: <span className='fw-bold' > {item?.config}</span></span>
                                    <span style={{width:"49%"}}  >Wing: <span className='fw-bold' > {item?.wing}</span></span>
                                    <span style={{width:"49%"}}  >Unit: <span className='fw-bold' > {item?.unit}</span></span>
                                </div>
                                <div className="text-end">
                                    <button className="btn btn-primary"  onClick={()=>{navigate(`/customer-booking-detail/${item.id}`)}} >View</button>
                                </div>
                            </div>
                        </div>
                    </>
                )
            })}
        </div>
    </Layout>
  )
}

export default DeveloperCutomerBookingList
