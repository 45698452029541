import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { Navbar, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { userLogout } from "../../../Actions/UserAction";
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Header = ({ pageTitle }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const showCheckInbutton = () => {
		if (
			location.pathname == "/" ||
			location.pathname == "/team" ||
			location.pathname == "/visit"
		) {
			return true;
		}
		return false;
	};
	const user = useSelector((state) => state?.userState?.userInfo);
	return (
		<div>
			<Navbar
				collapseOnSelect
				expand="lg"
				// bg="white"
				variant="white"
				sticky="top"
				className="bg-accent mb-0"
			>
				<Navbar.Brand className="ms-3 text-white">{pageTitle}</Navbar.Brand>
				<button
					className="btn text-white fs-4 me-3 "
					onClick={() => {
						dispatch(userLogout());
					}}
				>
					<FontAwesomeIcon icon={icon.faPowerOff} />
				</button>
			</Navbar>
			{showCheckInbutton() && (
				<div className="px-4 bg-accent py-2">
					<button
						className="btn btn-warning w-100 "
						onClick={() => {
							navigate("/check-in");
						}}
					>
						Check-In Visitor
					</button>
				</div>
			)}
		</div>
	);
};

export default Header;
