import React from 'react'
import Layout from '../Layout/Layout'
import ImageUploader from '../../../Components/ImageUploader'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addDeveloper } from '../../../Actions/MyAdminAction'
const AddDeveloper = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitHandler = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);
        dispatch(addDeveloper(formData,navigate))
    }
  return (
    <Layout pageTitle={"Add Developer"}>
          <div className='d-grid p-3 gap-3 position-relative '>
              <form onSubmit={submitHandler} >
                  <div className="d-flex justify-content-center">
                      <div className="w-30">
                          <ImageUploader dbImage={null} />
                      </div>
                  </div>
                  <div>
                      <label className='fw-bold'>Developer Name</label>
                      <input type="text" className='form-control' name="name" />
                  </div>
                  <div>
                      <label className='fw-bold'>About Developer</label>
                      <textarea className='form-control' name="about"  />
                  </div>
                  <div>
                      <label className='fw-bold'>Established Year</label>
                      <input type="text" className='form-control' name="established_year"  />
                  </div>
                  <div>
                      <label className='fw-bold'>Contact Email</label>
                      <input type="text" className='form-control' name="contact_email"  />
                  </div>
                  <div>
                      <label className='fw-bold'>Contact Number</label>
                      <input type="text" className='form-control' name="contact_number" />
                  </div>
                  <div className="text-end bg-white sticky-bottom py-1">
                      <button className="btn btn-success" type='submit'>Save</button>
                  </div>
              </form>
          </div>
    </Layout>
  )
}

export default AddDeveloper
