import React, { useState, useEffect } from "react";
import Layout from "./Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getVisitors } from "../../Actions/AdminAction";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import * as Icon from "react-bootstrap-icons";
import User from "../../assets/user.png";
import { useNavigate } from "react-router-dom";

const EazyVisitors = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [dataCount, setDataCount] = useState(20);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { error, loading, visitors } = useSelector(
    (state) => state?.developer?.visitorData?.list
  );

  const textColor = {
    warm: "text-light rounded-5 ms-3 py-1 fs-9   px-2  border border-1 border-warning opacity-75 bg-warning",
    cold: "text-light rounded-5 ms-3 py-1 fs-9   px-2  border border-1 border-info opacity-75 bg-info",
    hot: "text-light rounded-5  ms-3 py-1 fs-9  px-2 border border-1 border-danger opacity-75 bg-danger",
    lost: "text-light rounded-5 ms-3 py-1 fs-9   px-2  border border-1 border-primary opacity-75 bg-primary",
    new: "text-light  rounded-5 ms-3 py-1 fs-9   px-2  border border-1 border-success opacity-75 bg-success ",
  };

  useEffect(() => {
    if (visitors !== undefined) {
      setData(visitors?.visitor);
      setPage(visitors?.page);
      setDataCount(visitors?.number_of_visitor);
      setHasMore(visitors?.next_page);
    }
  }, [visitors]);

  useEffect(() => {
    dispatch(getVisitors());
  }, []);

  return (
    <Layout pageTitle={"Eazy Visitors"}>
      <div
        id="scrollableDiv"
        className=" p-0 h-100"
        style={{ overflow: "scroll" }}
      >
        <InfiniteScroll
          dataLength={dataCount}
          next={() => dispatch(getVisitors(page + 1))}
          className="row gap-3 px-3 px-md-1 w-100"
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {data.length === 0 && (
            <div className="col-lg-5 shadow border rounded p-3">
              <h1 className="my-5 text-center">Visitors Not Available</h1>
            </div>
          )}
          {data.map((d, index) => {
            return (
              <div className="col-lg-5 shadow border rounded p-3">
                <div className="row">
                  <div
                    className="col-4 d-flex align-items-center justify-content-center "
                    onClick={() => navigate(`/eazy-visitor/${d.id}`)}
                  >
                    {d.image !== null ? (
                      <img
                        src={"https://api.eazyapp.in" + d.image}
                        alt=""
                        className="img-fluid  img-thumbnail "
                        style={{ height: "100px", width: "auto" }}
                      />
                    ) : (
                      <img
                        src={User}
                        alt=""
                        className="img-fluid  img-thumbnail "
                        style={{ height: "100%", width: "auto" }}
                      />
                    )}
                  </div>
                  <div
                    className="col-8 "
                    onClick={() => navigate(`/eazy-visitor/${d.id}`)}
                  >
                    <h5 className="card_heading  m-0">
                      {d.first_name} {d.last_name}
                      <span
                        style={{ minWidth: "60px" }}
                        className={textColor[d.status.toLowerCase()]}
                      >
                        {d.status}
                      </span>
                    </h5>
                    <p className="m-0">
                      <span className="text-muted fs-6">Visits: </span>
                      {d.visit_count}
                    </p>
                    <p className="m-0 text-capitalize">
                      <span className="text-muted fs-6">Executive: </span>
                      {d.employee?.name}
                    </p>
                    <p className="m-0">
                      <span className="text-muted fs-6">Last Visit: </span>
                      {d.last_visit !== "Checking In"
                        ? moment(d.last_visit).fromNow()
                        : d.last_visit}
                    </p>
                    <div className=" d-flex justify-content-between px-1 border-top mt-2 ">
                      <a
                        href={`tel:+${d.mobile_number}`}
                        className="fs-4 fw-bold text-primary "
                      >
                        <Icon.Telephone />
                      </a>
                      <a
                        href={`sms:+${d.mobile_number}`}
                        className="fs-4 fw-bold text-secondary "
                      >
                        <Icon.Chat />
                      </a>
                      <a
                        href={`http://wa.me/+${d.whatsapp_number}?text=Hi`}
                        className="fs-4 fw-bold text-success "
                      >
                        <Icon.Whatsapp />
                      </a>
                      <a
                        href={`mailto:${d.email}`}
                        className="fs-4 fw-bold text-danger "
                      >
                        <Icon.EnvelopeAt />
                      </a>
                    </div>
                  </div>
                  <div className="col-12 "></div>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </Layout>
  );
};

export default EazyVisitors;
