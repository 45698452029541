import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./sidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import { getVisitorStatus } from "../../../Actions/SEAction";
import { userLogout, getUserData } from "../../../Actions/UserAction";
import useWebSocket, { ReadyState } from "react-use-websocket";
import toast from "react-hot-toast"
import Offcanvas from "react-bootstrap/Offcanvas";
import Alert from "../../../assets/alert.gif"
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { permissionsAction } from "../../../Actions/UserAction";

const Layout = ({ children, pageTitle }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userInfo = useSelector((state) => state?.userState?.userInfo);
	const isLoggedIn = userInfo !== null;

	useEffect(() => {
		if (isLoggedIn) {
			dispatch(permissionsAction())
		}
	}, [isLoggedIn])
	// useEffect(()=>{
	// 	dispatch(getUserData())
	// },[])
	const location = useLocation();
	const [showSidebar, setShowSidebar] = useState(false);
	const isMobileView = window.innerWidth <= 712;
	const deivceHeight = window.innerHeight;
	const showBottomBar = () => {
		if (location.pathname == "/") {
			return true;
		}
		const conditionRouteList = ["/projects", "/employee","/channel-partner","/developer"];
		return conditionRouteList.some((element) =>
			location.pathname.includes(element)
		);
	};
	return (
		<>
			<div className="sidebar_outer_container d-flex w-100">
				<Sidebar />
				<div className="sidebar_right_container p-0 pt-lg-2 pb-lg-2 pe-lg-2   ">
					<div
						className="sidebar_right_inner_container w-100 "
						// style={{
						// 	height: `${isMobileView ? deivceHeight : deivceHeight - 15}px`,
						// 	overflow: "hidden",
						// }}
					>
						<div
							className="right_screen w-100 "
							// style={{
							// 	height: `${isMobileView ? deivceHeight : deivceHeight - 15}px`,
							// 	overflow: "hidden",
							// }}
						>
							<Header pageTitle={pageTitle} />
							<div
								className="right_inner_screen w-100  "
								// style={{
								// 	height: `${
								// 		isMobileView ? deivceHeight - 130 : deivceHeight - 70
								// 	}px `,
								// 	overflowX: "hidden",
								// }}
							>
								{children}
								{/* <Outlet/> */}
								{isMobileView && showBottomBar() ? (
									<div className=" d-lg-none d-block  w-100 position-fixed  bottom-0 z-index-10 ">
										<div className="row m-0 p-0 bg-accent pb-2 text-center ">
											<div className="col mx-1 p-0">
												<Link
													className={`btn rounded-top-0  mt-0 pt-0 rounded-bottom-circle ${
														location.pathname == "/"
															? "btn-light"
															: "bg-accent text-light"
													} w-100 py-3 px-1`}
													to="/"
													activeClassName="active"
												>
													<span style={{ fontSize: "medium" }}>
														<Icon.HouseFill />
														<br />
														<span className="ms-2">Home</span>
													</span>
												</Link>
											</div>
											<div className="col mx-1 p-0">
												<Link
													to="/projects"
													className={`btn rounded-top-0  mt-0 pt-0 rounded-bottom-circle ${
														location.pathname == "/projects"
															? "btn-light"
															: "bg-accent text-light"
													} w-100 py-3 px-1`}
													activeClassName="active"
												>
													<span style={{ fontSize: "medium" }}>
														<Icon.BuildingAdd />
														<br />
														<span className="ms-2">Projects</span>
													</span>
												</Link>
											</div>
											<div className="col mx-1 p-0">
												<Link
													className={`btn rounded-top-0  mt-0 pt-0 rounded-bottom-circle ${
														location.pathname == "/employee"
															? "btn-light"
															: "bg-accent text-light"
													} w-100 py-3 px-1`}
													to="/employee"
													activeClassName="active"
												>
													<span style={{ fontSize: "medium" }}>
														{" "}
														<Icon.People />
														<br />
														<span className="ms-2">Employee</span>
													</span>
												</Link>
											</div>

											<div className="col mx-1 p-0">
												<button
													className={`btn rounded-top-0  mt-0 fs-1 pt-0 rounded-bottom-circle ${
														showSidebar ? "btn-light" : "bg-accent text-light"
													}  w-100 py-3 px-1`}
													onClick={() => setShowSidebar(true)}
												>
													<span classname="display-1">
														{showSidebar ? <Icon.XLg /> : <Icon.FilterRight />}
													</span>
												</button>
											</div>
										</div>
									</div>
								) : (
									isMobileView && (
										<>
											{/* <div
											className="bg-white w-100 position-fixed  bottom-0 z-index-10 "
											style={{ height: "66px" }}
										></div> */}
										</>
									)
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<Offcanvas
				className={"bg-light w-60"}
				scroll={false}
				backdrop={false}
				placement={"end"}
				show={showSidebar}
				onHide={() => setShowSidebar(false)}
			>
				<Offcanvas.Header closeVariant={"dark"} closeButton>
					<Offcanvas.Title>Pages</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body className="d-grid p-0 pe-1 py-3">
					<div class="list-group  h-100  m-0 rounded-0 p-0 w-100">
						<Link
							to="/channel-partners"
							className={`list-group-item rounded-end-4 list-group-item-action ${
								location.pathname == "/channel-partners" && "active"
							}  py-3 `}
							activeClassName="active"
						>
							<span style={{ fontSize: "medium" }}>
								<Icon.Person />
								<span className="ms-2">Search Projects</span>
							</span>
						</Link>
						<Link
							to="/developer/view"
							className={`list-group-item rounded-end-4 list-group-item-action ${
								location.pathname == "/developer/view" && "active"
							}  py-3 `}
							activeClassName="active"
						>
							<span style={{ fontSize: "medium" }}>
								<Icon.PersonCircle />
								<span className="ms-2">About</span>
							</span>
						</Link>
					</div>
					{/* <button className="btn btn-dark " onClick={()=>dispatch(change_dashboard( ""))} >Switch to Sales</button> */}
					<button
						className="bg-danger   mt-auto text-white px-3 py-3 list-group-item rounded-end-4 list-group-item-action"
						onClick={() => dispatch(userLogout())}
					>
						<Icon.BoxArrowLeft /> Logout
					</button>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
};

export default Layout;
