import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import { getVisitsDataAction } from "../../Actions/CREAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const Visits = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [visitsData, setVisits] = useState([]);

	const { loading, error, creVisitsData } = useSelector(
		(state) => state?.cre?.visitsData
	);

	useEffect(() => {
		if (creVisitsData !== undefined) {
			setVisits(
				creVisitsData?.map((d) => {
					return {
						id: d.id,
						img: d.img,
						name: d.name,
						checkInTime: d.check_in_time,
						assigned: d.sales_executive !== null,
						assignedTo: d.sales_executive,
						fromSource: false,
						source: d.source,
					};
				})
			);
		}
	}, [creVisitsData]);

	useEffect(() => {
		dispatch(getVisitsDataAction());
	}, []);

	return (
		<Layout pageTitle={"Visits"}>
			<div className="main_container">
				<div className="row w-100 gap-3">
					{visitsData.length === 0 && (
						<div className="col-lg-5 shadow border rounded p-3">
							<h1 className="my-5 text-center">Visitors Not Available</h1>
						</div>
					)}
					{visitsData.map((d) => {
						return (
							<div className="col-md-5  border rounded p-2 shadow">
								<div className="d-flex ">
									{/* <div
										className="col-3 d-flex align-items-center justify-content-center"
										style={{ height: "100px", width: "100px" }}
									>
									</div> */}
										<img
											src={"https://api.eazyapp.in" + d.img}
											alt=""
											className="me-3  img-thumbnail"
											style={{ height: "100px", width: "auto" }}
										/>
									<div className="py-1 ">
										<h4 className="p-0 m-0 card_heading">{d.name}</h4>
										<p className="p-0 m-0 text-muted ">
											<label>Check-In:</label>{moment(d.checkInTime).format("hh:mm a")}
										</p>

										{d.fromSource && (
											<p className="p-0 m-0">
												<span>Channel partner:</span> {d.source}
											</p>
										)}
										{d.assigned ? (
											<p className="m-0 p-0"> Assigned to: {d.assignedTo}</p>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</Layout>
	);
};

export default Visits;
