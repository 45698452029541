import React,{useEffect, useState} from 'react'
import { useParams ,useNavigate} from 'react-router-dom' 
import { useDispatch , useSelector } from 'react-redux'
import {
	getAvailableExcutives,
	assginExecutive,
} from "../../../Actions/CREAction";
import Loading from '../../../Components/Loading'
const AssignSalesExecutive = () => {
    const params = useParams();
    const { userInfo } = useSelector((state) => state?.userState);
    const [availableExcutives,setAvailabelExecutives]=useState([])
    const dispatch = useDispatch() ;
    const navigate=useNavigate() ;
    const { loading, error, excutives } = useSelector(
			(state) => state?.cre?.availableExcutives
		);
    useEffect(()=>{
        if(excutives !== undefined){
            setAvailabelExecutives(excutives);
        }
    },[excutives])
    useEffect(()=>{
        dispatch(getAvailableExcutives())
    },[])
    if(loading){
        return <div className='h-100 d-flex justify-content-center align-items-center'>
            <Loading />
        </div>
    }
  return (
		<div className="h-90" style={{overflowY:"scroll",overflowY:"hidden"}} >
			<div className="row w-100 gap-3">
				{availableExcutives.map((d) => {
					return (
						<div key={d.id} className="col-md-5  border rounded p-2 shadow">
							<div className="row">
								<div className="col-3 d-flex align-items-center justify-content-center">
									<img
										src={"https://api.eazyapp.in/" + d.img}
										alt=""
										className="img-fluid  img-thumbnail"
									/>
								</div>
								<div className="col-9 ">
									<h4 className="p-0 m-0">{d.emp_name}</h4>
									<p className="p-0 m-0 text-muted ">{d.emp_designation}</p>
									<button
										className="btn btn-warning"
										onClick={() => {
											dispatch(
												assginExecutive({
													visit_id: params.visit_id,
													executive_id: d.employee_id,
													team_id: d.team_id,
												},navigate)
											);
										}}
									>
										Assign
									</button>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default AssignSalesExecutive
