import {
    PERMISSIONS_REQUEST,
    PERMISSIONS_ERROR,
    // 
    CAN_VIEW_ADMIN_EMPLOYEES,
    CAN_ADD_ADMIN_EMPLOYEES,
    CAN_EDIT_ADMIN_EMPLOYEES,
    CAN_DELETE_ADMIN_EMPLOYEES,
    // 
    CAN_VIEW_DEVELOPER,
    CAN_ADD_DEVELOPER,
    CAN_EDIT_DEVELOPER,
    CAN_DELETE_DEVELOPER,
    CAN_APPROVE_DEVELOPER,
    // 
    CAN_VIEW_PROJECTS,
    CAN_ADD_PROJECTS,
    CAN_EDIT_PROJECTS,
    CAN_DELETE_PROJECTS,
    CAN_APPROVE_PROJECTS,
    // 

    CAN_VIEW_DEVELOPER_PROFILE,
    CAN_EDIT_DEVELOPER_PROFILE,
    // 
    CAN_VIEW_DEVELOPER_SETTINGS,
    CAN_ADD_DEVELOPER_SETTINGS,
    CAN_EDIT_DEVELOPER_SETTINGS,
    CAN_DELETE_DEVELOPER_SETTINGS,
    // 
    CAN_VIEW_DEVELOPER_EMPLOYEES,
    CAN_ADD_DEVELOPER_EMPLOYEES,
    CAN_EDIT_DEVELOPER_EMPLOYEES,
    CAN_DELETE_DEVELOPER_EMPLOYEES,
    // 
    CAN_VIEW_CHANNEL_PARTNERS,
    CAN_EDIT_CHANNEL_PARTNERS,
    CAN_ADD_CHANNEL_PARTNERS,
    // 
    CAN_VIEW_ADMIN_USERS,
    CAN_EDIT_ADMIN_USERS,
    CAN_ADD_ADMIN_USERS,
    CAN_DELETE_ADMIN_USERS,
    // 
    CAN_CREATE_ROLES,
    CAN_VIEW_ROLES,
    CAN_EDIT_ROLES,
    CAN_DELETE_ROLES,
    // 
} from "../Constants/PermissionConstant"

export const permissionReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case PERMISSIONS_REQUEST:
            return { loading: true }
        case PERMISSIONS_ERROR:
            return { error: payload }
        case CAN_VIEW_ADMIN_EMPLOYEES:
            return { ...state, loading: false, employee_view: true };
        case CAN_ADD_ADMIN_EMPLOYEES:
            return { ...state, loading: false, employee_add: true };
        case CAN_EDIT_ADMIN_EMPLOYEES:
            return { ...state, loading: false, employee_edit: true };
        case CAN_DELETE_ADMIN_EMPLOYEES:
            return { ...state, loading: false, employee_delete: true };
        case CAN_VIEW_DEVELOPER:
            return { ...state, loading: false, developer_view: true };
        case CAN_ADD_DEVELOPER:
            return { ...state, loading: false, developer_add: true };
        case CAN_EDIT_DEVELOPER:
            return { ...state, loading: false, developer_edit: true };
        case CAN_DELETE_DEVELOPER:
            return { ...state, loading: false, developer_delete: true };
        case CAN_APPROVE_DEVELOPER:
            return { ...state, loading: false, developer_approve: true };
        case CAN_VIEW_PROJECTS:
            return { ...state, loading: false, projects_view: true };
        case CAN_ADD_PROJECTS:
            return { ...state, loading: false, projects_add: true };
        case CAN_EDIT_PROJECTS:
            return { ...state, loading: false, projects_edit: true };
        case CAN_DELETE_PROJECTS:
            return { ...state, loading: false, projects_delete: true };
        case CAN_APPROVE_PROJECTS:
            return { ...state, loading: false, projects_approve: true };
        case CAN_VIEW_DEVELOPER_PROFILE:
            return { ...state, loading: false, developer_profile_view: true };
        case CAN_EDIT_DEVELOPER_PROFILE:
            return { ...state, loading: false, developer_profile_edit: true };
        case CAN_VIEW_DEVELOPER_EMPLOYEES:
            return { ...state, loading: false, developer_employees_view: true };
        case CAN_ADD_DEVELOPER_EMPLOYEES:
            return { ...state, loading: false, developer_employees_add: true };
        case CAN_EDIT_DEVELOPER_EMPLOYEES:
            return { ...state, loading: false, developer_employees_edit: true };
        case CAN_DELETE_DEVELOPER_EMPLOYEES:
            return { ...state, loading: false, developer_employees_delete: true };
        case CAN_VIEW_DEVELOPER_SETTINGS:
            return { ...state, loading: false, developer_settings_view: true };
        case CAN_ADD_DEVELOPER_SETTINGS:
            return { ...state, loading: false, developer_settings_add: true };
        case CAN_EDIT_DEVELOPER_SETTINGS:
            return { ...state, loading: false, developer_settings_edit: true };
        case CAN_DELETE_DEVELOPER_SETTINGS:
            return { ...state, loading: false, developer_settings_delete: true };
        case CAN_VIEW_CHANNEL_PARTNERS:
            return { ...state, loading: false, channel_partners_view: true };
        case CAN_ADD_CHANNEL_PARTNERS:
            return { ...state, loading: false, channel_partners_add: true };
        case CAN_EDIT_CHANNEL_PARTNERS:
            return { ...state, loading: false, channel_partners_edit: true };
        case CAN_VIEW_ADMIN_USERS:
            return {...state,loading:false,admin_user_view:true};
        case CAN_ADD_ADMIN_USERS:
            return {...state,loading:false,admin_user_add:true};
        case CAN_EDIT_ADMIN_USERS:
            return {...state,loading:false,admin_user_edit:true};
        case CAN_DELETE_ADMIN_USERS:
            return {...state,loading:false,admin_user_delete:true};
        case CAN_CREATE_ROLES:
            return {...state,loading:false,role_create:true};
        case CAN_VIEW_ROLES:
            return {...state,loading:false,role_view:true};
        case CAN_EDIT_ROLES:
            return {...state,loading:false,role_edit:true};
        case CAN_DELETE_ROLES:
            return {...state,loading:false,role_delete:true};
        default:
            return state
    }
}
