import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getDocumentDetail ,updateDocumentDetail } from "../../../../Actions/AdminAction";
import FileUploader from "../../../../Components/FileUploader";
const EditProjectDocument = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { documentId } = useParams();
	const { loading, error, detail } = useSelector(
		(state) => state?.developer?.project?.document
	);

	const submitHandler=(event)=>{
		event.preventDefault()
		const formData=new FormData(event.target);
		dispatch(updateDocumentDetail(formData,documentId,navigate))
	}

	useEffect(() => {
		dispatch(getDocumentDetail(documentId));
	}, []);


	return (
		<Layout pageTitle={"Edit Document"}>
			<form onSubmit={submitHandler} >
			<div className="d-grid gap-3 p-3">
				<div className="d-grid place-items-center" >
            <div className="w-20">
                <FileUploader dbFile={detail?.file} />
            </div>
				</div>
				<div>
					<label className="text-muted">Select File Type</label>
					<select className="form-select" name="file_type">
						{["Broucher", "Cost Sheet", "Master Plan", "Floor Plan","QR"].map(
							(fileType) => {
								return <option value={fileType} selected={fileType === detail?.file_type} >{fileType}</option>;
							}
						)}
					</select>
				</div>
				<div>
					<label className="text-muted">Select File For</label>
					<select className="form-select" name="available_to" multiple >
						{["Customers", "Visitors", "Channel Partner", "Employees"].map(
							(availbleTo) => {
								return (
									<option
										value={availbleTo}
										selected={availbleTo === detail?.available_to}
									>
										{availbleTo}
									</option>
								);
							}
						)}
					</select>
				</div>
				<div className="d-flex justify-content-end gap-3">
					<button className="btn btn-success" type="submit">Save</button>
					<button className="btn btn-dark" type="button" onClick={()=>{
						navigate(-1)
					}} >Cancel</button>
				</div>
			</div>
			</form>
		</Layout>
	);
};

export default EditProjectDocument;
