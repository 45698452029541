import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getChannelPartners,
	getChannelPartner,
	link_channel_partner,
} from "../../Actions/SEAction";
import { get_projects } from "../../Actions/CommonAction"
import { useNavigate } from 'react-router-dom'
import Layout from './Layout/Layout'
import moment from "moment";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
const ChannelPartner = () => {
	const [addChannelPartner, setAddChannelPartner] = useState(false);
	const [showProjects, setShowhowProjects] = useState(false);
	const [reraNumber, setReraNumber] = useState(null)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { loading, error, channel_partners } = useSelector(
		(state) => state?.se?.channelPartnerState
	);
	const channelPartner = useSelector(
		(state) => state?.se?.channelPartner
	);
	const { projects } = useSelector((state) => state?.project);
	useEffect(() => {
		dispatch(getChannelPartners())
		dispatch(get_projects());
	}, [])
	return (
		<Layout pageTitle={"Channel Partner"}>
			<div className="row">
				<div className="col-md-4"></div>
				<div className="col-md-4"></div>
				<div className="col-md-4 px-4">
					<button
						className="btn btn-dark w-100 my-2 "
						onClick={() => setAddChannelPartner(true)}
					>
						Add Channel Partner
					</button>
				</div>
			</div>
			<div className="h-90">
				<div className="row mx-2">
					{channel_partners?.map((channel_partner) => {
						return (
							<div
								className="col-md-6 p-2"
								key={channel_partner.id}
								onClick={() => {
									navigate(`${channel_partner.id}/`);
								}}
							>
								<div className="shadow border rounded py-2 ">
									<div className="row  p-3">
										<div className="col-3">
											{channel_partner?.logo ? <img
												src={"https://api.eazyapp.in" + channel_partner.logo}
												className="img-fluid"
												alt={channel_partner.name}
											/> : <div className="w-100 h-100 d-flex rounded rounded-circle bg-success justify-content-center align-items-center" >
												<p className='text-uppercase' >{channel_partner?.name !== undefined && channel_partner.name.split(' ').map(word => word[0]).join('')}</p>
											</div>}

										</div>
										<div className="col-9">
											<h5 className="card_heading">{channel_partner.name}</h5>
											<p className="m-0 p-0">
												{channel_partner.org_type}
											</p>
											<p className="m-0 p-0">
												<span className="text-muted">Rera Number:</span>{" "}
												{channel_partner.rera_number}
											</p>
										</div>
										<div className="col-12 text-center">
											{/* <p className="fs-7 fw-bold text-muted">
										( NOTE :- Last Visit was on{" "}
										{moment(channel_partner.other_data.last_visit).format(
											"dddd, MMMM Do, YYYY"
										)}{" "}
										)
									</p> */}
										</div>
									</div>
									<div className="row border  fw-bold rounded overflow-hidden shadow p-0">
										<div className="col-4 py-2 border-end bg-light d-flex align-items-center ">
											<label className=" m-0">
												Total Visits: {channel_partner.other_data.visitors}
											</label>
										</div>
										<div className="col-6 py-2 border-end d-flex bg-light align-items-center">
											<label className="m-0">
												Last Visit:{" "}
												{channel_partner.other_data.last_visit !== null ? moment(channel_partner.other_data.last_visit).format(
													"DD/MM/YYYY"
												) : "N/A"}
											</label>
										</div>
										<div className="col-2 py-2  bg-accent text-white   text-dark d-flex justify-content-center align-items-center ">
											<Icon.ArrowRight />
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<Modal
				size="lg"
				show={addChannelPartner}
				onHide={() => setAddChannelPartner(false)}
				aria-labelledby="example-modal-sizes-title-lg"
			>
				<Modal.Header closeButton>
					<Modal.Title id="example-modal-sizes-title-lg">
						Add Channel Partner
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="input-group">
						<input
							type="text"
							placeholder="Enter RERA Number"
							className="form-control border-end-0 border-dark"
							onChange={(e) => setReraNumber(e.target.value)}
						/>
						<span
							class="input-group-text bg-dark text-white  border-dark "
							id="basic-addon2"
							onClick={() => {
								dispatch(getChannelPartner(reraNumber));
							}}
						>
							<Icon.Search />
						</span>
					</div>
					{channelPartner?.detail !== undefined && (
						<div className="p-3">
							<div className="p-2 shadow-sm rounded border">
								{channelPartner?.detail?.logo !== null ? (
									<>
										<div className="row">
											<div className="col-3">
												<img
													src={
														"https://api.eazyapp.in" +
														channelPartner?.detail?.logo
													}
													alt=""
													className="img-fluid"
												/>
											</div>
											<div className="col-9">
												<p className="card_heading m-0">
													{channelPartner?.detail?.name}
												</p>
												{channelPartner?.detail?.location !== null && (
													<p className="m-0">
														<Icon.GeoAlt />
														{channelPartner?.detail?.location}
													</p>
												)}
												<p>
													RERA Number: {channelPartner?.detail?.rera_number}
												</p>
												{channelPartner?.detail?.owner_name !== null && (
													<div className="row">
														<div className="col-6">
															<label>Owner Name</label>
															<p>{channelPartner?.detail?.owner_name}</p>
														</div>
														<div className="col-6">
															<label>Owner Contact</label>
															<p>{channelPartner?.detail?.owner_contact}</p>
														</div>
													</div>
												)}
											</div>
										</div>
									</>
								) : (
									<>
										<p className="card_heading m-0">
											{channelPartner?.detail?.name}
										</p>
										{channelPartner?.detail?.location !== null && (
											<p className="m-0">
												<Icon.GeoAlt />
												{channelPartner?.detail?.location}
											</p>
										)}
										<p>RERA Number: {channelPartner?.detail?.rera_number}</p>
										{channelPartner?.detail?.owner_name !== null && (
											<div className="row">
												<div className="col-6">
													<label>Owner Name</label>
													<p>{channelPartner?.detail?.owner_name}</p>
												</div>
												<div className="col-6">
													<label>Owner Contact</label>
													<p>{channelPartner?.detail?.owner_contact}</p>
												</div>
											</div>
										)}
									</>
								)}
								<hr />
								<div
									onClick={() => {
										setShowhowProjects(true);
									}}
								>
									{showProjects ? (
										<>{projects?.map((i) => {
											return (
												<div className="d-flex justify-content-between" onClick={() => {
													dispatch(
														link_channel_partner(
															{
																channel_partner_id: channelPartner?.detail?.id,
																project_id: i.id,
															},
															setAddChannelPartner
														)
													);
												}}  >
													<p className="m-0">{i.name}</p> <Icon.Plus />
												</div>
											);
										})}</>
									) : (
										<div className="d-flex justify-content-between " onClick={() => { }}  >
											<p className="m-0"> Add To Project </p>{" "}
											<Icon.ArrowRight />
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</Modal.Body>
			</Modal>
		</Layout>
	);
}

export default ChannelPartner
