import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addPaymentPlan, getPaymentSchedule, editPaymentSchedule } from "../../../../Actions/AdminAction";



const PaymentSlab=({dateDriven,slab,slabHandler,removeHandler})=>{


	const DBRemoveHandler=(slabId)=>{
		
	}

	return (
		<>
			<div className="border rounded position-relative">
				<button
					className="btn btn-close position-absolute end-0 m-2 "
					onClick={() => {
						if (slab.fromDB) {
							DBRemoveHandler(slab.id);
						}
						removeHandler(slab.id);
					}}
				></button>
				<div className="p-3 ">
					<div className="row">
						<div className="col-md-4">
							<label>Label </label>
							<input
								className="form-control"
								value={slab.key}
								type="text"
								onChange={(e) => {
									const data = { ...slab };
									data["key"] = e.target.value;
									if (slab.fromDB) {
										data["edited"]=true
										data["new"]=false
									}else{
										data["edited"]=false
										data["new"]=true
									}
									slabHandler(data);
								}}
							/>
						</div>
						<div className="col-md-4">
							<label>Value </label>
							<input
								className="form-control"
								type="text"
								value={slab.value}
								onChange={(e) => {
									const data = { ...slab };
									data["value"] = e.target.value;
									if (slab.fromDB) {
										data["edited"] = true;
										data["new"] = false;
									} else {
										data["edited"] = false;
										data["new"] = true;
									}
									slabHandler(data);
								}}
							/>
						</div>
						{dateDriven && (
							<div className="col-md-4">
								<label>Date</label>
								<input
									type="date"
									className="form-control"
									value={slab.date_to_ask}
									onChange={(e) => {
										const data = { ...slab };
										data["date_to_ask"] = e.target.value;
										if (slab.fromDB) {
											data["edited"] = true;
											data["new"] = false;
										} else {
											data["edited"] = false;
											data["new"] = true;
										}
										slabHandler(data);
									}}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

const AddPaymentSchedule = () => {
	const [name,setName]=useState("")
	const [payementType,setPaymentType]=useState("MileStone")
	const [slabs,setSlabs]=useState([])
	const { projectId ,scheduleId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { loading, error, scheduleData } = useSelector(
		(state) => state?.developer?.paymentSchedule
	);

	useEffect(() => {
		if (scheduleData && scheduleId !== undefined){
			setName(scheduleData.name)
			setPaymentType(scheduleData.payment_type)
			setSlabs(scheduleData.payment_slabs)
		}else{
			setName("")
			setPaymentType("MileStone")
			setSlabs([])
		}
	}, [scheduleData]);

	useEffect(() => {
		if(scheduleId){
			dispatch(getPaymentSchedule(scheduleId));
		}
	}, [scheduleId]);
	

    const submitHandler=()=>{
		const postData = {
			name: name,
			payment_type: payementType,
			payment_slabs: slabs,
		};
		if (scheduleId) {
			dispatch(editPaymentSchedule(scheduleId, postData, navigate));
		}else{

			dispatch(addPaymentPlan(postData,projectId,navigate))
		}
    }



	return (
		<Layout pageTitle={"Add Payment Schedule"}>
			<div className="d-grid gap-2 p-3">
				<div className="d-grid rounded border gap-3 p-3">
					<div>
						<label>Payment Schedule Name</label>
						<input
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
							className="form-control"
						/>
					</div>
					<div>
						<label>Payment Type</label>
						<select
							value={payementType}
							onChange={(e) => setPaymentType(e.target.value)}
							className="form-select"
						>
							<option value="Date Driven">Date Driven</option>
							<option value="MileStone">MileStone</option>
						</select>
					</div>
					<div
						className="d-grid rounded border position-relative gap-3 p-3"
						style={{
							maxHeight: "400px",
							overflowY: "scroll",
							overflowx: "hidden",
						}}
					>
						<div>
							<h3 className="section_heading">Payment Slab</h3>
						</div>
						{slabs.map((item) => {
							return (
								<PaymentSlab
									removeHandler={(id) =>
										setSlabs([...slabs.filter((e) => e.id !== id)])
									}
									dateDriven={payementType === "Date Driven"}
									slab={item}
									slabHandler={(e) => {
										setSlabs(
											[...slabs.filter((d) => d.id !== e.id), e].sort(
												(a, b) => {
													return a.id - b.id;
												}
											)
										);
									}}
								/>
							);
						})}
						<div className="text-center bg-white  sticky-bottom">
							<button
								className="btn btn-primary"
								onClick={() => {
									setSlabs([
										...slabs,
										{
											id: slabs.length > 0 ? slabs[slabs.length - 1].id + 1 : 0,
										},
									]);
								}}
							>
								Add Payment Slab
							</button>
						</div>
					</div>
					<div className="col-12 sticky-bottom bg-white py-3 d-flex justify-content-end gap-3">
						<button className="btn btn-success" onClick={submitHandler}>
							Save
						</button>
						<button className="btn btn-dark" type="button" onClick={()=>{navigate(-1)
							dispatch({
								type: "GET_PAYMENT_SCHEDULE_RESET"
							})

						}}>Cancel</button>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default AddPaymentSchedule;
