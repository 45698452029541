import React, { useEffect, useState } from 'react'
import Layout from './Layout/Layout'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getCutomerProjectDocument } from '../../Actions/CustomerAction'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icon from "@fortawesome/free-solid-svg-icons";



const DocumentList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [tab,setTab]=useState("Receipts")
    const { loading, error, documents} = useSelector(state => state?.customer?.projectDocument)
    const currentBookingId = useSelector((state) => state?.customer?.currentBookingId);
    useEffect(() => {
        if (documents) {
            setData(documents)
        }
    }, [documents])
    const file_types =[

        "Receipts",
        "Demands",
        "Loan Documents",
        "Booking Documents",
        "Templates",
    ]


    useEffect(() => {
        const postData = { }

        dispatch(getCutomerProjectDocument(postData))
    }, [])

    return (
        <Layout pageTitle={"Document & Files"}>
            <div id="scrollableDiv" className='h-100 ' >
                <div className='d-flex justify-content-end p-3' >
                    <button className='btn btn-dark' onClick={() => navigate("/add-document")} >Add Document</button>
                </div>
                <Tabs id="controlled-tab-example"
                    activeKey={tab}
                    onSelect={(k) => setTab(k)}
                    className="mb-3 justify-content-center " >
                    {file_types.map((item, index) => {

                    return <Tab className='h-100 ' eventKey={item} title={item} >
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Document Name</th>
                                    <th scope="col">Genrated On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.filter(item => item.file_type === item).map((item, index) => {
                                        return <tr>
                                            <td>{item.document_name}</td>
                                            <td>{moment(item.genrated_on).format("DD-MM-YYYY")}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        </Tab>
                        })}
                </Tabs>
            </div>
        </Layout>
    )
}

export default DocumentList
