import React, { useEffect } from 'react'
import Layout from './Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getCustomerPaymentList } from '../../Actions/CustomerAction'


const PaymentList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { paymentList, error, loading } = useSelector(state => state.customer?.paymentList)
    const currentBookingId = useSelector((state) => state?.customer?.currentBookingId);

    useEffect(() => {
        dispatch(getCustomerPaymentList({booking_id:currentBookingId}))
    }, [])

    return (
        <Layout pageTitle={"Payment List"}>
            <div className="p-3">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Payment Mode</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date Of Payment</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentList?.map((item, index) => {
                            return <tr>
                                <th scope="row">1</th>
                                <td>{item.payment_mode}</td>
                                <td>{item.amount}</td>
                                <td>{item.date_of_payment}</td>
                                <td>{item.payment_verified ? item.is_rejected ? "Rejected" : "In Progress" : "Verfied"}</td>
                            </tr>
                        })}


                    </tbody>
                </table>
            </div>
        </Layout>
    )
}

export default PaymentList
