export const GET_DASHBOARD_DATA_REQUEST = "GET_DASHBOARD_DATA_REQUEST";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL";

export const GET_TEAMS_DATA_REQUEST = "GET_TEAMS_DATA_REQUEST";
export const GET_TEAMS_DATA_SUCCESS = "GET_TEAMS_DATA_SUCCESS";
export const GET_TEAMS_DATA_FAIL = "GET_TEAMS_DATA_FAIL";

export const GET_VISITS_DATA_REQUEST = "GET_VISITS_DATA_REQUEST";
export const GET_VISITS_DATA_SUCCESS = "GET_VISITS_DATA_SUCCESS";
export const GET_VISITS_DATA_FAIL = "GET_VISITS_DATA_FAIL";



export const SEND_VISITOR_OTP_REQUEST = "SEND_VISITOR_OTP_REQUEST"
export const SEND_VISITOR_OTP_SUCCESS = "SEND_VISITOR_OTP_SUCCESS"
export const SEND_VISITOR_OTP_FAIL = "SEND_VISITOR_OTP_FAIL"





export const VERIFY_VISITOR_OTP_REQUEST = "VERIFY_VISITOR_OTP_REQUEST"
export const VERIFY_VISITOR_OTP_SUCCESS = "VERIFY_VISITOR_OTP_SUCCESS"
export const VERIFY_VISITOR_OTP_FAIL = "VERIFY_VISITOR_OTP_FAIL"


export const SAVE_CHECKIN_DATA_REQUEST = "SAVE_CHECKIN_DATA_REQUEST"
export const SAVE_CHECKIN_DATA_SUCCESS = "SAVE_CHECKIN_DATA_SUCCESS"
export const SAVE_CHECKIN_DATA_FAIL = "SAVE_CHECKIN_DATA_FAIL"

export const GET_AVAILBALE_EXECUTIVES_REQUEST = "GET_AVAILBALE_EXECUTIVES_REQUEST"
export const GET_AVAILBALE_EXECUTIVES_SUCCESS = "GET_AVAILBALE_EXECUTIVES_SUCCESS"
export const GET_AVAILBALE_EXECUTIVES_FAIL = "GET_AVAILBALE_EXECUTIVES_FAIL"



export const GET_LEAD_DETAIL_REQUEST = "GET_LEAD_DETAIL_REQUEST"
export const GET_LEAD_DETAIL_SUCCESS = "GET_LEAD_DETAIL_SUCCESS"
export const GET_LEAD_DETAIL_FAIL = "GET_LEAD_DETAIL_FAIL"