import React, { useEffect } from 'react'
import Layout from './Layout/Layout'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDeveloperList } from '../../Actions/MyAdminAction';
/**
 * AdminDevelopers component displays a list of developers with various actions based on permissions
 */
const AdminDevelopers = () => {
    const navigate = useNavigate(); // navigate to different routes
    const { developer_add, developer_edit, developer_delete, developer_approve } = useSelector((state) => state.permissions); // get developer permissions from state
    const { loading, developers, error } = useSelector(state => state.myAdmin?.developerList); // get developer list, loading state, and error from state
    const dispatch = useDispatch(); // dispatch actions

    // fetch developer list on component mount
    useEffect(() => {
        dispatch(getDeveloperList()); // dispatch action to get developer list
    }, []);

    return (
        <Layout pageTitle={"Developers"}> {/* layout component with pageTitle prop */}
            <div className="d-grid p-3 gap-3"> {/* grid layout */}
                {
                    developer_add && // display add developer button if user has permission
                    <div className="text-end">
                        <button className="btn btn-primary" onClick={() => navigate("/add-developer")}  >Add</button>
                    </div>
                }
                {
                    developers?.map(developer => { // map through developers array
                        return <div key={developer.id} className="card"> {/* display developer card */}
                            <div className="card-body d-flex align-items-center  ">
                                <img className='m-2 rounded-circle img-thumbnail' style={{ height: "100px", width: "100px" }} src={"https://api.eazyapp.in" + developer.logo} alt="" /> {/* display developer logo */}
                                <div>
                                    <h5 className="card-title">{developer.name}</h5> {/* display developer name */}
                                    <p className="card-text">{developer.about}</p> {/* display developer about */}
                                    <div className="d-flex gap-4"> {/* flex layout */}
                                        <button className="btn btn-primary" onClick={() => navigate(`/developer-view/${developer.id}`)} >View</button> {/* view button */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </Layout>
    )
}

export default AdminDevelopers
