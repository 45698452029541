import React from "react";
import Layout from "./Layout/Layout";
import { addLead } from "../../Actions/CPAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const AddLeads = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const configs = ["1 RK", "1 BHK", "2 BHK", "3 BHK", "4 BHK","Jodi","Office","Shop"];

	const submitHandler = (e) => {
		e.preventDefault();
		var formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		object["budget"] = JSON.parse(object.budget);
		object["config"] = formData.getAll("config");
		dispatch(addLead(object, navigate));
	};

	const budgetOption = [
		{ minPrice: 0, maxPrice: 2500000, diff: 2500000, label: "Up to 25 lakhs" },
		{
			minPrice: 2500000,
			maxPrice: 5000000,
			diff: 2500000,
			label: "25 lakhs - 50 lakhs",
		},
		{
			minPrice: 5000000,
			maxPrice: 7500000,
			diff: 2500000,
			label: "50 lakhs - 75 lakhs",
		},
		{
			minPrice: 7500000,
			maxPrice: 10000000,
			diff: 2500000,
			label: "75 lakhs - 1 crore",
		},
		{
			minPrice: 10000000,
			maxPrice: 15000000,
			diff: 5000000,
			label: "1 crore - 1.5 crores",
		},
		{
			minPrice: 15000000,
			maxPrice: 20000000,
			diff: 5000000,
			label: "1.5 crores - 2 crores",
		},
		{
			minPrice: 20000000,
			maxPrice: 25000000,
			diff: 5000000,
			label: "2 crores - 2.5 crores",
		},
		{
			minPrice: 25000000,
			maxPrice: 30000000,
			diff: 5000000,
			label: "2.5 crores - 3 crores",
		},
		{
			minPrice: 30000000,
			maxPrice: 35000000,
			diff: 5000000,
			label: "3 crores - 3.5 crores",
		},
		{
			minPrice: 35000000,
			maxPrice: 40000000,
			diff: 5000000,
			label: "3.5 crores - 4 crores",
		},
		{
			minPrice: 40000000,
			maxPrice: 45000000,
			diff: 5000000,
			label: "4 crores - 4.5 crores",
		},
		{
			minPrice: 45000000,
			maxPrice: 50000000,
			diff: 5000000,
			label: "4.5 crores - 5 crores",
		},
		{
			minPrice: 50000000,
			maxPrice: 55000000,
			diff: 5000000,
			label: "5 crores - 5.5 crores",
		},
		{
			minPrice: 55000000,
			maxPrice: 60000000,
			diff: 5000000,
			label: "5.5 crores - 6 crores",
		},
		{
			minPrice: 60000000,
			maxPrice: 65000000,
			diff: 5000000,
			label: "6 crores - 6.5 crores",
		},
		{
			minPrice: 65000000,
			maxPrice: 70000000,
			diff: 5000000,
			label: "6.5 crores - 7 crores",
		},
		{
			minPrice: 70000000,
			maxPrice: 75000000,
			diff: 5000000,
			label: "7 crores - 7.5 crores",
		},
		{
			minPrice: 75000000,
			maxPrice: 80000000,
			diff: 5000000,
			label: "7.5 crores - 8 crores",
		},
		{
			minPrice: 80000000,
			maxPrice: 85000000,
			diff: 5000000,
			label: "8 crores - 8.5 crores",
		},
		{
			minPrice: 85000000,
			maxPrice: 90000000,
			diff: 5000000,
			label: "8.5 crores - 9 crores",
		},
		{
			minPrice: 90000000,
			maxPrice: 95000000,
			diff: 5000000,
			label: "9 crores - 9.5 crores",
		},
		{
			minPrice: 95000000,
			maxPrice: 100000000,
			diff: 5000000,
			label: "9.5 crores - 10 crores",
		},
	];


	return (
		<Layout pageTitle={"Add Lead"}>
			<form onSubmit={submitHandler}>
				<div className="d-grid gap-2 mx-3 py-5">
					<div className="">
						<label> Name: </label>
						<input type="text" name="name" className="form-control" />
					</div>
					<div className="">
						<label> Email: </label>
						<input type="email" name="email" className="form-control" />
					</div>
					<div className="">
						<label> Last 4 Digit Of Mobile Number: </label>
						<input type="number" name="last_4_digit" className="form-control" />
					</div>
					<div className="d-grid gap-2">
						<label> Budget: </label>
						<select name="budget" id="" className="form-select">
							{budgetOption.map((d) => {
								return (
									<option
										value={JSON.stringify({
											min: d.minPrice,
											max: d.maxPrice,
										})}
									>
										{d.label}
									</option>
								);
							})}
						</select>
					</div>
					<div className="">
						<label>Configuration</label>
						<div
							class=" "
							role="group"
							aria-label="Basic checkbox toggle button group"
						>
							{configs.map((d, index) => {
								return (
									<>
										<input
											type="checkbox"
											class="btn-check"
											id={`btncheck${index}`}
											name="config"
											value={d}
											autocomplete="off"
										/>
										<label
											class="btn btn-outline-primary ms-2 mt-2"
											for={`btncheck${index}`}
										>
											{d}
										</label>
									</>
								);
							})}
						</div>
					</div>
					<div>
						<button className="btn btn-success" type="submit">
							Save
						</button>
						<button className="btn btn-dark" type="button" onClick={() => navigate(-1)} >Cancel</button>
					</div>
				</div>
			</form>
		</Layout>
	);
};

export default AddLeads;
