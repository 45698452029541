import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch,useSelector } from "react-redux";
import { sendOTPVisitor } from '../../../Actions/CREAction'
import toast from "react-hot-toast";
const VerifyVisitor = () => {
	const dispatch = useDispatch();
	const [disabled,setDisabled]=useState(false)
	const [value, setValue] = useState("");
	const navigate = useNavigate();
	const { checkInData } = useSelector((state) => state?.cre?.checkInDetial);
	
    useEffect(() => {
		if (checkInData?.last_4_digit === undefined){
			setDisabled(true);
			return
		}
			if (value.length == 12) {
				if (value.substr(-4) == checkInData?.last_4_digit) {
					setDisabled(true);
					return;
				} else {
					toast.error(
						`Mobile Number should end with ${checkInData?.last_4_digit}`
					);
				}
			}
			setDisabled(false)
	}, [value, checkInData]);
				   
	return (
		<div className="h-80">
			<div
				className="main_container p-0  h-100  "
				style={{ height: "100vh !important" }}
			>
				<div className="d-flex h-100 justify-content-center align-items-center ">
					<div>
						<h3 className="my-3"> Enter Mobile Number </h3>
						<PhoneInput
							country={"in"}
							countryCodeEditable={false}
							placeholder="Enter phone number"
							value={value}
							enableSearch={true}
							containerClass="my-container-class"
							// className="form-control"
							inputClass="form-control"
							onChange={setValue}
						/>
						<div className="text-center">
							<button
								className="btn btn-warning mt-3 "
								disabled={!disabled}
								onClick={() => {
									dispatch(
										sendOTPVisitor(
											{
												mobile_number: value,
											},
											navigate
										)
									);
								}}
							>
								Initate Check-In
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VerifyVisitor;
