import React, { useState, useEffect } from "react";
import QRScanner from "../../../Components/QRScanner";
import { useDispatch, useSelector } from "react-redux";
import { getLeadDetail, saveCheckInData } from "../../../Actions/CREAction";
import { useNavigate } from "react-router-dom";
const Scanner = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showScanner, setShowScanner] = useState(false);
	const { detail, loading, error } = useSelector((state) => state?.cre?.lead);
	const [data, setData] = useState(null);
	useEffect(() => {
		if (data !== null) {
			const postData = JSON.parse(data);
			dispatch(getLeadDetail(postData));
		}
	}, [data]);
	useEffect(() => {
		if (detail?.data !== undefined) {
			if (detail.to === "assign-sales-manager") {
				if (detail.data.ask_to_add_source) {
					alert("tag expired ask to add previous cp");
				} else {
					dispatch(saveCheckInData(detail.data, navigate));

				}
			}
		}
	}, [detail]);

	const verifyHandler = () => {
		var object = {
			first_name: detail.data.lead_name.split(" ")[0],
			last_name:
				detail.data.lead_name.split(" ")[
					detail.data.lead_name.split(" ").length - 1
				],
			email: detail.data.email,
			source: detail.data.cp_detail,
			last_4_digit: detail.data.last_4_digit,
			requirement: {
				budget: {
					minPrice: detail.data?.budget.min,
					maxPrice: detail.data?.budget.max,
				},
				config: detail.data.config.split(/(?:,|&)+/).map((element) => {
					return element.trim();
				}),
				project: detail.data.project_id,
			},
		};
		if (detail.to === "verify-visitor") {
			dispatch({
				type: "ADD_CHECKIN_DATA",
				payload: object,
			});
			navigate("/check-in/verify-visitor");
		}
	};

	return (
		<div className="h-100 w-100">
			<div className="row h-100 align-items-center">
				<div className="col-md-4">
					{detail?.data !== undefined && detail.to === "verify-visitor" ? (
						<>
							<div className="p-2">
								<div className="p-3 rounded shadow border">
									<h2 className="card_heading">Visitor Details</h2>
									<p className=" text-capitalize m-0">
										<span className="label">Name:</span> {detail.data.lead_name}
									</p>
									<p className="m-0">Email: {detail.data.email}</p>
									<p className="">Mobile: XXXXXX{detail.data.last_4_digit}</p>
									<p className="card_heading">Requirements</p>
									<p className="m-0">Configuration: {detail.data?.config}</p>
									<p className="m-0">
										Tagged Project:{" "}
										<span className="text-capitalize">
											{detail.data.project_name}
										</span>
									</p>
									<p className="">
										Budget: {detail?.data.budget.min} to{" "}
										{detail?.data.budget.max}{" "}
									</p>
									<p className="card_heading">Channel Partner Details</p>

									<p className="m-0">Name: {detail.data.cp_detail.name}</p>
									<p className="m-0">
										Employee: {detail.data.cp_detail.employee.name}
									</p>
									<p className="">
										Contact: {detail.data.cp_detail.employee.mobile_number}
									</p>
									<button
										className="btn btn-dark w-100"
										onClick={verifyHandler}
									>
										Verify Vistor
									</button>
								</div>
							</div>
						</>
					) : (
						<></>
					)}
				</div>
				<div className="col-md-4 h-100">
					{showScanner && detail?.data === undefined ? (
						<>
							<div>
								<button
									className="btn btn-dark w-100 "
									onClick={() => {
										setShowScanner(false);
									}}
								>
									Back
								</button>
							</div>
							<QRScanner setData={(e) => setData(e)} />
						</>
					) : (
						<>
							{data === null && (
								<>
									<div className="d-flex flex-column justify-content-evenly align-items-center h-100">
										<div className=" w-80  h-10">
											<button
												className="btn btn-success w-100 h-100 "
												onClick={() => {
													navigate("/check-in/verify-visitor");
													dispatch({ type: "RESET_CHECKIN_DATA" });
												}}
											>
												Direct Visitor
											</button>
										</div>

										<div className=" w-80 h-10  ">
											<button
												className="btn btn-primary w-100 h-100 "
												onClick={() => {
													setShowScanner(true);
												}}
											>
												Channel Partner Visit
											</button>
										</div>

										<div className=" w-80 h-10  ">
											<button
												className="btn btn-secondary w-100 h-100 "
												onClick={() => {
													setShowScanner(true);
													dispatch({ type: "RESET_CHECKIN_DATA" });
												}}
											>
												Re-Visit
											</button>
										</div>
									</div>
								</>
							)}
						</>
					)}
				</div>
				<div className="col-md-4"></div>
			</div>
		</div>
	);
};

export default Scanner;
