export const GET_DASHBOARD_DATA_REQUEST = "GET_DASHBOARD_DATA_REQUEST";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL";

export const GET_LEADS_REQUEST = "GET_LEADS_REQUEST";
export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS";
export const GET_LEADS_FAIL = "GET_LEADS_FAIL";

export const GET_LEAD_DETAIL_REQUEST = "GET_LEAD_DETAIL_REQUEST";
export const GET_LEAD_DETAIL_SUCCESS = "GET_LEAD_DETAIL_SUCCESS";
export const GET_LEAD_DETAIL_FAIL = "GET_LEAD_DETAIL_FAIL";

export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL";

export const ADD_PROJECT_IN_MY_BUCKET_REQUEST =
	"ADD_PROJECT_IN_MY_BUCKET_REQUEST";
export const ADD_PROJECT_IN_MY_BUCKET_SUCCESS =
	"ADD_PROJECT_IN_MY_BUCKET_SUCCESS";
export const ADD_PROJECT_IN_MY_BUCKET_FAIL = "ADD_PROJECT_IN_MY_BUCKET_FAIL";

export const EDIT_EMPLOYEE_DETAIL_REQUEST = 'EDIT_EMPLOYEE_DETAIL_REQUEST'
export const EDIT_EMPLOYEE_DETAIL_SUCCESS = 'EDIT_EMPLOYEE_DETAIL_SUCCESS'
export const EDIT_EMPLOYEE_DETAIL_FAIL = 'EDIT_EMPLOYEE_DETAIL_FAIL'



export const ADD_LEAD_REQUEST = "ADD_LEAD_REQUEST"
export const ADD_LEAD_SUCCESS = "ADD_LEAD_SUCCESS"
export const ADD_LEAD_FAIL = "ADD_LEAD_FAIL"


export const GET_MY_PROJECT_BUCKET_REQUEST = "GET_MY_PROJECT_BUCKET_REQUEST"
export const GET_MY_PROJECT_BUCKET_SUCCESS = "GET_MY_PROJECT_BUCKET_SUCCESS"
export const GET_MY_PROJECT_BUCKET_FAIL = "GET_MY_PROJECT_BUCKET_FAIL"


export const TAG_PROJECT_REQUEST = "TAG_PROJECT_REQUEST"
export const TAG_PROJECT_SUCCESS = "TAG_PROJECT_SUCCESS"
export const TAG_PROJECT_FAIL = "TAG_PROJECT_FAIL"


export const GET_PROJECT_DETAIL_REQUEST = "GET_PROJECT_DETAIL_REQUEST"
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS"
export const GET_PROJECT_DETAIL_FAIL = "GET_PROJECT_DETAIL_FAIL"



export const GET_PROJECT_DOCUMENTS_REQUEST = "GET_PROJECT_DOCUMENTS_REQUEST"
export const GET_PROJECT_DOCUMENTS_SUCCESS = "GET_PROJECT_DOCUMENTS_SUCCESS"
export const GET_PROJECT_DOCUMENTS_FAIL = "GET_PROJECT_DOCUMENTS_FAIL"



export const REGISTER_CHANNEL_PARTNER_REQUEST = "REGISTER_CHANNEL_PARTNER_REQUEST"
export const REGISTER_CHANNEL_PARTNER_SUCCESS = "REGISTER_CHANNEL_PARTNER_SUCCESS"
export const REGISTER_CHANNEL_PARTNER_FAIL = "REGISTER_CHANNEL_PARTNER_FAIL"


export const DELETE_CHANNEL_PARTNER_LEAD_REQUEST = "DELETE_CHANNEL_PARTNER_LEAD_REQUEST"
export const DELETE_CHANNEL_PARTNER_LEAD_SUCCESS = "DELETE_CHANNEL_PARTNER_LEAD_SUCCESS"
export const DELETE_CHANNEL_PARTNER_LEAD_FAIL = "DELETE_CHANNEL_PARTNER_LEAD_FAIL"


export const UNTAG_PROJECT_REQUEST = "UNTAG_PROJECT_REQUEST"
export const UNTAG_PROJECT_SUCCESS = "UNTAG_PROJECT_SUCCESS"
export const UNTAG_PROJECT_FAIL = "UNTAG_PROJECT_FAIL"


export const GET_EMPLOYEES_REQUEST = "GET_EMPLOYEES_REQUEST"
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS"
export const GET_EMPLOYEES_FAIL = "GET_EMPLOYEES_FAIL"


export const DETAIL_EMPLOYEES_REQUEST = "DETAIL_EMPLOYEES_REQUEST"
export const DETAIL_EMPLOYEES_SUCCESS = "DETAIL_EMPLOYEES_SUCCESS"
export const DETAIL_EMPLOYEES_FAIL = "DETAIL_EMPLOYEES_FAIL"

export const CREATE_EMPLOYEE_REQUEST = "CREATE_EMPLOYEE_REQUEST"
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS"
export const CREATE_EMPLOYEE_FAIL = "CREATE_EMPLOYEE_FAIL"


export const DELETE_CP_EMPLOYEE_REQUEST = "DELETE_CP_EMPLOYEE_REQUEST"
export const DELETE_CP_EMPLOYEE_SUCCESS = "DELETE_CP_EMPLOYEE_SUCCESS"
export const DELETE_CP_EMPLOYEE_FAIL = "DELETE_CP_EMPLOYEE_FAIL"