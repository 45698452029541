import React,{useEffect,useState} from 'react'
import Layout from './Layout/Layout'
import { useDispatch,useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getCustomerPricing } from '../../Actions/CustomerAction'
import numberToWords from '../numberFormater'
import * as icon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const PricingPayment = () => {

  const dispatch = useDispatch();
  const navigate= useNavigate()
  const currentBookingId = useSelector((state) => state?.customer?.currentBookingId);
  const { pricings  ,error ,loading} = useSelector(state => state.customer?.pricingPayment)
    useEffect(()=>{
        dispatch(getCustomerPricing({booking_id:currentBookingId}))
    },[])

  return (
    <Layout pageTitle={"Pricing & Payments"}>
      <div className="row">
        <div className="col-4 p-3 position-relative ">
          <FontAwesomeIcon className='fs-3 position-absolute end-0 top-0 mt-4 me-4' icon={icon.faChevronRight} />
          <div className="p-3 rounded border" onClick={()=>{
            navigate("/payment-schedule")
          }} >
            <h2>Payment Schedule</h2>
            <p>Milestone-wise breakup of all demands billed till date and payment received against them</p>
          </div>
        </div>
        <div className="col-4 p-3 position-relative ">
          <FontAwesomeIcon className='fs-3 position-absolute end-0 top-0 mt-4 me-4' icon={icon.faChevronRight} />
          <div className="p-3 rounded border h-100" onClick={()=>{
            navigate("/all-payment-list")
          }} >
            <h2>My Payments</h2>
            <p>Detail of all payments made till date</p>
          </div>
        </div>
        <div className="col-4 p-3 position-relative ">
          <FontAwesomeIcon className='fs-3 position-absolute end-0 top-0 mt-4 me-4' icon={icon.faChevronRight} />
          <div className="p-3 rounded border h-100" onClick={() => {
            navigate("/add-payment")
          }} >
            <h2>Add Payment</h2>
            <p>Create a new payment</p>
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap gap-3 p-3">
        <div className="p-2 w-100">
          <h3>Unit Details</h3>
        </div>
        <div className="row w-50">
          <div className="col-6">Project Name</div>
          <div className="col-6">{pricings?.project_name}</div>
        </div>
        <div className="row w-50">
          <div className="col-6">Unit</div>
          <div className="col-6">{pricings?.unit}</div>
        </div>
        <div className="row w-50">
          <div className="col-6">Wing</div>
          <div className="col-6">{pricings?.wing}</div>
        </div>
        <div className="row w-50">
          <div className="col-6">Config</div>
          <div className="col-6">{pricings?.config}</div>
        </div>
        <div className="row w-50">
          <div className="col-6">Total Considration</div>
          <div className="col-6">{numberToWords(pricings?.total_payable)}</div>
        </div>
        {pricings && Object.keys(pricings?.data).map((key)=>{
          return <div className="row w-50">
            <div className="col-6">{key}</div>
            <div className="col-6">{pricings?.data[key]}</div>
          </div>
        })}
        {pricings && pricings.pricing_list.map(item=>{
          return <div className="row w-100 border">
            <div className="col-6 border">{item.key}</div>
            <div className="col-6 border">{numberToWords(item.value)}</div>
          </div>
        })}
      </div>
      
    </Layout>
  )
}

export default PricingPayment
