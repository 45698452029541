import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout/Layout";
import numberToWords from "../numberFormater";
import { getDeveloperProjects } from "../../Actions/AdminAction";
import { Search } from "react-bootstrap-icons";
const Projects = () => {
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [query, setQuery] = useState("");
	const [serach, setSearch] = useState("");
	const [dataCount, setDataCount] = useState(20);
	const [hasMore, setHasMore] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		loading,
		error,
		projects,
		total_projects,
		pages,
		page: currentPage,
		next_page,
	} = useSelector((state) => state?.developer?.projectList);

	useEffect(() => {
		const Debouncer = setTimeout(() => {
			setQuery(serach);
		}, 500);
		return () => clearTimeout(Debouncer);
	}, [serach]);

	useEffect(() => {
		if (projects) {
			setData(projects);
			setPage(currentPage);
			setDataCount(total_projects);
			setHasMore(next_page);
		}
	}, [projects]);
	useEffect(() => {
		let params = {
			page: page,
		};
		if (query) {
			params["query"] = query
			setData([])
		}
		dispatch(getDeveloperProjects(params));
	}, [query, page]);
	return (
		<Layout pageTitle={"Projects"}>
			<div className="p-3">
				<input
					type="text"
					className="form-control"
					placeholder="Search by Project Name"
					onChange={(e) => setSearch(e.target.value)}
				/>
			</div>
			<InfiniteScroll
				dataLength={dataCount}
				next={() => setPage(page + 1)}
				className="d-grid gap-2 p-2"
				hasMore={hasMore}
				loader={<h4>Loading...</h4>}
				scrollableTarget="scrollableDiv"
			>
				{data.map((project) => {
					return (
						<div className="bg-white d-flex p-2 rounded border shadow">
							<div>
								<img
									className="img-fluid rounded-top"
									src={"https://api.eazyapp.in" + project.cover_img}
									alt=""
									style={{ width: "150px", height: "200px" }}
								/>
							</div>
							<div className="p-3 flex-fill">
								<h4 className="text-capitalize card_heading">{project.name}</h4>
								<p className="m-0 mb-1" >{project.organization}</p>
								<p className="m-0 mb-1" >
									<b>Configs:</b> {project.configs}
								</p>
								<p className="m-0 mb-1" >
									<b>Starting carpet area:</b> {project.starting_carpet_area}{" "}
									sq.ft
								</p>
								<p className="m-0 mb-1" >
									<b>Starting Price:</b> {numberToWords(project.starting_price)}
								</p>
								<div className="row gap-2 row-cols-auto">
									<button
										className="btn btn-primary"
										onClick={() => {
											navigate(`/project-detail/${project.id}`);
										}}
									>
										View Detail
									</button>
									<button
										className="btn btn-primary"
										onClick={() => {
											navigate(
												`/project-payment-setting/${project.id}`
											);
										}}
									>
										Payment Setting
									</button>
									<button
										className="btn btn-primary "
										onClick={() => {
											navigate(`/project-offer-setting/${project.id}`)
										}}
									>
										Offers Setting
									</button>
									<button
										className="btn btn-primary "
										onClick={() => {
											navigate(`/project-pricing-setting/${project.id}`);
										}}
									>
										Pricing Setting
									</button>
									<button
										className="btn btn-primary "
										onClick={() => {
											navigate(`/project-automation/${project.id}`);
										}}
									>
										Automation
									</button>
									<button
										className="btn btn-primary "
										onClick={() => {
											navigate(`/whatsapp-template-create/${project.organization_id}`);
										}}
									>
										Whatsapp
									</button>
								</div>
							</div>
						</div>
					);
				})}
			</InfiniteScroll>
		</Layout>
	);
};

export default Projects;
