import React from 'react'

const ErrorPage = () => {
  return (
		<div className="h-100 w-100 d-grid justify-content-center align-items-center ">
			<div>
				<h1>Oops!</h1>
				<p>Sorry, an unexpected error has occurred.</p>
				<p>
					Please Try again.....!!!!
				</p>
			</div>
		</div>
	);
}

export default ErrorPage
