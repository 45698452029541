import React, { useEffect, useState } from 'react'
import Layout from './Layout/Layout'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getAdminUserList } from '../../Actions/MyAdminAction'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
Array.prototype.uniqueListOfObject = function () {
    var unique = this.filter((obj, index, self) =>
        index === self.findIndex((t) => (
            t.id === obj.id
        ))
    )
    return unique
}

Array.prototype.listToCommaSepratedString = function () {
    return this.join(',')
}
const AdminUserList = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [serach, setSearch] = useState("");
    const [dataCount, setDataCount] = useState(20);
    const [hasMore, setHasMore] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const { loading, error, admins, total_admin_users, pages, page: currentPage, next_page } = useSelector(state => state?.myAdmin?.adminUserList)
    const { admin_user_add } = useSelector(state => state?.permissions)
    useEffect(() => {
        if (admins) {
            setData([...data, ...admins].uniqueListOfObject());
            setPage(currentPage);
            setDataCount(total_admin_users);
            setHasMore(next_page);
        }
    }, [admins]);

    useEffect(() => {
        const Debouncer = setTimeout(() => {
            setQuery(serach);
        }, 500);
        return () => clearTimeout(Debouncer);
    }, [serach]);
    useEffect(() => {
        const params = {
            page: page
        }
        if (query) {
            params["query"] = query
            setData([])
        }
        dispatch(getAdminUserList(params))
    }, [query, page])
    return (
        <Layout pageTitle={"Admin User List"}>
            <div style={{ height: "100%", overflowY: "scroll", overflow: "hidden" }}>
                <div className="p-3 d-flex gap-3">
                    <div className='flex-fill'>
                        <input type="text" className="form-control" placeholder="Search for Admin User" onChange={(e) => setSearch(e.target.value)} />
                    </div>
                    {
                        admin_user_add &&
                        <button className='btn btn-primary' onClick={() => navigate("/add-admin-user")} >Add</button>
                    }
                </div>
                <InfiniteScroll
                    dataLength={dataCount}
                    next={() => setPage(page + 1)}
                    className="row "
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDiv"
                >
                    {data.map((user) => {
                        const roles = user.roles.map(role => role.name)
                        return <div className='col-md-6 p-3'>
                            <div className="rounded shadow-sm border p-2 d-flex gap-2" onClick={() => navigate(`/admin-user-detail/${user.id}`)} >
                                <img src={"https://api.eazyapp.in" + user.photo} alt="" style={{ height: "100px", width: "100px" }} />
                                <div className="flex-fill ">
                                    <h5>{user.name}</h5>
                                    <p>{user.email}</p>
                                    <p>{roles.listToCommaSepratedString()}</p>
                                </div>
                            </div>
                        </div>
                    })}
                </InfiniteScroll>
            </div>
        </Layout>
    )
}

export default AdminUserList
