import React, { useState, useEffect } from 'react'
import Layout from './Layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addAdminUser, getAdminRoles } from '../../Actions/MyAdminAction'
import ImageUploader from '../../Components/ImageUploader'
const AddAdminUser = () => {
    const [addRole, setAddRole] = useState([])
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { roleList } = useSelector(state => state?.myAdmin?.adminRoleList)
    useEffect(() => {
        dispatch(getAdminRoles())
    }, [])

    const submitHandler = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        // formData.add("role",addRole)
        dispatch(addAdminUser(formData, navigate))
    }

    return (

        <Layout pageTitle={"Add Admin User"}>
            <form onSubmit={submitHandler}>
                <div className="d-grid gap-3 p-3">
                    <div className="d-flex justify-content-center">
                        <div className="w-30">
                            <ImageUploader name={"photo"} />
                        </div>
                    </div>
                    <div>
                        <label>First Name</label>
                        <input type="text" name="first_name" className="form-control" />
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input type="text" name="last_name" className="form-control" />
                    </div>
                    <div>
                        <label>Email</label>
                        <input type="text" name="email" className="form-control" />
                    </div>
                    <div>
                        <label>Mobile Number</label>
                        <input type="text" name="phone_number" className="form-control" />
                    </div>
                    <div>
                        <label> Roles</label>
                        <div className="d-flex gap-3 flex-wrap">

                            {roleList?.map(role => {
                                return <div className='form-check form-check-inline'>
                                    <input type="checkbox" class="btn-check" value={role.id} name="roles" id={"btncheck" + role.id} onChange={(e) => {
                                        if (e.target.checked) {
                                            setAddRole([...addRole, role.id])
                                        } else {
                                            setAddRole(addRole.filter((d) => d.id !== role.id))
                                        }
                                    }} autocomplete="off" />
                                    <label class="btn btn-outline-primary" for={"btncheck" + role.id}>{role.name}</label>
                                </div>
                            })}
                        </div>

                    </div>
                    <div className="text-end">
                        <button className='btn btn-success' type='submit'>Save</button>
                        <button
                            className="btn btn-dark"
                            type='button'
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </Layout>
    )
}

export default AddAdminUser
