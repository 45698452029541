import React, { useEffect, useState } from 'react'
import Layout from "../Layout/Layout"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getDeveloperDetails, updateDeveloperDetails, getDeveloperEmployeeList, getDeveloperSettingType } from '../../../Actions/MyAdminAction';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icon from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import ImageUploader from '../../../Components/ImageUploader';
import InfiniteScroll from 'react-infinite-scroll-component';
import ErrorPage from '../../../ErrorPage';
import { deleteDeveloperSetting } from '../../../Actions/AdminAction';
import User from "../../../assets/user.png"

const DeveloperView = () => {
    const [tab, setTab] = useState('profile');
    const { developerId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (window.location.hash.replace("#", "") !== "") {
            setTab(window.location.hash.replace("#", ""));
        }
    }, []);
    useEffect(() => {
        window.location.hash = tab;
    }, [tab]);
    const { developer_profile_view, developer_employees_view, developer_settings_view } = useSelector(state => state?.permissions)

    useEffect(() => {
        dispatch(getDeveloperDetails(developerId))
    }, [developerId])

    return (
        <Layout pageTitle={"Developer View"}>
            <div className="p-3 h-100">

                <Tabs
                    id="controlled-tab-example"
                    activeKey={tab}
                    onSelect={(k) => setTab(k)}
                    className="mb-3 justify-content-center "
                >
                    {
                        developer_profile_view &&

                        <Tab className='h-100 ' eventKey="profile" title={<p><FontAwesomeIcon icon={icon.faUser} /> Profile</p>}>
                            <Profile developerId={developerId} />
                        </Tab>
                    }
                    {
                        developer_employees_view &&
                        <Tab className='h-100 ' eventKey="employee" title={<p><FontAwesomeIcon icon={icon.faUser} /> Employee</p>}>
                            <Employee developerId={developerId} />
                        </Tab>
                    }
                    {
                        developer_settings_view &&
                        <Tab className='h-100 ' eventKey="setting" title={<p><FontAwesomeIcon icon={icon.faCog} /> Settings</p>} >
                            <Settings developerId={developerId} />
                        </Tab>
                    }
                </Tabs>
            </div>
        </Layout>
    )
}



const Profile = ({ developerId }) => {

    const [edit, setEdit] = useState(false);

    const dispatch = useDispatch();
    const { developer, loading, error } = useSelector(state => state?.myAdmin?.developerDetails)
    const { developer_profile_edit } = useSelector(state => state?.permissions)
    useEffect(() => {
        dispatch(getDeveloperDetails(developerId))
    }, [developerId])

    const submitHandler = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);
        dispatch(updateDeveloperDetails(formData, developerId, setEdit))
    }

    if (loading) {
        return <div>Loading...</div>
    }
    if (error) {
        return <ErrorPage />
    }
    if (developer) {

        return (
            <div className='p-2 h-100' >
                <div className="border h-100 rounded p-3 d-grid gap-3 position-relative ">
                    {
                        developer_profile_edit &&
                        <FontAwesomeIcon style={{ zIndex: 99999 }} className=" h4 position-absolute top-0 end-0 m-3" icon={edit ? icon.faXmark : icon.faPenToSquare} onClick={() => setEdit(!edit)} />
                    }
                    {edit ? 
                    <div className='d-grid p-3 gap-3 position-relative '>
                        <form onSubmit={submitHandler} >
                            <div className="d-flex justify-content-center">
                                <div className="w-30">
                                    <ImageUploader dbImage={developer?.logo} name={"logo"} />
                                </div>
                            </div>
                            <div>
                                <label className='fw-bold'>Developer Name</label>
                                <input type="text" className='form-control' name="name" defaultValue={developer?.name} />
                            </div>
                            <div>
                                <label className='fw-bold'>About Developer</label>
                                <textarea className='form-control' name="about" defaultValue={developer?.about} />
                            </div>
                            <div>
                                <label className='fw-bold'>Established Year</label>
                                <input type="text" className='form-control' name="established_year" defaultValue={developer?.established_year} />
                            </div>
                            <div>
                                <label className='fw-bold'>Contact Email</label>
                                <input type="text" className='form-control' name="contact_email" defaultValue={developer?.contact?.contact_email} />
                            </div>
                            <div>
                                <label className='fw-bold'>Contact Number</label>
                                <input type="text" className='form-control' name="contact_number" defaultValue={developer?.contact?.contact_number} />
                            </div>
                            <div className="text-end bg-white sticky-bottom py-1">
                                <button className="btn btn-success" type='submit'>Save</button>
                            </div>
                        </form>
                    </div> :
                        <>

                            <div className="d-flex">
                                <img src={"https://api.eazyapp.in" + developer?.logo} style={{ height: "100px", width: "100px" }} alt="" />
                                <div className='p-3'>
                                    <h5 className="section_heading">{developer?.name}</h5>
                                    <p className="card-text">Since {moment(developer?.established_year).format("YYYY")}</p>
                                </div>
                            </div>
                            <div className="about-section">
                                <h2 className='card_heading'>About {developer.name}</h2>
                                <pre className="card-text" >{developer?.about}</pre>
                            </div>
                            <div className="contact-section">
                                <h2 className='card_heading'>{developer.name} Contacts</h2>
                                <div>
                                    <h6 className="fw-bold">Email:</h6>
                                    <a href={`mailto:${developer?.contact?.contact_email}`} target="_blank" rel="noopener noreferrer">{developer?.contact?.contact_email}</a>
                                </div>
                                <div>
                                    <h6 className="fw-bold">Number:</h6>
                                    <a href={`tel:${developer?.contact?.contact_number}`} target="_blank" rel="noopener noreferrer" >{developer?.contact?.contact_number}</a>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    }
}

const Employee = ({ developerId }) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [serach, setSearch] = useState("");
    const [dataCount, setDataCount] = useState(5);
    const [hasMore, setHasMore] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, employees, error, total_employees, pages, page: currentPage, next_page } = useSelector(state => state?.myAdmin?.developerEmployeeList)
    const { developer_employees_add } = useSelector(state => state?.permissions)
    useEffect(() => {
        const Debouncer = setTimeout(() => {
            setQuery(serach);
        }, 500);
        return () => clearTimeout(Debouncer);
    }, [serach]);

    useEffect(() => {
        if (employees) {
            setData([...data.filter((e) => !employees.map((d)=>d.id).includes(e.id)),...employees]);
            setPage(currentPage);
            setDataCount(total_employees);
            setHasMore(next_page);
        }
    }, [employees]);

    useEffect(() => {
        let params = {
            page: page,
        };
        if (query) {
            params["query"] = query
            setData([])
        }
        dispatch(getDeveloperEmployeeList(developerId, params))
    }, [page, query])

    return (
        <div className='p-2 h-100 position-relative ' id="scrollableDiv" style={{maxHeight:"80vh", overflowY:"scroll"}} >
            {
                developer_employees_add &&

                <div className="sticky-top  py-2 text-end ">
                    <button className="btn btn-primary" onClick={()=>navigate(`/employee-add/${developerId}`)} >Add Employee</button>
                </div>
            }
            <div>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Employee Name"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <InfiniteScroll
                dataLength={dataCount}
                next={() => setPage(page + 1)}
                className="d-grid gap-2 p-2"
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
            >
                {data.map((employee) => {
                    return (
                        <div className="rounded border p-2">
                            <div className="d-flex gap-3 align-items-center">
                                <img src={employee.photo !== undefined && employee.photo !== null ? "https://api.eazyapp.in" + employee.photo : User} style={{ height: "150px", width: "150px" }} alt="" />
                                <div className='flex-fill'>
                                    <p className='card_heading'>{employee.name}</p>
                                    <p className=""><span>Designation: </span>{employee.designation}</p>
                                    <div className="d-grid">
                                        <button className="btn btn-dark" onClick={() => navigate(`/employee-view/${employee.id}`)}>View</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </InfiniteScroll>
        </div>
    )
}

const Settings = ({ developerId }) => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { types, loading ,error } = useSelector(state => state?.myAdmin?.developerSettingType)
    const perrmissions = useSelector(state => state?.permissions)
    useEffect(()=>{
        dispatch(getDeveloperSettingType(developerId))
    },[])
    if(loading){
        return <div>Loading...</div>
    }
    if(types){

        return (
            <div className='p-2'>
                <div className="text-end mb-3">
                    <button className="btn btn-dark" onClick={()=>navigate(`/setting-add/${developerId}`)} >Add Setting</button>
                </div>
                <div className="d-grid gap-3">
                {
                    types.map(element=>{
                        return (
                            <div className='p-3 rounded border shadow-sm  position-relative '>
                                <div className="position-absolute gap- top-0 end-0 ">
                                    {
                                        perrmissions?.developer_settings_edit &&
                                        <FontAwesomeIcon onClick={() => navigate(`/setting-edit/${element.id}`)} className='text-dark m-1 h5' icon={icon.faPenToSquare} />
                                    }
                                    {
                                        perrmissions?.developer_settings_delete &&
                                        <FontAwesomeIcon onClick={() => dispatch(deleteDeveloperSetting(element.id))} className='text-danger m-1 h5' icon={icon.faTrash} />
                                    }
                                </div>
                                <p className='card_heading fw-bold m-0'>{element.setting_type}</p>
                                <p className='m-0 '>{element.name}</p>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        )
    }
}

export default DeveloperView
