import React, { useState,useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./sidebar.css";
import { Link, useLocation } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { getUserData } from "../../../Actions/UserAction";
import { useDispatch } from "react-redux";
const Layout = ({ children, pageTitle }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [showSidebar, setShowSidebar] = useState(true);
	const isMobileView = window.innerWidth <= 768;
	const deivceHeight = window.innerHeight;
	useEffect(()=>{
		dispatch(getUserData());
	})
	const showBottomBar = ()=>{
		if(location.pathname == "/"){
			return true
		}
		const conditionRouteList=["/visit","/team","/profile"]
		return conditionRouteList.some(element => location.pathname.includes(element)) ;
	}
	return (
		<>
			<div className="sidebar_outer_container d-flex">
				<Sidebar show={showSidebar} handleClose={setShowSidebar} />
				<div className="sidebar_right_container p-0 pt-md-2 pb-md-2 pe-md-2  ">
					<div
						className="sidebar_right_inner_container w-100  "
						style={{
							height: `${isMobileView ? deivceHeight : deivceHeight - 15}px`,
							overflow: "hidden",
						}}
					>
						<div
							className="right_screen w-100 "
							style={{
								height: `${isMobileView ? deivceHeight : deivceHeight - 75}px`,
								overflow: "hidden",
							}}
						>
							<Header pageTitle={pageTitle} />
							<div
								className="right_inner_screen   pb-md-2 h-100 w-100 "
								style={{
									overflow: "hidden",
								}}
							>
								{isMobileView && showBottomBar() ? (
									<div className="h-90" style={{
										overflow: "scroll",
									}} >{children}</div>
								) : (
									children
								)}
								{isMobileView && showBottomBar() ? (
									<div className=" d-md-none d-block h-10  w-100 position-fixed  bottom-0 z-index-10 ">
										<div className="row m-0 p-0 bg-accent pb-2 ">
											<div className="col-3 m-0 p-0">
												<Link
													className={`btn rounded-top-0  mt-0 pt-0 rounded-bottom-circle ${
														location.pathname == "/"
															? "btn-light"
															: "bg-accent text-white"
													} w-100 py-3 px-1`}
													to="/"
													activeClassName="active"
												>
													<span className="" style={{ fontSize: "medium" }}>
														<Icon.HouseFill />
														<br />
														<span className="ms-2"> Home</span>
													</span>
												</Link>
											</div>
											<div className="col-3 m-0 p-0">
												<Link
													className={`btn rounded-top-0  mt-0 pt-0 rounded-bottom-circle ${
														location.pathname == "/team"
															? "btn-light"
															: "btn-accent text-white"
													} w-100 py-3 px-1`}
													to="/team"
													activeClassName="active"
												>
													<span style={{ fontSize: "medium" }}>
														{" "}
														<Icon.PeopleFill />
														<br />
														<span className="ms-2"> Teams</span>
													</span>
												</Link>
											</div>
											<div className="col-3 m-0 p-0">
												<Link
													to="/visit"
													className={`btn rounded-top-0  mt-0 pt-0 rounded-bottom-circle ${
														location.pathname == "/visit"
															? "btn-light"
															: "btn-accent text-white"
													} w-100 py-3 px-1`}
													activeClassName="active"
												>
													<span style={{ fontSize: "medium" }}>
														<Icon.CarFrontFill />
														<br />
														<span className="ms-2"> Visits</span>
													</span>
												</Link>
											</div>
											<div className="col-3 m-0 p-0">
												<Link
													to="/check-in"
													className={`btn rounded-top-0  mt-0 pt-0  ${
														location.pathname == "/check-in"
															? "btn-light"
															: "btn-warning "
													} w-100 py-3 px-1`}
													activeClassName="active"
												>
													<span style={{ fontSize: "medium" }}>
														<Icon.BoxArrowLeft />
														<br />
														<span className="ms-2"> Check-In</span>
													</span>
												</Link>
											</div>
										</div>
									</div>
								) : (
									isMobileView && (
										<div
											className="bg-white w-100 position-fixed  bottom-0 z-index-10 "
											style={{ height: "66px" }}
										></div>
									)
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Layout;
