import React ,{useEffect,useState} from 'react'
import Layout from '../Layout/Layout'
import { useParams,useNavigate } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux'
import { deletePricingDetail, getProjectPricingList } from '../../../Actions/AdminAction'
import LoadingPage from '../../LoadingPage'
import ConfirmDelete from '../Components/ConfirmDelete'

const PricingSetting = () => {
    const {projectId}=useParams();
    const dispatch = useDispatch();
    const navigate=useNavigate()
    const {loading,error,pricingList}=useSelector(state=>state?.developer?.project?.pricingList)
    const user=useSelector(state=>state?.userState?.userInfo)
    useEffect(()=>{
        dispatch(getProjectPricingList(projectId))
    },[])

  if(loading){
    return <LoadingPage />
  }
  return (
    <Layout pageTitle={"Pricing List"}>
      <div className="d-grid gap-3 p-3" >
        {
          pricingList?.map(item=>{
            return (
							<div className="rounded border p-3 d-flex flex-wrap">
								<div className="flex-fill">{item.key}</div>
								<div className="flex-fill">{item.value}{item.in_percantage && "%"} </div>
                {!user.extra?.viewer_only &&<>
                <div className="flex-fill d-flex gap-2"><button className='btn btn-success' onClick={()=>{
                  navigate(`/edit-pricing-setting/${item.id}`)
                }} >Edit</button>
                  <button className='btn btn-danger' 
                  // onClick={()=>{
                  //   dispatch(deletePricingDetail(item.id,projectId))
                  // }} 
                  ><ConfirmDelete id={item.id} idToCall={projectId} callback={deletePricingDetail} /></button>
                </div>
                </>}
							</div>
						);
          })
        }
        {!user.extra?.viewer_only &&<div className="text-end">
            <button className="btn btn-primary" onClick={()=>{
                navigate(`/add-pricing-setting/${projectId}`);
            }} >Add Pricing </button>
        </div>}
      </div>
    </Layout>
  )
}

export default PricingSetting
