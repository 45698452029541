import React from 'react'
import Layout from '../Layout/Layout';
import ImageUploader from '../../../Components/ImageUploader';
import { useNavigate ,useParams} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addNewConfigDetail } from '../../../Actions/AdminAction';
const AddConfig = () => {
    const dispatch=useDispatch()
    const {projectId} =useParams();
    const navigate=useNavigate()
    const submitHandler=(event)=>{
        event.preventDefault()
        const formData=new FormData(event.target)
        dispatch(addNewConfigDetail(formData,navigate));
    }
  return (
		<Layout pageTitle={"Add Configuration"}>
            <form onSubmit={submitHandler}>
			<div className="d-grid gap-3 p-2">
				<div className="row">
					<div className="col-4">
						<ImageUploader
							dbImage={null}
						/>
					</div>
					<div className="col-8">
						<div className="row">
							<div className="col-md-6">
                                <input type="text" name="project_id" value={projectId} hidden />
								<label>Name Of Configuration</label>
								<input
									className="form-control"
									type="text"
									name="name"
								/>
							</div>
							<div className="col-md-6">
								<label className="text-muted">Configuration</label>
								<select
									name="config"
									className="form-select"
								>
									{[
										"1 RK",
										"1 BHK",
										"1.5 BHK",
										"2 BHK",
										"2.5 BHK",
										"3 BHK",
										"4 BHK",
										"5 BHK",
									].map((config) => {
										return (
											<option
												value={config}
											>
												{config}
											</option>
										);
									})}
								</select>
							</div>
							<div className="col-md-6">
								<span className="text-muted">Price</span>
								<input
									className="form-control"
									type="number"
									name="price"
								/>
							</div>
							<div className="col-md-6 ">
                                <label>All in</label>
								<div
									class="btn-group"
									role="group"
									aria-label="Basic radio toggle button group"
								>
									<input
										type="radio"
										class="btn-check"
										name="all_in"
										id="btnradio1"
										autocomplete="off"
										value={false}
                                        checked
									/>
									<label class="btn btn-outline-primary" for="btnradio1">
										Extra GST ++
									</label>

									<input
										type="radio"
										class="btn-check"
										name="all_in"
										id="btnradio2"
										value={true}
										autocomplete="off"
									/>
									<label class="btn btn-outline-primary" for="btnradio2">
										All in Price
									</label>
								</div>
							</div>
							<div className="col-md-6">
								<span className="text-muted">Carpet Area</span>
								<div className="input-group">
									<input
										type="number"
										className="form-control"
                                        name="carpet_area"
									/>
									<span className="input-group-text">sq.ft</span>
								</div>
							</div>
						</div>
						<div className="d-flex justify-content-end gap-3">
						<button type='submit' className="btn btn-success">Save</button>
							<button
								className="btn btn-dark"
                                type='button'
								onClick={() => {
									navigate(-1);
								}}
							>
								Cancel
							</button>
							
						</div>
					</div>
				</div>
			</div>
            </form>
		</Layout>
	);
}

export default AddConfig
