import React, { useEffect } from "react";
import ReactAutocomplete from "react-autocomplete";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
const WorkInfo = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [location, setLocation] = React.useState();
	const [locationItems, setLocationItems] = React.useState([]);
	const { checkInData } = useSelector((state) => state?.cre?.checkInDetial);
	useEffect(() => {
		if (checkInData?.workInfo?.location !== undefined) {
			setLocation(checkInData.workInfo.location);
		}
	}, []);
	useEffect(() => {
		if (location === "") {
			return;
		}
		const delay = 1000;
		const Debouncer = setTimeout(async () => {
			try {
				const response = await axios.post(
					"https://houzyy.com/api/v1/get-area-list/",
					{ searched_term: location }
				);
				setLocationItems(response.data);
			} catch (error) {
				toast.error("Something went wrong");
			}
		}, delay);
		return () => clearTimeout(Debouncer);
	}, [location]);

	const submitHandler = (e) => {
		e.preventDefault();
		var formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		Object.keys(object).map((key) => {
			if (object[key] === "" || object[key] === undefined || object[key] === null  ) {
				toast.error(`Please fill ${key}`);
				return
			}
		})
		const postData = { ...checkInData, workInfo: object };

		dispatch({
			type: "ADD_CHECKIN_DATA",
			payload: postData,
		});
		navigate("/check-in/requirments");
	};
	return (
		<div className="h-100 p-3">
			<h2 className="section_heading">Work Details</h2>
			<form action="" className="h-100" onSubmit={submitHandler}>
				<div className="row">
					<div className="col-md-6 pt-3">
						<label>Location</label>
						<ReactAutocomplete
							getItemValue={(item) => item.name}
							items={locationItems}
							renderItem={(item, isHighlighted) => (
								<div
									class={`alert m-0  mt-1 ${
										isHighlighted ? "alert-primary" : "alert-light"
									}`}
									role="alert"
								>
									{item.name}
								</div>
							)}
							value={location}
							menuStyle={{
								borderRadius: "3px",
								boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
								background: "rgba(255, 255, 255, 0.9)",
								fontSize: "90%", // TODO: don't cheat, let it flow to the bottom
								display: "grid",
								maxHeight: "200px",
								overflow: "scroll",
								position: "fixed",
								zIndex: 999999999999,
							}}
							inputProps={{
								className: "form-control",
								name: "location",
								required: true,
							}}
							wrapperProps={{ className: "w-100" }}
							onChange={(e) => {
								setLocation(e.target.value);
							}}
							onSelect={(val, item) => {
								setLocation(val);
							}}
						/>
					</div>
					<div className="col-md-6 pt-3">
						<label>Company Name</label>
						<input
							type="text"
							className="form-control"
							value={checkInData?.workInfo?.company_name}
							name="company_name"
							required
						/>
					</div>
					<div className="col-md-6 pt-3">
						<label>Designation</label>
						<input
							type="text"
							className="form-control"
							value={checkInData?.workInfo?.designation}
							name="designation"
							required
						/>
					</div>
				</div>
				<div className="row mt-5">
					<div className="col-md-4"></div>
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<div className="row">
							<div className="col-6">
								<button type="button" className="btn btn-dark w-100 " onClick={() => navigate(-1)} >
									Back
								</button>
							</div>
							<div className="col-6">
								<button className="btn btn-success w-100 ">Next</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default WorkInfo;
