import React, { useState, useEffect } from 'react'
import Layout from './Layout/Layout'
import { getChannelPartnerList } from '../../Actions/MyAdminAction'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

const AdminChannelPartners = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [serach, setSearch] = useState("");
  const [dataCount, setDataCount] = useState(20);
  const [hasMore, setHasMore] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { loading, channel_partners, error, total_cps, pages, page: currentPage, next_page } = useSelector((state) => state?.myAdmin?.channelPartnerList);
  const permission = useSelector(state => state?.permissions)
  useEffect(() => {
    if (channel_partners) {
      setData(channel_partners);
      setPage(currentPage);
      setDataCount(total_cps);
      setHasMore(next_page);
    }
  }, [channel_partners]);
  useEffect(() => {
    const Debouncer = setTimeout(() => {
      setQuery(serach);
    }, 500);
    return () => clearTimeout(Debouncer);
  }, [serach]);
  useEffect(() => {
    const params = {
      page: page
    }
    if (query) {
      params["query"] = query
      setData([])
    }
    dispatch(getChannelPartnerList(params))
  }, [query,page])
  return (
    <Layout pageTitle={"Channel Partners"}>
      <div className="d-flex p-3">
        <div className='flex-fill px-2'>
          <input placeholder='Search Channel Partner by name or RERA' type="text" className='form-control' value={serach} onChange={(e) => setSearch(e.target.value)} />
        </div>
        {
          permission?.channel_partners_add &&
          <div className="">
            <button className="btn btn-primary" onClick={()=>navigate("/add-channel-partner")} >Add</button>
          </div>
        }
      </div>
      <div className="d-grid gap-3 p-3">
        <InfiniteScroll
          dataLength={dataCount}
          next={() => setPage(page + 1)}
          className="d-flex flex-wrap gap-2 p-2"
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {
            data.map(item => {
              return (<>
                <div className="p-3 border border-1 rounded" style={{ width: "450px" }} onClick={()=>navigate(`/channel-partner/${item?.id}`)} >
                  <div className="d-flex gap-3 align-items-center">
                    {item?.logo !== null && item?.logo !== undefined ? <img src={"https://api.eazyapp.in" + item?.logo} style={{ height: "100px", width: "100px" }} alt="" /> : <div className=" d-flex rounded rounded-circle bg-success justify-content-center align-items-center" style={{ height: "100px", width: "100px" }} >
                      <p className='text-uppercase m-0 ' >{item?.name !== undefined && item.name.split(' ').map(word => word[0]).join('')}</p>
                    </div>}
                    
                    <div>
                      <h2 className='card_heading'>{item?.name}</h2>
                      <p className='m-0'>({item?.org_type}) </p>
                      <p className='m-0'> <span>RERA Number:</span> {item?.rera_number} </p>
                      <div className="btn btn-dark my-2">
                        View
                      </div>
                    </div>
                  </div>
                </div>
              </>)
            })
          }
        </InfiniteScroll>
      </div>
    </Layout>
  )
}

export default AdminChannelPartners
