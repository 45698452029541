import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/eazyapp-logo-blue.png";
import * as Icon from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { userLogout } from "../../../Actions/UserAction";
function Sidebar(props) {
	const dispatch=useDispatch()
	const check = () => {
		if (
			props.props === "ServiceOptions" ||
			props.props === "AddServiceScreen"
		) {
			return "active";
		}
	};

	return (
		<div className="sidebar_left d-none d-md-block">
			<div className="sidbar_logo d-flex justify-content-center">
				{/* <h2 className="text-warning">EazyApp</h2> */}
				<img src={logo} alt="logo" className="img-fluid" />
			</div>
			<div>
				<ul className="list-group">
					<li className="menu_links">
						<NavLink
							to="/"
							className={`d-flex align-items-center link_options `}
							activeclassname="active"
						>
							<Icon.HouseFill /> <span className="ms-2"> Home</span>
						</NavLink>
					</li>
					<li className="menu_links">
						<NavLink
							to="/team"
							className={`d-flex align-items-center link_options `}
							activeclassname="active"
						>
							<Icon.PeopleFill /> <span className="ms-2"> Teams</span>
							{/* <i className="ri-camera-line me-2"></i> */}
						</NavLink>
					</li>
					<li className="menu_links">
						<NavLink
							to="/visit"
							className={`d-flex align-items-center link_options `}
							activeclassname="active"
						>
							<Icon.CarFrontFill /> <span className="ms-2"> Visits</span>
						</NavLink>
					</li>
					<li className="menu_links">
						<NavLink
							to="/profile"
							className={`d-flex align-items-center link_options `}
							activeclassname="active"
						>
							<Icon.PersonCircle /> <span className="ms-2"> Profile</span>
						</NavLink>
					</li>
					<li className="menu_links mt-auto">
						<button
							className="d-flex btn-danger m-0 border-0 align-items-center link_options "
							onClick={() => dispatch(userLogout())}
						>
							<Icon.BoxArrowLeft /> Logout
						</button>
						{/* <NavLink
							to="/channel-partner"
							className={`d-flex align-items-center link_options `}
							activeclassname="active"
						>
							<FontAwesomeIcon icon={icon.faUserTie} />
							<span className="ms-2"> Channel Partner</span>
						</NavLink> */}
					</li>
				</ul>
			</div>
		</div>
	);
}

export default Sidebar;
