import React,{useEffect, useState} from 'react'
import { useDispatch , useSelector } from 'react-redux'
import { useNavigate ,useParams } from 'react-router-dom'
import Layout from '../Layout/Layout'
import { getCustomerBookingDetail } from '../../../Actions/AdminAction'
import numberToWords from '../../numberFormater'
import { Tab, Tabs } from "react-bootstrap";


const CustomerBooking=({bookingDetail})=>{
  return (
    <>

      <h2 className='text-center fw-bold' >Booking Details</h2>
      <table className="table table-bordered">
        <tbody>
          <tr>
            <td colSpan={2} >Email : {bookingDetail?.customer?.email}</td>
            <td colSpan={2} >Phone : +{bookingDetail?.customer?.phone_number}</td>
          </tr>
          <tr>
            <td>Project: {bookingDetail?.project?.name}</td>
            <td>Configuration Booked: {bookingDetail?.config}</td>
            <td>Unit Booked: {bookingDetail?.unit}</td>
            <td>Wing: {bookingDetail?.wing}</td>

          </tr>
          <tr>
            <td colSpan={2}  >Flat Price</td>
            <td colSpan={2} className='text-end' >{bookingDetail?.flat_value.commaSepratedAmount()}</td>
          </tr>
          {bookingDetail?.pricing_table.map((item) => {
            if(item.value){

            return <tr>
              <td colSpan={2}  >{item?.key}</td>
              <td colSpan={2} className='text-end' >{parseInt(item.in_percantage ? (bookingDetail?.flat_value * item.value) / 100 : item?.value).commaSepratedAmount()}</td>
            </tr>
            }

          })}
          {bookingDetail?.offers.map((item) => {
            if(item.value){

            return <tr>
              <td colSpan={2}  >{item?.key}</td>
              <td colSpan={2} className='text-end' > {item.use_value ? "-": "+-"} {parseInt(item.in_percantage ? (bookingDetail?.flat_value * item.value) / 100 : item?.value).commaSepratedAmount()}</td>
            </tr>
            }
          })}
          <tr >
            <td className=' fw-bold' colSpan={2}  >Total Amount</td>
            <td colSpan={2} className='text-end  fw-bold'  >{([bookingDetail?.flat_value, ...bookingDetail?.pricing_table.map((item) => { return item.in_percantage ? (bookingDetail?.flat_value * item.value) / 100 : item?.value }).filter((item) => item != undefined)].addAllItems() - bookingDetail?.offers.map((item) => { if (item.use_value) { return item.in_percantage ? (bookingDetail?.flat_value * item.value) / 100 : item?.value } return 0}).addAllItems()).commaSepratedAmount()}</td>
          </tr>


        </tbody>
      </table>
    </>
  )
}

const CustomerPaymentSchedule=({bookingDetail})=>{
  const total_payable = [bookingDetail?.flat_value, ...bookingDetail?.pricing_table.map((item) => { return item.in_percantage ? (bookingDetail?.flat_value * item.value) / 100 : item?.value })].addAllItems() - bookingDetail?.offers.map((item) => { return item.in_percantage ? (bookingDetail?.flat_value * item.value) / 100 : item?.value }).addAllItems()
  return (  
    <>  
      <h2 className='text-center fw-bold' >Customer Payment Schedule</h2>
      <div className="d-flex justify-content-center">

        <ul class="timeline">

        {
          bookingDetail?.payment_schedule.map((item)=>{
            return <li className="timeline-item mb-5 rounded border p-3 shadow position-relative">
              <h4> {item.value}%  {item.key}  {item?.is_completed ? <span  className='text-success fs-6 rounded p-2 alert alert-success rounded-pill ' >Completed</span> : <span className='text-danger fs-6 p-2 alert alert-danger rounded rounded-pill  '>Pending</span>}</h4>
              <p>{item.value}% of {numberToWords(total_payable)} = {numberToWords((item.value * total_payable)/100)}</p>
              <div  className='text-end' >
                {item.date_to_ask}
              </div>
            </li>
          })
        }
        </ul>
      
      </div>
    </>
  )
}


const CustomerDocument=({bookingDetail})=>{
  return (
    <>
      <h2 className='text-center fw-bold' >Customer Documents</h2>
      
    </>
  )
}

const CustomerBookingDetail = () => {

  const [page, setPage] = useState("booking_detail");
    const {bookingId}=useParams();
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const {loading,error,bookingDetail}=useSelector(state=>state?.developer?.customer?.bookingDetail)
    useEffect(()=>{
        dispatch(getCustomerBookingDetail(bookingId))
    },[])


  if (bookingDetail){
    return (
      <Layout pageTitle={"Customer Booking Detail"} >
        <div className="d-grid  gap-2 p-2 ">
          <div className='d-flex justify-content-center text-center' >
              <img  className='rounded rounded-circle border thumbnail' src={bookingDetail?.customer?.photo} alt=""   style={{height:"150px",width:"150px"}} />
          </div>
          <div className="text-center">
              <h3 className='fw-bold'>{bookingDetail?.customer?.first_name} {bookingDetail?.customer?.last_name}</h3>
          </div>


          <Tabs id="controlled-tab-example"
            variant="tabs"
            activeKey={page}
            onSelect={(k) => setPage(k)}
            className=" gap-2 gap-md-4 " >
              <Tab eventKey={"booking_detail"} title={"Booking Detail"} >
                <CustomerBooking  bookingDetail={bookingDetail} />
              </Tab>
              <Tab eventKey={"payement_schedule"} title={"Payment Schedule"} >
                <CustomerPaymentSchedule  bookingDetail={bookingDetail} />  
              </Tab>

          </Tabs>
        </div>
        <div className="justify-content-end d-flex gap-3">
        <button className="btn btn-dark" type="button" onClick={()=>navigate(-1)}>Back</button>
        </div>
          
  
      </Layout>
    )
  }

}

export default CustomerBookingDetail
