import React,{useState} from 'react'
import Layout from './Layout/Layout'
import { useNavigate,Outlet } from 'react-router-dom';
import * as Icon from "react-bootstrap-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CheckInVisitors = () => {
const navigate = useNavigate();
  return (
		<Layout
			pageTitle={
				<div className="d-flex">
					<button
						className="btn btn-white  d-md-none "
						onClick={() => {
							navigate(-1);
						}}
					>
						<Icon.ArrowLeft />
					</button>
					<span className="mt-1 ms-2">Check-In</span>
				</div>
			}
		>
			<Outlet />	
		</Layout>
	);
}

export default CheckInVisitors
