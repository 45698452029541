import React, { useEffect } from "react";
import ReactAutocomplete from "react-autocomplete";
import axios from "axios";
import { useSelector ,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading";
import toast from "react-hot-toast";
const ResidentialInfo = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [location, setLocation] = React.useState();
	const [locationItems, setLocationItems] = React.useState([]);
	const { checkInData } = useSelector((state) => state?.cre?.checkInDetial);
	useEffect(()=>{
		if(checkInData?.residentialInfo?.location !== undefined){
			setLocation(checkInData.residentialInfo.location);	
		}
	},[])
	useEffect(() => {
		if (location === "") {
			return;
		}
		const delay = 1000;
		const Debouncer = setTimeout(async () => {
			try {
				const response = await axios.post(
					"https://houzyy.com/api/v1/get-area-list/",
					{ searched_term: location }
				);
				setLocationItems(response.data)
			} catch (error) {
				toast.error("Something went wrong");
			}
		}, delay);
		return () => clearTimeout(Debouncer);
	}, [location]);

	const submitBasicInfo = (e) => {
		e.preventDefault();
		var formData = new FormData(e.target);
		const object = Object.fromEntries(formData);
		Object.keys(object).map((key) => {
			if (object[key] === "" || object[key] === undefined || object[key] === null  ) {
				toast.error(`Please fill ${key}`);
				return 
			}
		})
		const postData={...checkInData,residentialInfo:object}
		
		dispatch({
			type: "ADD_CHECKIN_DATA",
			payload: postData,
		});
		navigate("/check-in/work-info");
	};
	return (
		<div className="h-100 p-3">
			<h2>Residence Details</h2>
			<form className="h-100" onSubmit={submitBasicInfo}>
				<div className="row  px-3">
					<div className="col-md-6 pt-3">
						<label>Location</label>
						<ReactAutocomplete
							getItemValue={(item) => item.name}
							items={locationItems}
							renderItem={(item, isHighlighted) => (
								<div
									class={`alert m-0  mt-1 ${
										isHighlighted ? "alert-primary" : "alert-light"
									}`}
									role="alert"
								>
									{item.name}
								</div>
							)}
							value={location}
							menuStyle={{
								borderRadius: "3px",
								boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
								background: "rgba(255, 255, 255, 0.9)",
								fontSize: "90%", // TODO: don't cheat, let it flow to the bottom
								display: "grid",
								maxHeight: "200px",
								overflow: "scroll",
								position: "fixed",
								zIndex: 999999999999,
							}}
							inputProps={{
								className: "form-control",
								name: "location",
								required: true,
							}}
							wrapperProps={{ className: "w-100" }}
							onChange={(e) => {
								setLocation(e.target.value);
							}}
							onSelect={(val, item) => {
								setLocation(val);
							}}
						/>
					</div>
					<div className="col-md-6 pt-3">
						<label> Select Residence OwnerShip </label>
						<br />
						<div
							class="btn-group"
							role="group"
							aria-label="Basic radio toggle button group"
						>
							<input
								type="radio"
								class="btn-check"
								name="property_status"
								value="owned"
								id="btnradio1"
								autocomplete="off"
							/>
							<label class="btn btn-outline-warning" for="btnradio1">
								Owned
							</label>
							<input
								type="radio"
								class="btn-check"
								name="property_status"
								value="rent"
								id="btnradio2"
								autocomplete="off"
								// checked={
								// 	checkInData?.residentailInfo?.property_status === "rent"
								// }
							/>
							<label class="btn btn-outline-warning" for="btnradio2">
								Rented
							</label>
						</div>
					</div>
					<div className="col-md-6 pt-3">
						<label>Current Configuration</label>
						<select name="config" className="form-select" required>
							<option
								disabled
								selected={undefined === checkInData?.residentailInfo?.config}
							>
								Select Configuration
							</option>
							<option
								value="1Rk"
								selected={"1Rk" === checkInData?.residentailInfo?.config}
							>
								1Rk
							</option>
							<option
								value="1Bhk"
								selected={"1Bhk" === checkInData?.residentailInfo?.config}
							>
								1Bhk
							</option>
							<option
								value="2Bhk"
								selected={"2Bhk" === checkInData?.residentailInfo?.config}
							>
								2Bhk
							</option>
							<option
								value="3Bhk"
								selected={"3Bhk" === checkInData?.residentailInfo?.config}
							>
								3Bhk
							</option>
							<option
								value="4Bhk"
								selected={"4Bhk" === checkInData?.residentailInfo?.config}
							>
								4Bhk
							</option>
						</select>
					</div>
				</div>
				<div className="row mt-5 ">
					<div className="col-md-4"></div>
					<div className="col-md-4"></div>
					<div className="col-md-4">
						<div className="row">
							<div className="col-6">
								<button type="button" className="btn btn-dark w-100 "  onClick={() => navigate(-1)}  >
									Back
								</button>
							</div>
							<div className="col-6">
								<button className="btn btn-success w-100 ">Next</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ResidentialInfo;
