import {
    GET_DEVELOPER_LIST_REQUEST,
    GET_DEVELOPER_LIST_SUCCESS,
    GET_DEVELOPER_LIST_FAIL,
    // 
    GET_DEVELOPER_DETAILS_REQUEST,
    GET_DEVELOPER_DETAILS_SUCCESS,
    GET_DEVELOPER_DETAILS_FAIL,
    // 
    GET_DEVELOPER_EMPLOYEES_REQUEST,
    GET_DEVELOPER_EMPLOYEES_SUCCESS,
    GET_DEVELOPER_EMPLOYEES_FAIL,
    // 
    GET_DEVELOPER_EMPLOYEE_DETAILS_REQUEST,
    GET_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS,
    GET_DEVELOPER_EMPLOYEE_DETAILS_FAIL,
    // 
    GET_DEVELOPER_SETTING_TYPE_REQUEST,
    GET_DEVELOPER_SETTING_TYPE_SUCCESS,
    GET_DEVELOPER_SETTING_TYPE_FAIL,
    // 
    GET_SETTING_TYPE_REQUEST,
    GET_SETTING_TYPE_SUCCESS,
    GET_SETTING_TYPE_FAIL,
    // 
    GET_PROJECT_LIST_REQUEST,
    GET_PROJECT_LIST_SUCCESS,
    GET_PROJECT_LIST_FAIL,
    // 
    GET_PROJECT_DETAILS_REQUEST,
    GET_PROJECT_DETAILS_SUCCESS,
    GET_PROJECT_DETAILS_FAIL,
    // 
    GET_CHANNEL_PARTNER_LIST_REQUEST,
    GET_CHANNEL_PARTNER_LIST_SUCCESS,
    GET_CHANNEL_PARTNER_LIST_FAIL,
    // 
    GET_CHANNEL_PARTNER_DETAIL_REQEUST,
    GET_CHANNEL_PARTNER_DETAIL_SUCCESS,
    GET_CHANNEL_PARTNER_DETAIL_FAIL,
    // 
    GET_CP_EMPLOYEE_DETAILS_REQUEST,
    GET_CP_EMPLOYEE_DETAILS_SUCCESS,
    GET_CP_EMPLOYEE_DETAILS_FAIL,
    // 
    GET_DASHBOARD_DATA_REQUEST,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_FAIL,
    // 
    GET_ADMIN_USER_LIST_REQUEST,
    GET_ADMIN_USER_LIST_SUCCESS,
    GET_ADMIN_USER_LIST_FAIL,
    // 
    GET_ADMIN_USER_DETAIL_REQUEST,
    GET_ADMIN_USER_DETAIL_SUCCESS,
    GET_ADMIN_USER_DETAIL_FAIL,
    // 
    GET_ADMIN_ROLES_REQUEST,
    GET_ADMIN_ROLES_SUCCESS,
    GET_ADMIN_ROLES_FAIL,
    // 
    GET_ADMIN_PERMISSIONS_REQUEST,
    GET_ADMIN_PERMISSIONS_SUCCESS,
    GET_ADMIN_PERMISSIONS_FAIL,
    // 
} from "../Constants/MyAdminConstants.js"

export const developerListReducer = (state = { developers: [] }, action) => {

    /**
     * Reducer function for managing the list of developers in the state.
     *
     * @param {Object} state - The current state of the developers list
     * @param {Object} action - The action object that contains the type and payload
     * @return {Object} The new state after handling the action
     */

    switch (action.type) {
        case GET_DEVELOPER_LIST_REQUEST:
            return { loading: true }
        case GET_DEVELOPER_LIST_SUCCESS:
            return { loading: false, developers: action.payload }
        case GET_DEVELOPER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const developerDetailsReducer = (state = { developer: {} }, action) => {
    /**
     * Reducer function for managing developer details.
     *
     * @param {object} state - The current state
     * @param {object} action - The action being performed
     * @return {object} The new state after applying the action
     */
    switch (action.type) {
        case GET_DEVELOPER_DETAILS_REQUEST:
            return { loading: true }
        case GET_DEVELOPER_DETAILS_SUCCESS:
            return { loading: false, developer: action.payload }
        case GET_DEVELOPER_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const developerEmployeeListReducer = (state = {}, action) => {
    /**
     * Reducer function for managing developer employees.
     *
     * @param {object} state - The current state
     * @param {object} action - The action being performed
     * @return {object} The new state after applying the action
     */
    switch (action.type) {
        case GET_DEVELOPER_EMPLOYEES_REQUEST:
            return { loading: true }
        case GET_DEVELOPER_EMPLOYEES_SUCCESS:
            return { loading: false, ...action.payload }
        case GET_DEVELOPER_EMPLOYEES_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const developerEmployeeDetailsReducer = (state = {}, action) => {
    /**
     * Reducer function for managing developer employee details.
     *
     * @param {object} state - The current state
     * @param {object} action - The action being performed
     * @return {object} The new state after applying the action
     */
    switch (action.type) {
        case GET_DEVELOPER_EMPLOYEE_DETAILS_REQUEST:
            return { loading: true }
        case GET_DEVELOPER_EMPLOYEE_DETAILS_SUCCESS:
            return { loading: false, employee:action.payload }
        case GET_DEVELOPER_EMPLOYEE_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const developerSettingTypeReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_DEVELOPER_SETTING_TYPE_REQUEST:
            return { loading: true }
        case GET_DEVELOPER_SETTING_TYPE_SUCCESS:
            return { loading: false, types:action.payload }
        case GET_DEVELOPER_SETTING_TYPE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const settingTypeReducer=(state={}, action)=>{
    switch(action.type){
        case GET_SETTING_TYPE_REQUEST:
            return { loading: true }
        case GET_SETTING_TYPE_SUCCESS:
            return {loading:false, typeList:action.payload}
        case GET_SETTING_TYPE_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    }
}


export const projectListReducer = (state = { projects: [] }, action) => {
    switch (action.type) {
        case GET_PROJECT_LIST_REQUEST:
            return { loading: true }
        case GET_PROJECT_LIST_SUCCESS:
            return { loading: false,  ...action.payload }
        case GET_PROJECT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const projectDetailsReducer = (state = { project: {} }, action) => {
    switch (action.type) {
        case GET_PROJECT_DETAILS_REQUEST:
            return { loading: true }
        case GET_PROJECT_DETAILS_SUCCESS:
            return { loading: false,  projectDetail:action.payload }
        case GET_PROJECT_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const channelPartnerListReducer = (state = { channelPartners: [] }, action) => {
    switch (action.type) {
        case GET_CHANNEL_PARTNER_LIST_REQUEST:
            return { loading: true }
        case GET_CHANNEL_PARTNER_LIST_SUCCESS:
            return { loading: false,  ...action.payload }
        case GET_CHANNEL_PARTNER_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const channelPartnerDetailReducer = (state={}, action) => {
    switch(action.type){
        case GET_CHANNEL_PARTNER_DETAIL_REQEUST:
            return { loading: true }
        case GET_CHANNEL_PARTNER_DETAIL_SUCCESS:
            return {loading:false, channel_partner:action.payload}
        case GET_CHANNEL_PARTNER_DETAIL_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    }
}


export const cpEmployeeDetailReducer=(state={}, action)=>{
    switch(action.type){
        case GET_CP_EMPLOYEE_DETAILS_REQUEST:
            return { loading: true }
        case GET_CP_EMPLOYEE_DETAILS_SUCCESS:
            return {loading:false, employee:action.payload}
        case GET_CP_EMPLOYEE_DETAILS_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    }
}


export const AdmindashboardDataReducer=(state={},action)=>{
    switch(action.type){
        case GET_DASHBOARD_DATA_REQUEST:
            return { loading: true }
        case GET_DASHBOARD_DATA_SUCCESS:
            return {loading:false, dashboardData:action.payload}
        case GET_DASHBOARD_DATA_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    }
}



export const AdminUserListReducer=(state={},action)=>{
    switch(action.type){
        case GET_ADMIN_USER_LIST_REQUEST:
            return { loading: true }
        case GET_ADMIN_USER_LIST_SUCCESS:
            return {loading:false, ...action.payload}
        case GET_ADMIN_USER_LIST_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    }
}


export const adminUserDetailReducer=(state={},action)=>{
    switch(action.type){
        case GET_ADMIN_USER_DETAIL_REQUEST:
            return { loading: true }
        case GET_ADMIN_USER_DETAIL_SUCCESS:
            return {loading:false, adminDetail:action.payload}
        case GET_ADMIN_USER_DETAIL_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    }
}


export const adminRoleListReducer=(state={},action)=>{
    switch(action.type){
        case GET_ADMIN_ROLES_REQUEST:
            return { loading: true }
        case GET_ADMIN_ROLES_SUCCESS:
            return {loading:false, roleList:action.payload}
        case GET_ADMIN_ROLES_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    }
}


export const adminPermissionsListReducer=(state={},action)=>{
    switch(action.type){
        case GET_ADMIN_PERMISSIONS_REQUEST:
            return {loading:true};
        case GET_ADMIN_PERMISSIONS_SUCCESS:
            return {loading:false, permissionList:action.payload};
        case GET_ADMIN_PERMISSIONS_FAIL:
            return {loading:false, error:action.payload};
        default:
            return state
    }
}