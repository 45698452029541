import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ImageUploader from "../../../Components/ImageUploader";
import Layout from "../Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
	getConfigDetail,
	editConfigDetail,
} from "../../../Actions/AdminAction";
const AdminEditConfig = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const { configId } = useParams();
	const { configDetail } = useSelector(
		(state) => state?.developer?.project?.configDetail
	);
	useEffect(() => {
		dispatch(getConfigDetail(configId));
	}, []);

	const submitHandler = (event) => {
		event.preventDefault()
		const formData = new FormData(event.target)
		dispatch(editConfigDetail(formData, configId, navigate))
	}

	if (configDetail === undefined) {
		return <>
			Loading</>
	}

	return (
		<Layout pageTitle={"Edit Configuration"}>
			<form onSubmit={submitHandler}>

				<div className="d-grid gap-3 p-2">
					<div className="row">
						<div className="col-4">
							<ImageUploader
								dbImage={
									configDetail?.file
										? "https://api.eazyapp.in" + configDetail.file
										: null
								}
							/>
						</div>
						<div className="col-8">
							<div className="row">
								<div className="col-md-6">
									<label>Name Of Configuration</label>
									<input
										className="form-control"
										type="text"
										defaultValue={configDetail?.name}
										name="name"
									/>
								</div>
								<div className="col-md-6">
									<label className="text-muted">Configuration</label>
									<select
										name="config"
										className="form-select"
										defaultValue={configDetail?.config}
									// defaultChecked={configDetail?.config}
									// defaultSelected={configDetail?.config}
									>
										{[
											"1 RK",
											"1 BHK",
											"1.5 BHK",
											"2 BHK",
											"2.5 BHK",
											"3 BHK",
											"4 BHK",
											"5 BHK",
										].map((config) => {
											return (
												<option
													value={config}
													selected={config === configDetail?.config}
												>
													{config}
												</option>
											);
										})}
									</select>
								</div>
								<div className="col-md-6">
									<span className="text-muted">Price</span>
									<input
										className="form-control"
										type="number"
										name="price"
										defaultValue={configDetail?.price}
									/>
								</div>
								<div className="col-md-6">
									<p className="m-0 text-muted">All in</p>
									<div
										class="btn-group "
										role="group"
										aria-label="Basic radio toggle button group"
									>
										<input
											type="radio"
											class="btn-check"
											name="btnradio"
											id="btnradio1"
											autocomplete="off"
											value={false}
											defaultChecked={configDetail?.all_in === false}
										/>
										<label class="btn btn-outline-primary" for="btnradio1">
											Extra GST ++
										</label>

										<input
											type="radio"
											class="btn-check"
											name="btnradio"
											id="btnradio2"
											value={true}
											autocomplete="off"
											defaultChecked={configDetail?.all_in === true}
										/>
										<label class="btn btn-outline-primary" for="btnradio2">
											All in Price
										</label>
									</div>
								</div>
								<div className="col-md-6">
									<span className="text-muted">Carpet Area</span>
									<div className="input-group">
										<input
											type="number"
											name="carpet_area"
											className="form-control"
											defaultValue={configDetail?.carpet_area}
										/>
										<span className="input-group-text">sq.ft</span>
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex justify-content-end gap-3">
						<button type="submit" className="btn btn-success">Save</button>
							<button
             					 type="button"
								className="btn btn-dark"
								onClick={() => {
									navigate(-1);
								}}
							>
								Cancel
							</button>
							
						</div>
					</div>
				</div>
			</form>
		</Layout>
	);
};

export default AdminEditConfig;
