import React, { useEffect, useState } from 'react'
import Layout from './Layout/Layout'
import { getChannelPartners, getCPReport } from '../../Actions/SEAction'
import { useDispatch, useSelector } from 'react-redux'
import { get_projects } from '../../Actions/CommonAction'
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import { Modal } from 'react-bootstrap'
const CPReport = () => {
  const dispatch = useDispatch();
  const [showReport, setShowReport] = useState(false);
  const [channelPartner, setChannelPartner] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const { channel_partners } = useSelector(state => state.se?.channelPartnerState)
  const { projects } = useSelector(state => state?.project)
  const { report } = useSelector(state => state?.se?.cpReport)

  useEffect(() => {
    if (report !== undefined) {
      setShowReport(true)
    }
  }, [report])

  useEffect(() => {
    dispatch(getChannelPartners())
    dispatch(get_projects())
  }, [])

  const submitHandler = (e) => {
    e.preventDefault();
    const postData = {
      "projects": selectedProjects,
      "channel_partners": channelPartner,
      "from_date": fromDate,
      "to_date": toDate
    }
    dispatch(getCPReport(postData))
  }

  return (
    <Layout pageTitle={"EazyReport"}>
      <div className="p-3">
        <div className="d-grid gap-3 border rounded p-3">
          <div>
            <h5>Select Channel Partners</h5>
            <Dropdown className="w-100 border" autoClose="outside">
              <Dropdown.Toggle
                className="w-100 remove-after"
                variant="white"
                id="dropdown-basic"
              >
                {channelPartner.length > 0
                  ? channel_partners?.every((v, i) => channelPartner.includes(v.id))
                    ? "All Channel Partner"
                    : channel_partners.map((id) => {
                      var emp = channel_partners.filter((e) => e.id === id)[0];
                      return (
                        <span className="bg-light border rounded py-2 px-1">
                          {emp.name}
                        </span>
                      );
                    })
                  : "Select Channel Partner"}{" "}

              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {channel_partners?.map((cp) => (
                  <Dropdown.Item
                    className={`p-2 rounded my-1`}
                    key={cp.id}
                    onClick={() => {
                      if (!channelPartner.includes(cp.id)) {
                        setChannelPartner([
                          ...channelPartner.filter((id) => id !== cp.id),
                          cp.id,
                        ]);
                      } else {
                        setChannelPartner(
                          channelPartner.filter((id) => id !== cp.id)
                        );
                      }
                    }}
                  >
                    <Form.Check
                      type={"checkbox"}
                      name="channel_partners"
                      value={cp.id}
                      id={`default-${cp.id}`}
                      checked={channelPartner.includes(cp.id)}
                      label={cp.name}
                    />
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            <h5>Select Projects</h5>
            <Dropdown className="w-100 border" autoClose="outside">
              <Dropdown.Toggle
                className="w-100 remove-after"
                variant="white"
                id="dropdown-basic"
              >
                {selectedProjects.length > 0
                  ? projects?.every((v, i) => selectedProjects.includes(v.id))
                    ? "All Projects"
                    : projects.map((id) => {
                      var emp = projects.filter((e) => e.id === id)[0];
                      return (
                        <span className="bg-light border rounded py-2 px-1">
                          {emp.name}
                        </span>
                      );
                    })
                  : "Select Projects"}{" "}

              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {projects?.map((cp) => (
                  <Dropdown.Item
                    className={`p-2 rounded my-1`}
                    key={cp.id}
                    onClick={() => {
                      if (!selectedProjects.includes(cp.id)) {
                        setSelectedProjects([
                          ...selectedProjects.filter((id) => id !== cp.id),
                          cp.id,
                        ]);
                      } else {
                        setSelectedProjects(
                          selectedProjects.filter((id) => id !== cp.id)
                        );
                      }
                    }}
                  >
                    <Form.Check
                      type={"checkbox"}
                      name="channel_partners"
                      value={cp.id}
                      id={`default-${cp.id}`}
                      checked={selectedProjects.includes(cp.id)}
                      label={cp.name}
                    />
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div>
            <h5>Select Timeline</h5>
            <div className="row">
              <div className="col-md-5">
                <label>From</label>
                <input
                  className="form-control"
                  type="date"
                  placeholder="From"
                  name="from"
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="col-md-2 d-flex py-2 align-items-end justify-content-center">
                <Icon.DashLg />
              </div>
              <div className="col-md-5">
                <label>To</label>
                <input
                  className="form-control"
                  type="date"
                  defaultValue={new Date().toLocaleDateString("en-CA")}
                  placeholder="To"
                  name="to"
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="text-center">
            <button className="btn btn-warning" onClick={submitHandler} >Genrate Report</button>
          </div>

        </div>
      </div>
      <Modal show={showReport} fullscreen={true} onHide={() => setShowReport(false)} >
        <Modal.Header closeButton>
          <Modal.Title>Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className='table' >
            <thead>
              <tr>
                <th>#</th>
                <th>Channel Partner Name</th>
                <th>Visits</th>
                <th>Deals</th>
              </tr>
            </thead>
            <tbody>
              {report?.channel_partner?.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.source__name}</td>
                    <td>{item?.unique_visitors}</td>
                    <td>{item?.complete_deals_count}</td>
                  </tr>
                )
              })}

            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export default CPReport
