import {
	GET_DEVELOPER_DASHBOARD_REQUEST,
	GET_DEVELOPER_DASHBOARD_SUCCESS,
	GET_DEVELOPER_DASHBOARD_FAIL,
	//
	GET_DEVELOPER_PROJECTS_LIST_REQUEST,
	GET_DEVELOPER_PROJECTS_LIST_SUCCESS,
	GET_DEVELOPER_PROJECTS_LIST_FAIL,
	//
	GET_PROJECT_DETAIL_REQUEST,
	GET_PROJECT_DETAIL_SUCCESS,
	GET_PROJECT_DETAIL_FAIL,
	//
	GET_CONFIG_DETAIL_REQUEST,
	GET_CONFIG_DETAIL_SUCCESS,
	GET_CONFIG_DETAIL_FAIL,
	//
	GET_GALLERY_DETAILS_REQUEST,
	GET_GALLERY_DETAILS_SUCCESS,
	GET_GALLERY_DETAILS_FAIL,
	//
	GET_DEVELOPER_AMENITIES_REQUEST,
	GET_DEVELOPER_AMENITIES_SUCCESS,
	GET_DEVELOPER_AMENITIES_FAIL,
	//
	GET_DOCUMENT_DETAIL_REQUEST,
	GET_DOCUMENT_DETAIL_SUCCESS,
	GET_DOCUMENT_DETAIL_FAIL,
	//
	DEVELOPER_EMPLOYEES_REQUEST,
	DEVELOPER_EMPLOYEES_SUCCESS,
	DEVELOPER_EMPLOYEES_FAIL,
	//
	GET_TEAM_MEMBERS_REQUEST,
	GET_TEAM_MEMBERS_SUCCESS,
	GET_TEAM_MEMBERS_FAIL,
	//
	GET_TEAM_MEMBER_DETAIL_REQUEST,
	GET_TEAM_MEMBER_DETAIL_SUCCESS,
	GET_TEAM_MEMBER_DETAIL_FAIL,
	//
	DEVELOPER_DETAIL_REQUEST,
	DEVELOPER_DETAIL_SUCCESS,
	DEVELOPER_DETAIL_FAIL,
	//
	GET_DEVELOEPR_CHANNEL_PARTNER_REQUEST,
	GET_DEVELOEPR_CHANNEL_PARTNER_SUCCESS,
	GET_DEVELOEPR_CHANNEL_PARTNER_FAIL,
	//
	DEVELOPER_CHANNEL_PARTNER_DETAIL_REQUEST,
	DEVELOPER_CHANNEL_PARTNER_DETAIL_SUCCESS,
	DEVELOPER_CHANNEL_PARTNER_DETAIL_FAIL,
	//
	GET_PROJECT_PAYMENT_SCHEDULE_REQUEST,
	GET_PROJECT_PAYMENT_SCHEDULE_SUCCESS,
	GET_PROJECT_PAYMENT_SCHEDULE_FAIL,
	//
	GET_PAYMENT_SLAB_DATA_REQUEST,
	GET_PAYMENT_SLAB_DATA_SUCCESS,
	GET_PAYMENT_SLAB_DATA_FAIL,
	//
	GET_PAYMENT_SCHEDULE_REQUEST,
	GET_PAYMENT_SCHEDULE_SUCCESS,
	GET_PAYMENT_SCHEDULE_FAIL,
	GET_PAYMENT_SCHEDULE_RESET,
	//
	GET_PROJECT_PRICING_LIST_REQUEST,
	GET_PROJECT_PRICING_LIST_SUCCESS,
	GET_PROJECT_PRICING_LIST_FAIL,
	//
	GET_PRICING_DETAILS_REQUEST,
	GET_PRICING_DETAILS_SUCCESS,
	GET_PRICING_DETAILS_FAIL,
	// 
	GET_PROJECT_OFFER_LIST_REQUEST,
	GET_PROJECT_OFFER_LIST_SUCCESS,
	GET_PROJECT_OFFER_LIST_FAIL,
	// 
	GET_OFFER_DETAIL_REQUEST,
	GET_OFFER_DETAIL_SUCCESS,
	GET_OFFER_DETAIL_FAIL,
	// 
	GET_INVENTORY_DATA_REQUEST,
	GET_INVENTORY_DATA_SUCCESS,
	GET_INVENTORY_DATA_FAIL,
	//
	GET_DEVELOPER_SETTING_REQUEST,
	GET_DEVELOPER_SETTING_SUCCESS,
	GET_DEVELOPER_SETTING_FAIL,
	// 
	GET_EMPLOYEE_DETAIL_REQUEST,
	GET_EMPLOYEE_DETAIL_SUCCESS,
	GET_EMPLOYEE_DETAIL_FAIL,
	// 
	GET_CUSTOMER_BOOKING_LIST_REQUEST,
	GET_CUSTOMER_BOOKING_LIST_SUCCESS,
	GET_CUSTOMER_BOOKING_LIST_FAIL,
	// 
	GET_CUSTOMER_BOOKING_DETAIL_REQUEST,
	GET_CUSTOMER_BOOKING_DETAIL_SUCCESS,
	GET_CUSTOMER_BOOKING_DETAIL_FAIL,
	// 
	GET_PROJECT_CONFIGS_REQUEST,
	GET_PROJECT_CONFIGS_SUCCESS,
	GET_PROJECT_CONFIGS_FAIL,
	// 
	GET_PROJECT_AUTOMATION_LIST_REQUEST,
	GET_PROJECT_AUTOMATION_LIST_SUCCESS,
	GET_PROJECT_AUTOMATION_LIST_FAIL,
	// 
	GET_WHATSAPP_TEMPLATES_REQUEST,
	GET_WHATSAPP_TEMPLATES_SUCCESS,
	GET_WHATSAPP_TEMPLATES_FAIL,
	// 
	GET_WHATSAPP_AUTH_REQUEST,
	GET_WHATSAPP_AUTH_SUCCESS,
	GET_WHATSAPP_AUTH_FAIL,
	// 
	GET_VISITORS_REQUEST,
	GET_VISITORS_SUCCESS,
	GET_VISITORS_FAIL,
	//
} from "../Constants/AdminConstants";

export const developerDashBoardDataReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_DEVELOPER_DASHBOARD_REQUEST:
			return { loading: true };
		case GET_DEVELOPER_DASHBOARD_SUCCESS:
			return { loading: false, dashData: action.payload };
		case GET_DEVELOPER_DASHBOARD_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state
	}
};

export const developerProjectsListReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_DEVELOPER_PROJECTS_LIST_REQUEST:
			return { loading: true };
		case GET_DEVELOPER_PROJECTS_LIST_SUCCESS:
			return { loading: false, ...action.payload };
		case GET_DEVELOPER_PROJECTS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const developerProjectDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROJECT_DETAIL_REQUEST:
			return { loading: true };
		case GET_PROJECT_DETAIL_SUCCESS:
			return { loading: false, detail: action.payload };
		case GET_PROJECT_DETAIL_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const projectConfigDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_CONFIG_DETAIL_REQUEST:
			return { loading: true };
		case GET_CONFIG_DETAIL_SUCCESS:
			return { loading: false, configDetail: action.payload };
		case GET_CONFIG_DETAIL_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const projectGalleryDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_GALLERY_DETAILS_REQUEST:
			return { loading: true };
		case GET_GALLERY_DETAILS_SUCCESS:
			return { loading: false, itemDetail: action.payload };
		case GET_GALLERY_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const developerProjectAmenitieHandlerReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_DEVELOPER_AMENITIES_REQUEST:
			return { loading: true };
		case GET_DEVELOPER_AMENITIES_SUCCESS:
			return { loading: false, amenities: action.payload };
		case GET_DEVELOPER_AMENITIES_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const documentDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_DOCUMENT_DETAIL_REQUEST:
			return { loading: true };
		case GET_DOCUMENT_DETAIL_SUCCESS:
			return { loading: false, detail: action.payload };
		case GET_DOCUMENT_DETAIL_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const developerEmployeesReducer = (state = {}, action) => {
	switch (action.type) {
		case DEVELOPER_EMPLOYEES_REQUEST:
			return { loading: true };
		case DEVELOPER_EMPLOYEES_SUCCESS:
			return { loading: false, employeeList: action.payload };
		case DEVELOPER_EMPLOYEES_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const developerTeamListReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_TEAM_MEMBERS_REQUEST:
			return { loading: true };
		case GET_TEAM_MEMBERS_SUCCESS:
			return { loading: false, teamData: action.payload };
		case GET_TEAM_MEMBERS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const developerTeamDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_TEAM_MEMBER_DETAIL_REQUEST:
			return { loading: true };
		case GET_TEAM_MEMBER_DETAIL_SUCCESS:
			return { loading: false, detail: action.payload };
		case GET_TEAM_MEMBER_DETAIL_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const developerDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case DEVELOPER_DETAIL_REQUEST:
			return { loading: true };
		case DEVELOPER_DETAIL_SUCCESS:
			return { loading: false, detail: action.payload };
		case DEVELOPER_DETAIL_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};
export const developerChannelPartnerReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_DEVELOEPR_CHANNEL_PARTNER_REQUEST:
			return { loading: true };
		case GET_DEVELOEPR_CHANNEL_PARTNER_SUCCESS:
			return { loading: false, ...action.payload };
		case GET_DEVELOEPR_CHANNEL_PARTNER_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const developerChannelPartnerDetailReducer = (state = {}, action) => {
	switch (action.type) {
		case DEVELOPER_CHANNEL_PARTNER_DETAIL_REQUEST:
			return { loading: true };
		case DEVELOPER_CHANNEL_PARTNER_DETAIL_SUCCESS:
			return { loading: false, detail: action.payload };
		case DEVELOPER_CHANNEL_PARTNER_DETAIL_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};


export const developerProjectPaymentSchedule = (state = {}, action) => {
	switch (action.type) {
		case GET_PROJECT_PAYMENT_SCHEDULE_REQUEST:
			return { loading: true };
		case GET_PROJECT_PAYMENT_SCHEDULE_SUCCESS:
			return { loading: false, paymentScheduleList: action.payload }
		case GET_PROJECT_PAYMENT_SCHEDULE_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}

export const paymentSlabReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PAYMENT_SLAB_DATA_REQUEST:
			return { loading: true }
		case GET_PAYMENT_SLAB_DATA_SUCCESS:
			return { loading: false, slabData: action.payload }
		case GET_PAYMENT_SLAB_DATA_FAIL:
			return { loading: false, error: action.payload }
		default:
			return state
	}
}




export const paymentScheduleReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_PAYMENT_SCHEDULE_REQUEST:
			return { loading: true };
		case GET_PAYMENT_SCHEDULE_SUCCESS:
			return { loading: false, scheduleData: payload };
		case GET_PAYMENT_SCHEDULE_FAIL:
			return { loading: false, error: payload }
		case GET_PAYMENT_SCHEDULE_RESET:
			return {}
		default:
			return state
	}
}


export const projectPricingListReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_PROJECT_PRICING_LIST_REQUEST:
			return { loading: true }
		case GET_PROJECT_PRICING_LIST_SUCCESS:
			return { loading: false, pricingList: payload };
		case GET_PROJECT_PRICING_LIST_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}


export const pricingDetailsReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_PRICING_DETAILS_REQUEST:
			return { loading: true }
		case GET_PRICING_DETAILS_SUCCESS:
			return { loading: false, pricingDetail: payload }
		case GET_PRICING_DETAILS_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}


export const projectOfferListReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_PROJECT_OFFER_LIST_REQUEST:
			return { loading: true }
		case GET_PROJECT_OFFER_LIST_SUCCESS:
			return { loading: false, offerList: payload }
		case GET_PROJECT_OFFER_LIST_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}



export const offerDetailReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_OFFER_DETAIL_REQUEST:
			return { loading: true }
		case GET_OFFER_DETAIL_SUCCESS:
			return { loading: false, offerDetail: payload }
		case GET_OFFER_DETAIL_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}



export const inventoryDataReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_INVENTORY_DATA_REQUEST:
			return { loading: true }
		case GET_INVENTORY_DATA_SUCCESS:
			return { loading: false, inventoryData: payload }
		case GET_INVENTORY_DATA_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}

export const getDeveloperSettingDetailReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_DEVELOPER_SETTING_REQUEST:
			return { loading: true }
		case GET_DEVELOPER_SETTING_SUCCESS:
			return { loading: false, developerSettingDetail: payload }
		case GET_DEVELOPER_SETTING_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}

export const getEmployeeDetailReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_EMPLOYEE_DETAIL_REQUEST:
			return { loading: true }
		case GET_EMPLOYEE_DETAIL_SUCCESS:
			return { loading: false, employeeDetail: payload }
		case GET_EMPLOYEE_DETAIL_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}

export const customerBookingListReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_CUSTOMER_BOOKING_LIST_REQUEST:
			return { loading: true }
		case GET_CUSTOMER_BOOKING_LIST_SUCCESS:
			return { loading: false, customerList: payload }
		case GET_CUSTOMER_BOOKING_LIST_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}


export const customerBookingDetailReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_CUSTOMER_BOOKING_DETAIL_REQUEST:
			return { loading: true }
		case GET_CUSTOMER_BOOKING_DETAIL_SUCCESS:
			return { loading: false, bookingDetail: payload }
		case GET_CUSTOMER_BOOKING_DETAIL_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}

export const projectConfigListReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_PROJECT_CONFIGS_REQUEST:
			return { loading: true }
		case GET_PROJECT_CONFIGS_SUCCESS:
			return { loading: false, configList: payload }
		case GET_PROJECT_CONFIGS_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}


export const projectAutomationListReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_PROJECT_AUTOMATION_LIST_REQUEST:
			return { loading: true }
		case GET_PROJECT_AUTOMATION_LIST_SUCCESS:
			return { loading: false, automationList: payload }
		case GET_PROJECT_AUTOMATION_LIST_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}


export const whatsappTemplateReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_WHATSAPP_TEMPLATES_REQUEST:
			return { loading: true }
		case GET_WHATSAPP_TEMPLATES_SUCCESS:
			return { loading: false, whatsappTemplate: payload }
		case GET_WHATSAPP_TEMPLATES_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}


export const whatsappAccountReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_WHATSAPP_AUTH_REQUEST:
			return { loading: true }
		case GET_WHATSAPP_AUTH_SUCCESS:
			return { loading: false, whatsappAuth: payload }
		case GET_WHATSAPP_AUTH_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}


export const visitorListReducer = (state = {}, { type, payload }) => {
	switch (type) {
		case GET_VISITORS_REQUEST:
			return { loading: true }
		case GET_VISITORS_SUCCESS:
			return { loading: false, visitors: payload }
		case GET_VISITORS_FAIL:
			return { loading: false, error: payload }
		default:
			return state
	}
}