import React, { useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../Actions/axios";
import toast from "react-hot-toast";
const AddImage = () => {
	const navigate = useNavigate();
	const { userInfo } = useSelector((state) => state?.userState);
	let params = useParams();
	const uploadInput = useRef(null);
	const uploadImageHandler = async (file) => {
		const formData = new FormData();
		formData.append("image", file, file.name);
		formData.append("visitor_id", params.visitor_id);
		try {
			toast.loading("Uploading Image");
			const config = {
				headers: {
					"Content-type": "multipart/form-data",
					Authorization: `Bearer ${userInfo.token}`,
				},
			};
			const { data } = await axios.post(
				"visitor/upload-image/",
				formData,
				config
			);
			toast.dismiss();
			toast.success("Image Uploaded");
			navigate(`/check-in/${data.visit_id}/assign-sales-executive`);
		} catch (error) {
			toast.dismiss();
			toast.error("Upload Error. Try Again!");
		}
	};
	return (
		<div className="h-100">
			<div className="h-80 d-flex justify-content-center align-items-center">
				{/* <h2>add image</h2> */}
				<div>

				<input
					ref={uploadInput}
					type="file"
					onChange={(event) => {
						uploadImageHandler(event.target.files[0]);
					}}
					hidden
				/>
				<button
					className="btn btn-outline-primary w-100"
					onClick={() => {
						uploadInput.current.click();
					}}
				>
					Upload Photo
				</button>
				<div className="text-center py-2">
					<p>OR</p>
				</div>
				<button className="btn btn-dark w-100" type="button"  onClick={()=>{
					navigate(`/check-in/${params.visit_id}/assign-sales-executive`);
				}}>Skip</button>
				</div>
			</div>
		</div>
	);
};

export default AddImage;
