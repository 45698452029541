import React from 'react'
import WhatsappTemplates from './DeveloperAdmin/WhatsappTemplates'
const TestPage = () => {
  return (
    <div>
      <WhatsappTemplates />
    </div>
  )
}

export default TestPage
