import toast from 'react-hot-toast';
import {
    CUSTOMER_GET_MY_BOOKINGS_REQUEST,
    CUSTOMER_GET_MY_BOOKINGS_SUCCESS,
    CUSTOMER_GET_MY_BOOKINGS_FAIL,
    UPDATE_CURRENT_BOOKING_ID,
    // 
    GET_CUSTOMER_DASHBOARD_DATA_REQUEST,
    GET_CUSTOMER_DASHBOARD_DATA_SUCCESS,
    GET_CUSTOMER_DASHBOARD_DATA_FAIL,
    // 
    GET_CUSTOMER_PROJECT_DOCUMNETS_REQUEST,
    GET_CUSTOMER_PROJECT_DOCUMNETS_SUCCESS,
    GET_CUSTOMER_PROJECT_DOCUMNETS_FAIL,
    // 
    GET_CUSTOMER_PRICING_PAYMENTS_REQUEST,
    GET_CUSTOMER_PRICING_PAYMENTS_SUCCESS,
    GET_CUSTOMER_PRICING_PAYMENTS_FAIL,
    // 
    GET_CUSTOMER_PAYMENT_SCHEDULE_REQUEST,
    GET_CUSTOMER_PAYMENT_SCHEDULE_SUCCESS,
    GET_CUSTOMER_PAYMENT_SCHEDULE_FAIL,
    // 
    GET_ALL_CUSTOMER_PAYMENT_LIST_REQUEST,
    GET_ALL_CUSTOMER_PAYMENT_LIST_SUCCESS,
    GET_ALL_CUSTOMER_PAYMENT_LIST_FAIL,
    // 
    ADD_CUSTOMER_DOCUMENT_REQUEST,
    ADD_CUSTOMER_DOCUMENT_SUCCESS,
    ADD_CUSTOMER_DOCUMENT_FAIL,
    // 
    ADD_CUSTOMER_PAYMENT_REQUEST,
    ADD_CUSTOMER_PAYMENT_SUCCESS,
    ADD_CUSTOMER_PAYMENT_FAIL,
    // 
} from '../Constants/CustomerConstants'
import axios from './axios';




export const getMyBookings = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: CUSTOMER_GET_MY_BOOKINGS_REQUEST
        })

        const {userState: {userInfo}} = getState();
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const {data}= await axios.get("customer/get-my-bookings", config)
        dispatch({
            type: CUSTOMER_GET_MY_BOOKINGS_SUCCESS,
            payload: data.bookings
        })
        dispatch({ type:UPDATE_CURRENT_BOOKING_ID, payload: data.bookings[0].id})

    }catch(error){
        dispatch({
            type: CUSTOMER_GET_MY_BOOKINGS_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}

export const getCustomerDashboardData = (params) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_CUSTOMER_DASHBOARD_DATA_REQUEST
        })
        const { userState: { userInfo } } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            },
            params: params,
        }

        const { data } = await axios.get("customer/get/dashboard-data", config)
        dispatch({
            type: GET_CUSTOMER_DASHBOARD_DATA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_DASHBOARD_DATA_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}


export const getCutomerProjectDocument = (params) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_CUSTOMER_PROJECT_DOCUMNETS_REQUEST
        })
        const { userState: { userInfo } } = getState();
        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            },
            params: params
        }
        const { data } = await axios.get("customer/get/project-documnets", config)
        dispatch({
            type: GET_CUSTOMER_PROJECT_DOCUMNETS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_PROJECT_DOCUMNETS_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}

export const getCustomerPricing = (params) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_CUSTOMER_PRICING_PAYMENTS_REQUEST,
        })

        const { userState: { userInfo } } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            },
            params: params
        }

        const { data } = await axios.get("customer/get/pricing-payments", config)
        dispatch({
            type: GET_CUSTOMER_PRICING_PAYMENTS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_PRICING_PAYMENTS_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}




export const getCustomerPaymentSchedule = (params) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_CUSTOMER_PAYMENT_SCHEDULE_REQUEST,
        })

        const { userState: { userInfo } } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            },
            params: params
        }

        const { data } = await axios.get("customer/get/payment-schedule", config)
        dispatch({
            type: GET_CUSTOMER_PAYMENT_SCHEDULE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: GET_CUSTOMER_PAYMENT_SCHEDULE_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const getCustomerPaymentList = (params) => async (dispatch, getState) => {
    try {

        dispatch({
            type: GET_ALL_CUSTOMER_PAYMENT_LIST_REQUEST
        })

        const { userState: { userInfo } } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo?.token}`
            },
            params: params
        }

        const { data } = await axios.get("customer/get/payment-list", config)
        dispatch({
            type: GET_ALL_CUSTOMER_PAYMENT_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_ALL_CUSTOMER_PAYMENT_LIST_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}


export const addCustomerDocument = (postData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADD_CUSTOMER_DOCUMENT_REQUEST
        })

        const { userState: { userInfo } } = getState();
        const config = {
            headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.post("customer/add/document", postData, config)

        toast.success("Document Added Successfully")

        dispatch({
            type: ADD_CUSTOMER_DOCUMENT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ADD_CUSTOMER_DOCUMENT_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }
}



export const addCustomerPayment = (postData,navigate) => async (dispatch, getState) => { 

    try{

        dispatch({
            type: ADD_CUSTOMER_PAYMENT_REQUEST
        })

        const {userState:{userInfo}} = getState();

        const config={
            headers:{
                "Content-type":"application/json",
                Authorization:`Bearer ${userInfo?.token}`
            }
        }

        const { data } = await axios.post("customer/add-payment",postData,config)
        toast.success("Payment Added Successfully")
        dispatch({
            type: ADD_CUSTOMER_PAYMENT_SUCCESS,
            payload: data
        })
        navigate("/all-payment-list")

    }catch(error){
        dispatch({
            type: ADD_CUSTOMER_PAYMENT_FAIL,
            payload: error?.response && error?.response?.data?.error
                ? error?.response?.data?.error
                : error.message
        })
    }

}

